.breadcrumbs a {
    display: inline-block;
    font-size: 15px;
    text-transform: uppercase;
}

.breadcrumbs {
    padding: 8px 0 8px;
    background: #f7f8f9;
    background-image: none;
    background-image: unset !important;
    color: #000;
    font-weight: 500;
}
.breadcrumbs div {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .crumb::before {
    display: inline-block;
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    /* margin-left: 20px;
    margin-right: 15px; */
    color: var(--text-color);
    color: #000;
  }
  .crumb:last-child {
    color: var(--text-color);
    font-weight: normal;
  }