/********************************** custom date 25-1-23 **********************************/
/* scrollbar start */
:root{
  scrollbar-color: #888 #f1f1f1 !important;
  scrollbar-width: thin !important;
}
 /* width */
 ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 
/* scrollbar end */
html {scroll-behavior: smooth;}
a{color: inherit;text-decoration: inherit;}
a:hover{color: #db1c00;text-decoration: none;}
.header-navigation .main-menu ul li>a {color: #fff;font-size: 22px;font-weight: 500;text-transform: unset;}

.header-navigation .main-menu ul li > a.active {color: #db1c00;}

.header-navigation .main-menu ul li.actived>a {color: var(--red-dark);}

.header-navigation {width: 100%;background-color: #000;}

.index-header-navigation.header-navigation {position: absolute;top: 0;width: 100%;left: 0;z-index: 12;background-color: unset;}

.header-navigation .main-menu ul>.menu-item>a::before {content: "";width: 2px;height: 17px;background-color: #fff;position: absolute;
  right: 0;top: 40px;}
  .header-navigation .main-menu ul>li:last-child>a::before {display: none;}

  .header-navigation .main-menu ul > li.has-children > a::after {
 display: none;
  }

.footer_phone i {transform: rotate(90deg);}

.hero-content.text-left h1 {font-size: 72px;font-weight: 600;}
div.features-style-one::after {
  left: 0;
}
.fancy-features.banner_over_cnt {
  padding: 95px 0px 105px 0px;
}
.fancy-about-one::after{display: none;}
.has-children-arrow{display: none;}

div.hero-wrapper-one .single-slider {
  padding: 206px 0 268px;
  z-index: 1;
  height: 100vh;
}

.hero-content.text-left p {font-size: 24px;font-weight: 400;color: #fff;}

.section-title h2 {font-weight: 500;text-transform: unset !important;line-height: 46px;}

.banner_over_cnt p {font-size: 36px;}

.banner_over_cnt p span {color: #000;}

.banner_over_cnt h2 {font-size: 48px;text-transform: unset !important;margin-top: 10px;}

.banner_over_cnt .section-title {padding-left: 20px;border-left: 4px solid #db1c00;}

.banner_over_cnt .brands {display: flex;gap: 50px;flex-wrap: wrap;margin-top: 10px;}

.banner_over_cnt .brand {width: calc(20% - 40px);opacity: .6;transition: all .3s ease;cursor: pointer;text-align: center;}

.brand:hover {opacity: 1;}

span.sub-title {font-size: 24px;font-weight: 500;text-decoration: none;}

span.sub-title:hover {text-decoration: none;}

.text-transform.side_line {padding-left: 20px;position: relative;}

.text-transform.side_line::before {content: "";height: 76px;width: 4px;background: #db1c00;position: absolute;top: 9px;left: 0;}

.main-btn.bg_blu {background-color: var(--red-dark);text-decoration: none;}

.main-btn.bg_blu:hover {background-color: #000;}

.fancy-about-one::after {left: 38px;}

.Features_start {background-image: url('image/what_we_do.png');background-position: center;background-size: cover;height: max-content;}

.block-style-two {padding: 50px 25px 40px;}

.Features_start h3 {font-size: 28px;font-weight: 500;text-decoration: none;text-transform: unset !important;}

.Features_start h3:hover {text-decoration: none;}

.Features_start p {font-size: 18px;margin-bottom: 10px;}

.newsletter-wrapper {background-image: url('image/news_letter_bg.png');background-size: cover;background-position: center;}

.newsletter-style-one .newsletter-wrapper .newsletter-form .main-btn {position: unset;border: 1px solid #fff;background-color: rgb(255, 255, 255);
  color: #000;text-decoration: none;}

.newsletter-style-one .newsletter-wrapper .newsletter-form .main-btn.transparent_btn {background-color: transparent;color: #FFF;
  border: 1px solid #fff;font-weight: 500;margin-left: 10px;}

.newsletter-form {height: 100%;display: flex;align-items: center;justify-content: end;}

.newsletter-style-one .newsletter-wrapper .newsletter-content-box .content h2 {font-size: 38px;font-weight: 600;}

.newsletter-style-one .newsletter-wrapper {padding:40px;z-index: unset;border-radius: 10px;}

.newsletter-area.newsletter-style-one {position: absolute;width: 100%;top: -60px;}

/* .newsletter-style-one .newsletter-wrapper .newsletter-form i {position: unset;padding-left: 10px;color:#1774d9;font-size: 13px;} */
.newsletter-style-one .newsletter-wrapper .newsletter-form i {position: unset;padding-left: 10px;font-size: 13px;}

.newsletter-style-one .newsletter-wrapper .newsletter-form .transparent_btn i {color: #fff;}

.header-navigation.sticky {background-color: #000;}

.case_study {background-image: url("image/case_study_bg.jpg");background-position: top center;background-repeat: no-repeat;
  background-size: cover;transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;padding: 95px 0px 140px 0px;}

.case_study_title {display: flex;}

.case-content .p-icon a {
  display: block;
  width: 100%;
  text-align: center;
  transition: all .3s ease;
  cursor: pointer;
}
.case-content .p-icon a:hover{
  margin-left: 15px;
}

.blank_case_study {width: 31.551%;}

.section-title.case_study_title span.sub-title {margin-bottom: 25px;line-height: 0;}

.case_title_main {padding-left: 20px;border-left: 4px solid #db1c00;}

.owl-dots {display: flex;justify-content: center;position: absolute;left: 50%;bottom: -60px;transform: translateX(-50%);gap: 10px;}

.owl-carousel button.owl-dot.active {width: 40px;height: 8px;box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);background: var(--red-dark);
  border-radius: 50px;transition: all .3s ease;}

.owl-carousel button.owl-dot {height: 8px;width: 8px;box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);border-radius: 50%;
  background: #032e42;transition: all .3s ease;overflow: hidden;}

.service-area {background-image: url("image/service_bg.png");background-position: top center;background-repeat: no-repeat;background-size: cover;}

.service-area .block-style-three .text h3.title {color: #000;font-weight: 600;}

.service-area .block-style-three .text p {color: #616161;}

.service-style-one .service-wrapper::after {background-color: #b5b5b4;}

.border-right-1 {border-right: 1px solid #b5b5b4;height: 100%;}

.service-style-one .text-wrapper p {color: #616161;}

.service-style-one .block-style-three .icon {width: 110px;}

.service-area .block-style-three {padding: 60px 30px 50px;}

.service-area .block-style-three.border-right-1 {padding-left: 0;}

.service-area .main-btn {background-color: var(--red-dark);text-decoration: none;font-weight: 600;}

.service-area .main-btn:hover {background-color: #000;}

.service-area .section-title {padding-left: 20px;border-left: 4px solid #db1c00;}

.portfolio {background-image: url("image/portfolio_bg.png");background-position: top center;background-repeat: no-repeat;
  background-size: cover;transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;padding: 95px 0px 105px 0px;}

.testimonial-item {padding: 55px 55px 60px 55px;background-color: #FFFFFF;box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  border-radius: 5px 5px 5px 5px;}

.item-content.basic p {font-size: 24px;font-weight: 400;color: #000;min-height: 150px;}

.portfolio .image-wrap img {height: 65px;width: 65px;border-radius: 50%;position: relative;}

.testimonial-content {display: flex;gap: 25px;margin-top: 20px;}
.testimonial-item .item-content {
  height: 155px;
  overflow-y: auto;
  margin-right: -55px;
    padding-right: 55px;
}

.testimonial-name {color: #000;font-size: 20px;font-weight: 600;}

.testimonial-title {color: #676767;font-size: 16px;}

#portfolioo .owl-nav,#news_blog .owl-nav{display: none;}

.item-content.basic span {position: absolute;width: unset !important;right: 30px;bottom: 77px;}

.portfolio_title h2 {font-weight: 500;text-transform: unset !important;line-height: 46px;}

.portfolio_title_main {padding-left: 20px;position: relative;}

.portfolio_title_main.side_line::before {content: "";height: 68px;width: 4px;background: #db1c00;position: absolute;top: 5px;left: 0;}

.portfolio .owl-carousel button.owl-dot.active {background: #032e42;}

.portfolio .owl-carousel button.owl-dot {background: var(--red-dark);}

.news_blog {padding: 95px 0px 105px 0px;}

.news_blog .blog-content {padding: 32px 40px 39px 20px;}

.news_blog .blog-inner-wrap {box-shadow: 0 0px 15px rgba(12, 89, 219, 0.09);margin: 15px 0px 30px;}

.news_blog .image-wrap {position: relative;overflow: hidden;max-height: 217px;}

.news_blog .date {display: inline-block;padding: 6px 18px 6px;color: #fff;position: absolute;right: 26px;bottom: 24px;border-radius: 5px;
  background: #00c6db;font-size: 16px;}

.news_blog .btn-txt {padding: 8px 22px 8px 22px;background: var(--red-dark);color: #fff;border-radius: 5px;font-size: 16px;font-weight: 500;
  transition: all .3s ease;}
  /* .news_blog .btn-txt a{color:#fff;text-decoration: none;} */

.news_blog .blog-name {font-size: 26px;font-weight: 500;line-height: 30px;}

.news_blog .blog-content p {color: #666666;font-size: 18px;line-height: 22px;margin: 20px 0 30px;}

.post-image {transition: all .5s ease;}

.news_blog .blog-inner-wrap:hover .post-image {transform: scale(1.2);}

.home-blog-description{
  text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 15px 0;
    min-height: 93px;
}
.news_blog .blog-name{
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.newsletter-style-one .newsletter-wrapper .newsletter-form .main-btn.transparent_btn {
  display: none;
}
.pointer-events {height: 100%;display: block;}

.newsletter-style-one .newsletter-wrapper .newsletter-form .main-btn:hover {border: 1px solid #000;background-color: #000;color: #fff;}

.footer-area {background-image: url("image/footer_bg.jpg");background-position: top center;background-repeat: no-repeat;background-size: cover;margin-top: 60px;}

.list-style-dot li a::before {width: unset;height: unset;background-color: unset;content: "\f105";font-family: "Font Awesome 5 Pro";
  color: rgba(255, 255, 255, .60)}

.footer-widget .widget.footer-nav-widget ul li a {margin-bottom: 10px;color: #fff;font-weight: 300;}

.footer-area .about-content p {color: #fff;font-weight: 300;padding-right: 70px;}

.footer-default .footer-widget .widget h4.widget-title {color: #fff;font-size: 18px;font-weight: 500;text-transform: uppercase;
  position: relative;}

.footer-default .footer-widget .widget h4.widget-title::before {content: "";height: 2px;width: 40px;background-color: var(--red-dark);
  position: absolute;left: 0;bottom: -10px;}

.address {color: #fff;font-weight: 300;}

.address:first-child {margin-bottom: 10px;}

.address span:first-child {font-size: 16px;text-transform: uppercase;font-weight: 400;margin: 0px 0 5px;}

.address i {color: #db1c00;font-size: 20px;margin-right: 10px;}

div.footer-copyright {padding: 20px 0 20px;}

.address p:last-child {display: flex;align-items: center;}

.social-link li a {display: inline-block;width: 40px;height: 40px;border-radius: 5px;display: flex;justify-content: center;align-items: center;
  color: #fff;}

.footer-copyright .social-link li {margin-left: 15px;}

.footer-default .footer-copyright ul.social-link li a:hover {color: #fff;}

.social-link li:nth-child(1) a {background-color: #3460a1;}

/* .social-link li:nth-child(3) a {background-color: #28aae1;} */
/* .social-link li:nth-child(3) a {background-color: #fff;} */

.social-link li:nth-child(3) a {background-color: #136d9d;}

.social-link li:nth-child(4) a {background-color: #cc2127;}

.social-link li:nth-child(2) a {background-color: #8c0bef;}

.imp-link {display: flex;gap: 30px;color: #fff;height: 100%;align-items: center;}

.imp-link li {position: relative;}

.imp-link li::after {content: "";height: 15px;width: 2px;background: #fff;position: absolute;left: -15px;top: 4px;}

.imp-link li:first-child:after {display: none;}

.footer-copyright.footer-copyright-bottom p {text-align: center;color: #fff;}

.header-navigation .main-menu ul li:hover>.sub-menu {top: 93px;}

.header-navigation .main-menu ul li .sub-menu {position: fixed;left: 0;width: 100%;top: 120px;display: flex;padding: 50px 20px;gap: 15px;
  box-shadow: -2px 5px 9px -2px rgba(0, 0, 0, 10%);}

.header-navigation .main-menu ul>li.has-children:last-child>a::after {display: none;}

.header-navigation .main-menu ul li .sub-menu li.extra_sub_menu_wrapper a {line-height: unset;border-bottom: none;font-size: 18px;font-weight: 400;
  padding: 7px 0px;text-transform: capitalize;}

.header-navigation .main-menu ul li .sub-menu li.extra_sub_menu_wrapper a:hover {background-color: unset;color: var(--red-dark);}


/* #case_studyy .item {box-shadow: 0 0px 15px rgba(12, 89, 219, 0.09);} */
.case_study_img_wrap:after {
	content: '';
background: #00000012;
position: absolute;
left: 0;
top: 0;
bottom: 0;
right: 0;
z-index: 0;
mix-blend-mode: multiply;
}

.extra_sub_menu_wrapper {width: 25%;padding: 0 50px;border-right: 1px solid #e8e8e8;}

.extra_sub_menu_wrapper h6 {text-transform: capitalize;color: var(--red-dark);font-weight: 500;font-size: 20px;margin-bottom: 15px;}

.header-navigation .main-menu ul li .sub-menu.sub-menu_about li a {padding: 7px 0px;border-bottom: unset;min-height: 170px;display: flex;
  justify-content: center;align-items: center;border: 1px solid #e8e8e8;text-transform: capitalize;font-size: 24px;}

.header-navigation .main-menu ul li .sub-menu.sub-menu_about li {width: 33.33%;}

.header-navigation .main-menu ul li .sub-menu.sub-menu_about {flex-wrap: wrap;gap: unset;padding: 20px;}

.extra_sub_menu_wrapper:last-child {border-right: unset;}

.main-menu {display: flex;align-items: center;gap: 50px;}

.contact_buttn {color: #fff;font-size: 18px;font-size: 20px;font-weight: 500;padding: 3px 15px;background-color: var(--red-dark);
  border: 2px solid var(--red-dark);border-radius: 5px;transition: all .3s ease;}

.contact_buttn:hover {background-color: transparent;border: 2px solid #fff;color: #fff;}

.case_img_overtxt {position: absolute;left: 0;top: 30%;background: rgba(0, 0, 0, 0.5);width: 100%;height: 100%;transition: all .3s ease-out;
  opacity: 0;z-index: -1;}

#case_studyy .item:hover .case_img_overtxt {top: 0;opacity: 1;z-index: 1;}

.service_wrapper_icon_txt {display: flex;}

.case_img_overtxt_details {position: absolute;width: 100%;left: 0px;bottom: 25px;z-index: 1;color: #c6c6c6;font-size: 24px;font-weight: 500;
padding: 10px;}

.case_img_overtxt_details h3 {
  color: #fff;
}

.header-navigation .main-menu ul li .sub-menu.sub-menu_about li a:hover {border-color: var(--red-dark);}

.news_blog .btn-txt:hover {background-color: #000;}

.sub_menu_service_img_txt {font-size: 20px;line-height: 26px;margin-top: 18px;}

.features-area .icon img {width: 50px;max-width: unset;}

.breadcrumbs-wrapper .page-title-text .title {font-weight: 600;text-decoration: none;}

.imp-link a:hover {color: var(--red-dark);}

.banner_para_span {background: #ffa502;padding: 1px 8px 7px 8px;margin-top: 7px;transform: skewX(-12deg);}

div.portfolio-block-four .portfolio-img .portfolio-hover {background-color: rgba(0, 0, 0, 0.74);}

.galllery_section {padding-bottom: 100px;padding-top: 70px;}

.galllery_section .btn-orange-dark {background-color: var(--red-dark);}

.galllery_section .btn-orange-dark:hover {background-color: #000;}

.header-navigation .main-menu ul li .sub-menu.sub-menu_about li a img {margin-right: 5px;transition: all .3s ease;filter: grayscale(1) invert(1);}

.header-navigation .main-menu ul li .sub-menu.sub-menu_about li a:hover img {filter: grayscale(0) invert(0);}

.header-navigation .main-menu ul li .sub-menu li a {color: #110f0e;}

div.breadcrumbs-wrapper .page-title-text .title {display: none;}

div.breadcrumbs-wrapper .page-title-text ul.breadcrumbs-link li {display: inline-block;font-size: 15px;text-transform: uppercase;}

div.breadcrumbs-wrapper {padding: 8px 0 8px;background: #f7f8f9;background-image: unset !important;}

div.breadcrumbs-wrapper .page-title-text ul.breadcrumbs-link li a {color: #000;font-weight: 500;}

div.breadcrumbs-wrapper .page-title-text ul.breadcrumbs-link li a:hover {color: var(--red-dark);}

div.breadcrumbs-wrapper .text-center {text-align: left !important;}

div.breadcrumbs-wrapper .justify-content-center {justify-content: start !important;}

.header-navigation.sticky {position: fixed !important;}

div.breadcrumbs-wrapper::after {display: none;}

div.breadcrumbs-wrapper .page-title-text ul.breadcrumbs-link li::after {color: #000;font-size: 16px;}

.breadcrumbs-wrapper .page-title-text ul.breadcrumbs-link li.active {color: #636363;text-transform: uppercase;}

.fancy-features .block-style-two {min-height: 452px;}

.service-style-one .block-style-three .icon img {max-width: unset;}

/* .service-area .block-style-three .text p {height: 90px;text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 3;
  -webkit-box-orient: vertical;} */
  .service-area .block-style-three .text p {text-align: justify;hyphens: auto;}
  

.block-style-three div.icon {margin-right: 20px;}

.Features_start.fancy-features .block-style-two {min-height: 425px;}

.home_what_we_do.Features_start.fancy-features .block-style-two {min-height: 376px;padding: 50px 30px;}
.home_what_we_do .block-style-two .icon {margin-bottom: 50px;}
.home_what_we_do .block-style-two .text h3 {margin-bottom: 15px;}
.header-navigation .main-menu ul li:nth-child(4) {display: none;}
.header-navigation .main-menu ul li .sub-menu li a.active {
  color: var(--red-dark);
}
.header-navigation .main-menu ul li .sub-menu.sub-menu_about li a.active{
  color: var(--red-dark);
}
.header-navigation .main-menu ul li .sub-menu.sub-menu_about li:hover > a.active {
  color: #fff;
}
.header-navigation .main-menu ul li .sub-menu.sub-menu_about li a.active {
  background-color: var(--red-dark);
  color: #fff;

}
.header-navigation .main-menu ul li .sub-menu.sub-menu_about li a.active img {
  margin-right: 5px;
  transition: all .3s ease;
  filter: grayscale(0) invert(0);
}


/********************************** service flow start**********************************/
.pt-process-2 .pt-process-icon {display: inline-block;position: relative;-webkit-box-shadow: 0px 0px 30px 0 rgba(0, 20, 9, 0.1);
  -moz-box-shadow: 0px 0px 30px 0 rgba(0, 20, 9, 0.1);box-shadow: 0px 0px 30px 0 rgba(0, 20, 9, 0.1);-webkit-border-radius: 700px;
  -moz-border-radius: 700px;border-radius: 700px;height: 135px;width: 135px;display: flex;justify-content: center;align-items: center;
  margin: auto;}

.pt-process-2 .pt-process-icon .pt-process-number {position: absolute;right: 0;border-radius: 999px;background: #000;font-size: 18px;
  color: #fff;height: 45px;width: 45px;text-align: center;line-height: 45px;top: 0;bottom: auto;}

.pt-process-2 .pt-process-info {display: inline-block;width: 100%;margin-top: 30px;text-align: center;}

.pt-process-2 .pt-process-info p {margin-top: 5px;margin-bottom: 0;}

.pt-process-2 .pt-before-img {position: absolute;top: 50%;right: -16%;margin-top: -85px;height: 37px;transition: all .4s ease;
  animation: mymove 1.6s infinite;}

@keyframes mymove {
  0% {top: 50%;}

  50% {top: calc(50% + 6px);}

  100% {top: 50%;}
}

.elementor-kit-1 img {border-radius: 5px 5px 5px 5px;}

.pt-process-icon img {width: 52px;}

.pt-process-2 {text-align: center;padding: 0 15px;display: inline-block;}

.pt-process-title {font-size: 28px;line-height: 36px;font-weight: 600;text-transform: capitalize;}

.work_flow_section {padding-bottom: 80px;background: #f7f8f9;padding-top: 70px;}

.flow_items {margin-top: 15px;}

.pt-process-2 .pt-before-img.end_arrow {transform: rotate(90deg);top: 100%;animation: mymove_end 1.6s infinite;right: -27%;}

.even_row_flow_items .col-lg-4:first-child .pt-before-img {transform: rotate(90deg);top: 100%;animation: mymove_end 1.6s infinite;
  right: unset;left: -27%;}

@keyframes mymove_end {
  0% {top: 100%;}

  50% {top: calc(100% + 6px);}

  100% {top: 100%;}
}

.flow_items .row {margin-top: 60px;}

.flow_items .row:first-child {margin-top: 0;}

.even_row_flow_items .pt-process-2 .pt-before-img {left: -16%;right: unset;transform: rotateY(180deg);}

/********************************** service flow end**********************************/

/********************************** service start **********************************/
#desc_long{display: none;}
.testimonial-slider-two .owl-stage-outer {padding-top: 60px;padding-bottom: 25px;}

.testimonial-style-two .testimonial-item .wt-thumb-box .thumb {margin-top: -25px;width: 80px;}

.service-area .section-title.no_left_line {padding-left: 0;border-left: unset;}

div.block-style-eight {border: none;}

div.block-style-eight:hover {animation: shakeX linear 1s;}

.col-md-4.col-6 .counter_column_inn {display: flex;justify-content: center;background: #fff;padding: 40px;
  box-shadow: 0px 10px 30px 0px rgba(58, 65, 111, 0.1);}

.fancy-about-five .text-wrapper .experience-box div.content {padding-left: 0;}

.counter-wrapper.wow.fadeInUp {padding: 100px 0;color: #fff;background: #f7f8f9;}

.my_fancy.fancy-about-five .text-wrapper .experience-box {border-bottom: none;padding-bottom: unset;margin-bottom: unset;}

.counter-wrapper p {color: #000;}

.counter_icon {width: 80px;margin-right: 15px;}

.fancy-about .content .check-list {display: flex;flex-wrap: wrap;margin-top: 10px;padding-left: 0px;column-gap: 44px;}

.check-list li {width: calc(50% - 22px);margin-top: 15px;padding-left: 40px;position: relative;line-height: 23px;}

.fancy-text-block-eleven div.text-wrapper ul.check-list li {display: unset;margin-top: 0px;
  margin-bottom: 18px;}

ul.check-list.list-circle-bg li::before {position: absolute;left: 0;top: 0px;}

.features-area .block-style-eight:hover .text h3 {color: #000;}

.fancy-about-five .text-wrapper div.experience-box .content blockquote {padding-left: 0;border-left: unset;}

.fancy-about-five .text-wrapper .experience-box .content span.span span {color: var(--red-dark);font-weight: 700;font-size: 22px;}

.service_details_top_right img {border-radius: 50px 3px;}

.features-area .block-style-twenty {height: calc(100% - 30px);}

/********************************** service end **********************************/
/********************************** aboutus start **********************************/
.team-overlay .social-link li a {background: unset;color: #616161;width: unset;}

.only_text .owl-dots {left: 38px;bottom: 0;}

.history-content-box .quote-content {border-left: unset;padding-left: unset;}

.clients-style-two .client-item .client-img img {opacity: .7;filter: grayscale(1) invert(1);}

.block-style-seventeen .icon img {width: 50px;margin-bottom: 20px;}

.block-style-seventeen {min-height: 282px;}

.mission_vission {background-color: #f7f8f9;}

.each_about_section {margin-bottom: 30px;}

.each_about_section h2 {margin-bottom: 5px;font-size: 30px;}

.each_about_section .check-list li {margin-bottom: 5px;}

.each_about_section .check-list {margin-top: 15px;}

.about_para {margin-bottom: 20px;font-size: 20px;}

.banner_over_cnt.about_brand .section-title {padding-left: 0;border-left: unset;}

.about_brand_morebtn {margin-top: 50px;}

.about_acordion .faq-accordian .check-list li {width: 100%;}

.about_acordion .faq-accordian-two .card {margin-bottom: 15px;background-color: #f3f3f3;}

.about_top_rgt_img img {border-radius: 10px;}

.about_acordion .faq-img img {border-radius: 0 50px;}

.fancy-about-five .text-wrapper .experience-box .service_details_heading h2 {font-size: 42px;text-transform: unset !important;line-height: 46px;}

div.faq-accordian-two .card .card-header a[aria-expanded="true"]::after,
div.faq-accordian-two .card .card-header a[aria-expanded]::after {background-color: #555;}

div.faq-accordian-two .card .card-header a[aria-expanded="true"] {background-color: #3d3d3d;}

#more {display: none;}

#readmore {padding: 0 10px;margin-top: 7px;border-radius: 3px;}

/********************************** aboutus end **********************************/
/********************************** platform page start **********************************/
.contact-style-one .contact-form .main-btn {background: #db1c00;}

.platform_contact.contact-area.contact-style-one {position: relative;z-index: 1;}

.platform_contact.contact-area.contact-style-one::after {position: absolute;top: 0;left: 0;content: '';width: 100%;height: 100%;
  /* background: linear-gradient( -90deg, rgba(235, 86, 0, 0.88) 0%, rgba(219, 28, 0, 0.84) 99%, rgba(219, 28, 0, 0.89) 100%); */
  background-color: rgba(0, 0, 0, 0.75);z-index: -1;}

.platform_contact .sub-title.sub-title-bg.blue-light-bg {background: #fff;color: #000;}

.platform_contact .section-title h2 {color: #fff;}

.platform_icon_txt .gradient-bg-blue {background: var(--red-dark);}

.platform_icon_txt .block-style-six .icon .step {color: var(--red-dark);}

.platform_details .block-style-six {padding-bottom: 12px;}

.platform_details .section-title {margin-bottom: 25px;}

.platform_details.fancy-text-block-one .img-holder-box .img-holder-two {width: 65%;}

.platform_details .block-style-six .text h3 {font-weight: 600;}

.block-style-twenty .text h3 {font-weight: 600;}

.platform_feature_wraper {padding: 0 40px;}

.platform_feature_wraper .block-style-twenty {padding: 45px 30px 40px;min-height: 344px;}

.text-wrapper.platform_icon_txt {padding-right: 15px;}

.platform_banner_txt_inn {margin: 20px 0 50px;}

.dine_in_banner {background-image: url('image/platform/dine_in_banner.png');height: 521px;background-position: center;
  background-repeat: no-repeat;background-size: cover;display: flex;align-items: center;}

.dine_in_banner_txt h2 {font-weight: 500;font-size: 65px;color: #fff;text-transform: uppercase;display: inline-block;
  padding: 0 40px 15px 0;margin: 0 0 15px 0;border-bottom: 3px solid #db1c00;}

.dine_in_banner_txt p {font-weight: 400;font-size: 24px;color: #fff;max-width: 414px;}

/********************************** platform page end **********************************/
/********************************** portfolio start **********************************/
.portfolio-block-five .portfolio-content {background-color: rgba(136, 136, 136, 0.05);
  padding: 20px 10px;}

.portfolio-block-five .portfolio-content h3.title {font-weight: 600;}

.portfolio-block-five .portfolio-content .cat-btn {font-weight: 400;color: #000;transition: all .4s ease;}

.portfolio-area .col-lg-4:nth-child(2n) .portfolio-block-five .portfolio-img {
  background-color: #95c777ff;
}
.portfolio-area .col-lg-4:nth-child(3n) .portfolio-block-five .portfolio-img {
  background-color: #ff8588ff;
}


.portfolio-area .col-lg-4:nth-child(4n) .portfolio-block-five .portfolio-img {
  background-color: #8fabdd;
}
.portfolio-area .col-lg-4:nth-child(6n) .portfolio-block-five .portfolio-img {
  background-color: #404040ff;
}
.view_project {
  padding: 1px 35px 1px 15px;
  border-radius: 3px;
  transition: all .4s ease;
  opacity: 0;
  background: #494949;
  position: relative;
  color: #fff;
}
.view_project span{
  position: absolute;
  right: 10px;
  top:50%;
  transform: translateY(-50%);
  transition: all .3s ease;
}
.view_project:hover {
 
  background: var(--red-dark);
  color: #fff;
}
.view_project:hover span{
  right: 6px;
}



  .portfolio-block-five .portfolio-img {
    overflow: hidden;
    background-color: #bebebe;
    padding: 30px;
  }

.portfolio-filter-button .filter-btn li {font-size: 16px;margin: 0;padding: 7px 22px;
 font-weight: 500;border-bottom: 2px solid #ddd;}

/* .portfolio-filter-button .filter-btn li:last-child{border-right: 1px solid #ddd;} */

 .portfolio-filter-wrap{width: 100%;}

 #masonry-portfolio .row.masonry-row {padding: 30px 0;}

.portfolio-filter-button .filter-btn li:hover {border-color: var(--red-dark);}

.portfolio-filter-button .filter-btn li.active {border-color: var(--red-dark);}

.filter-btn {display: flex;justify-content: center;}

.portfolio-filter-button .filter-btn li.active::after {display: none;}

.portfolio-filter-button .filter-btn li:hover::after {display: none;}

.hide_ {display: none;}

.hide_.show {display: block;}
.portfolio-img-overlay {
  position: absolute;
  background: #0000008a;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 26px;
  cursor: pointer;
  opacity: 0;
  transition: all .3s ease;
  visibility: hidden;
}
.portfolio-block-five:hover .portfolio-img-overlay{
  opacity: 1;
  visibility: visible;
}
.portfolio-block-five:hover .view_project {
  opacity: 1;
  transform: translateY(-20px);
}
.portfolio-block-five:hover .portfolio-content .cat-btn {
  transform: translateY(-20px);
  opacity: 0;
}
.image-popup {
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #0000009e;
	display: flex;
	justify-content: center;
	align-items: center;
}
.cancel_img_popup {
  position: absolute;
  right: 15px;
  top: 15px;
}
.cancel_img_popup span {
	width: 40px;
	height: 40px;
	color: #fff;
	border: 2px solid #fff;
	border-radius: 50%;

	font-size: 22px;
	cursor: pointer;
  background: #000;
  position: relative;
}
.cancel_img_popup span:after {
content: '\f00d';
font-family: 'Font Awesome 5 Pro';
position: absolute;
top: 52%;
left: 50%;
transform: translate(-50%,-50%);
line-height: 0
}
/* .image-popup img {
  max-width: 55%;
} */
.cancel_img_popup {
  position: absolute;
  right: -23px;
  top: -17px;
}
.image-popup-inner {
  max-width: 55%;
  position: relative;
}

/********************************** portfolio end **********************************/
/********************************** why choose us start **********************************/
.why_choose_us_experties .block-style-twenty {min-height: 350px;}

.block-style-twenty .square_icon.icon {border-radius: 3px;position: absolute;width: 65px;height: 65px;top: 20px;right: 40px;margin: 0;}

.features-area .square_icon.icon img {width: 45px;}

.square_icon~.text h3 {width: 80%;}

.justify_txt {text-align: justify;}
.How_can_we_help .check-list li{
  width: 100%;
}

/********************************** why choose us end **********************************/
/********************************** contact start **********************************/
.contact-information-area .icon img {max-width: unset;}

.contact-information-style-one .information-cta {height: 100%;}

.contact-information-style-one div.information-wrapper {padding: 80px 53px 10px;}

.contact-information-style-one .information-wrapper .information-item .text h5 {font-weight: 600;}

.contact-information-style-one .information-wrapper .information-item .text p span {font-weight: 600;}

.contact-information-style-one .information-cta .information-box h3 {font-weight: 500;font-size: 27px;}

.contact-style-two .contact-form form .form_control {font-weight: 500;}
.error {position: absolute;bottom: 2px;color: #db1c00;left: 45px;font-size: 15px;}
.capcha_error.error {position: absolute;bottom: -30px;color: #db1c00;left: 45px;font-size: 15px;}
.contact-style-two .contact-form form .form_control:focus {
  background-color: rgba(20, 29, 56, 0.05);
}
.contact-style-two .contact-form form .form_control:focus::placeholder {
  color: var(--text-color);
}

/********************************** contact end **********************************/
/********************************** Cunsultancy start **********************************/
.innerBlock {padding-top: 35px;}

.nomber {position: relative;display: inline-block;display: inline-block;background: var(--red-dark);color: #fff;font-size: 30px;
  padding: 0 18px;position: relative;height: 48px;line-height: 48px;font-weight: 600;}

.nomber_span {color: #fff;font-size: 30px;height: 48px;line-height: 48px;font-weight: 600;}

.tilt {height: 100%;width: 30px;background: var(--red-dark);position: absolute;right: -7px;transform: skewX(12deg);z-index: -1;top: 0;bottom: 0;}

.block_heading {font-size: 42px;font-weight: 500;line-height: 48px;margin: 5px 0;}

.innerBlock {padding: 35px 0;margin: 25px 0;border-bottom: 1px dashed gray;}

.innerBlock:last-child {border: none;padding-bottom: 0;margin-bottom: 0;}

.innerBlock:first-child {padding-top: 0;}

.consultancy_banner img {width: 100%;}

.consultancy_banner {position: relative;margin-bottom: 15px;}

.consultancy_banner::before {content: "";width: 100%;position: absolute;left: 0;bottom: 0;top: 0;right: 0;background-color: #00000082;
  mix-blend-mode: multiply;}

.transparent_txt_banner_wrap h1 {color: #fff;opacity: .7;font-size: 80px;font-weight: 800;text-transform: uppercase;}

.transparent_txt_banner_wrap {position: absolute;top: 50%;transform: translateY(-50%);}

.grybg_consultancy .check-list li {width: 100%;}

.consultancy_desc_wrap p {text-align: justify;hyphens: auto;}

/********************************** Cunsultancy end **********************************/
/********************************** insight start **********************************/
section.blog-standard-style .blog-post-item .entry-content h3.title:hover {
  color: #000;
}
section.blog-standard-style .blog-post-item .entry-content h3.title a:hover {
  cursor:default;
}
.blog-standard-style .blog-post-item .entry-content h3.title {font-weight: 500;}

div.sidebar-widget-area .widget.recent-post-widget .post-thumbnail-content .post-title-date h6 {font-size: 18px;line-height: 21px;
cursor: pointer;transition: all .3s ease;}

div.sidebar-widget-area .widget.recent-post-widget .post-thumbnail-content .post-title-date h6:hover {color:var(--red-dark)}
  

.sidebar-widget-area .widget.widget-tag-cloud span {
  padding: 5px 15px;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 7px;
  margin-right: 2px;
  border-radius: 5px;
  font-weight: 500;
  background-color: #f7f8f9;
  transition: all .3s;
}

.sidebar-widget-area .widget h4.widget-title {font-weight: 500;}

.sidebar-widget-area .widget.recent-post-widget .post-thumbnail-content .post-title-date h6 {font-weight: 500;}

.sidebar-widget-area .widget.widget-banner .banner-content h3 {font-weight: 500;}

.entry-content .main-btn.filled-btn {font-weight: 500;
  background-color: var(--blue-light);
  color: #fff;
  border-color: transparent;}
  .blog-standard-style .blog-post-item .entry-content span.main-btn.filled-btn:hover {
    background-color: #000;
  }

.page-pagination ul li a {width: 45px;height: 45px;font-size: 18px;font-weight: 500;}

.b-user-admin a {background-color: #e6bcb6;}

.upload_btn{background: #db1c00;color: #fff;padding: 10px 30px;margin-bottom: 20px;border-radius: 3px;font-weight: 600;

letter-spacing: 1px;cursor: pointer;}

.login_submit.btn {margin-top: 20px;}

.blog-standard-style .blog-post-item .entry-content .main-btn.filled-btn:hover {cursor: pointer;}

/********************************** insight end **********************************/
/********************************** carrer start **********************************/
.block-style-twentyOne .text h4 {font-weight: 500;}

.block-style-twentyTwo .text h3 {font-weight: 500;}

.job-style-one .single-job-item .text h3.title {font-weight: 500;}

.job-style-one .single-job-item .text .main-btn {font-weight: 500;}

.main-btn {font-weight: 600;}

ul.check-list.list-circle-bg li::before {width: 22px;height: 22px;font-size: 12px;font-weight: 600;}

.no_vacancy{text-align: center;}

/********************************** case-study page start **********************************/
.case-item_inner {margin-bottom: 30px;overflow: hidden;position: relative;border-radius: 50px 5px;}

.p-title {font-size: 24px;font-weight: 500;bottom: 10px;left: 25px;padding-right: 10px;transition: all ease 700ms;-moz-transition: all ease 700ms;
  -webkit-transition: all ease 700ms;opacity: 0;visibility: hidden;position: absolute;z-index: 11;margin: 0;color: #fff;}

.p-icon {background: #BB0B0B;width: 50px;height: 50px;line-height: 55px;top: 25px;right: 0px;text-align: right;border-radius: 50%;
  transition: all ease 800ms;-moz-transition: all ease 800ms;-webkit-transition: all ease 800ms;opacity: 0;visibility: hidden;
  position: absolute;z-index: 11;margin: 0;display: flex;justify-content: center;align-items: center;color: #fff;}

.case-item_inner:hover .p-title {bottom: 20px;opacity: 1;visibility: visible;}

.case-item_inner:hover .p-icon {right: 25px;opacity: 1;visibility: visible;}

.case-item_inner::before {content: "";position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: #000000C4;opacity: 0;visibility: hidden;
  transition: all 0.4s ease 0s;}

.case-item_inner:hover::before {opacity: 1;visibility: visible;}

.case_study_page {padding: 60px 0;}

.p-category {display: block;font-size: 18px;font-weight: 400;}

.p-icon span {
  width: 100%;
  text-align: center;
}
.p-icon span i {
 transition: all .3s ease;
  }
.p-icon span:hover i {
margin-left: 15px;
}

/********************************** case-study page end **********************************/
/********************************** case-study detail page start **********************************/
.case_details_items {margin-top: 40px;}

.inner_banner {margin-bottom: 30px;}

.inner_banner img {width: 100%;}

.case_details_des_head {font-size: 42px;color: #000;margin-bottom: 8px;}
.case_details_des_head strong { font-weight: 500;line-height: 47px;}

.case_study_details_page li{
  margin-top: 15px;
padding-left: 40px;
position: relative;
line-height: 23px;
}
.case_study_details_page li::before {
  width: 22px;
  height: 22px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
left: 0;
top: 0px;
display: inline-flex;
margin-right: 13px;
width: 25px;
height: 25px;
border-radius: 50%;
align-items: center;
justify-content: center;
background-color: var(--purple-blue);
color: #fff;
content: '\f00c';
font-weight: 600;
font-family: 'Font Awesome 5 Pro';
}
.case_study_details_page .rerult_imgs {
	display: flex;
	gap: 20px;
	margin: 0 0 20px 0;
}
/********************************** case-study detail page end **********************************/
/********************************** our work process start **********************************/
.pt-tabs-1 .nav-tabs .nav-item.active {background: var(--red-dark);color: #fff;position: relative;}

.pt-tabs-1 .nav-tabs .nav-item {background: #f7f8f9;padding: 30px;border: 2px solid #f7f8f9;margin-right: 30px;position: relative;
  -webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px;flex: 1;font-size: 20px;line-height: 28px;font-weight: 600;color: #000;}

.pt-tabs-1 .nav-tabs .nav-item::after {top: 100%;left: 50%;border: solid transparent;content: "";height: 0;width: 0;position: absolute;
  pointer-events: none;border-color: rgba(255, 75, 54, 0);border-top-color: var(--white-color);border-width: 15px;margin-left: -15px;
  opacity: 0;transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;-moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;-webkit-transition: all 0.5s ease-in-out;}

.pt-tabs-1 .nav-tabs .nav-item.active::after {opacity: 1;border-top-color: var(--red-dark);}

.pt-tabs-1 .tab-content {margin-top: 45px;}

.pt-tabs-1 .nav-tabs {border: none;}

.our_work_process .pt-fancy-media img {width: 60px;}

.pt-fancy-media h3 {font-weight: 700;right: 15px;font-size: 60px;line-height: 68px;position: absolute;right: 45px;top: 40px;opacity: 0.1;}

.pt-fancybox-boxslider.fancyslider-2 {background: #fff;padding: 45px 30px;-webkit-border-radius: 5px;-moz-border-radius: 5px;
  border-radius: 5px;transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;-moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;-webkit-transition: all 0.5s ease-in-out;min-height: 374px;}
  #our_work_process .owl-nav {
    display: none;
  }

.our_work_process {padding-bottom: 140px;background: #f7f8f9;padding-top: 70px;}

.pt-fancy-media {margin-bottom: 30px;}

.pt-fancy-info .pt-fancy-title {margin-bottom: 5px;font-size: 28px;font-style: normal;line-height: 36px;font-weight: 600;}

.pt-fancy-info p {margin-bottom: 30px;}

.pt-button.pt-btn-link {color: var(--red-dark);}

.pt-tab-info h2 {font-weight: 500;margin-bottom: 15px;}

/********************************** our work process end **********************************/
footer {font-size: 16px;line-height: 22px;}

/********************************** privacy policy start  **********************************/
.std {padding: 15px 0;}

.std h3 {font-weight: 500;text-transform: uppercase;margin-bottom: 5px;}

.std h4 {font-weight: 500;color: #434343;}

.std p {margin-bottom: 15px;}

.std .check-list li {width: 100%;}

/********************************** privacy policy end  **********************************/
/********************************* faq page start **********************************/
.faq-heading{margin-top: 50px;}
.faq_acordion .faq-content-box-one {margin-left: 0;}
/********************************** faq page end **********************************/
.fancy-text-block-eleven .text-wrapper {
  padding-left: 0 !important;
}
.italian_text {
  font-style: italic;
  font-weight: 300;
}
.cta-style-three .cta-content-box h2 {
  font-size: 42px;
  line-height: 56px;
  font-weight: 600;
  margin-bottom: 50px;
}
.cta-area .main-btn.btn-purple {
  display: none;
}.pt-process-step {
  min-height: 363px;
}
.testimonial-style-two .testimonial-item .wt-content p {
  min-height: 213px;
  max-height: 213px;
    overflow-y: auto;
    margin-right: -40px;
  padding-right: 40px;
  text-align: justify;
  hyphens: auto;
}

/* blog-details */
.blog-details-wrapper .blog-post-item .entry-content h3.title {
  font-weight: 500;
  text-decoration: none;
}
.entry-content h5{
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 5px;
}
#comment-respond h4{
  font-weight: 600;
}
#comment-respond .form_control {
  font-weight: 500;
}
.blog-details-wrapper .post-thumbnail img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.error_section {
  margin: 130px auto;
  text-align: center;
}
.header-navigation.black_bg {
  background-color: #000;
}
.inner_pages_wrapper {
  padding-top: 91px;
}
/* chat design */
.mylivechat_collapsed {
  background-color: #db1c00 !important;
}
.mylivechat_expanded_title {
  background-color: rgb(219, 28, 0) !important;
  border: 0px solid rgb(219, 28, 0) !important;
  color: #fff !important;
}
.mylivechat_prechat_submit_button, .mylivechat_offline_submit_button, .mylivechat_prechat_survey_button {
  background-color: rgb(219, 28, 0) !important;
}
.mylivechat_closebtn {
  background-color: rgb(219, 28, 0) !important ;
}
.mylivechat_framebtm .mylivechat_sprite {
  border: solid 1px rgb(219, 28, 0) !important;
  background-color: rgb(219, 28, 0) !important;
}
/* scrolltotop button */
.scrollToTop {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #db1c00;
  color: #fff;
  padding: 13px 5px;
  writing-mode: vertical-rl;
  text-transform: capitalize;
  cursor: pointer;
  letter-spacing: 1px;
}
.success_message{
color: #37ad46;
background: #37ad461a;
padding: 5px 15px;
margin-bottom: 10px;
text-transform: capitalize;
}

/* all terms and condition pages */
.some_policys li{
  margin-top: 15px;
padding-left: 40px;
position: relative;
line-height: 23px;
}
.some_policys li::before {
  width: 22px;
  height: 22px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
left: 0;
top: 0px;
display: inline-flex;
margin-right: 13px;
width: 25px;
height: 25px;
border-radius: 50%;
align-items: center;
justify-content: center;
background-color: var(--purple-blue);
color: #fff;
content: '\f00c';
font-weight: 600;
font-family: 'Font Awesome 5 Pro';
}
.some_policys h1 {
  font-size: 42px;
  font-weight: 500;
  line-height: 48px;
  margin: 5px 0;
}
.some_policys ul {
  margin-bottom: 30px;
}
.nav_toggle_button {
  width: 30px;
  display: none;
}
.newsletter-inn{
  display: flex;
  justify-content: space-between;
}
ul.social-link {
  display: flex;
  align-items: center;
}
.social-link li a svg {
  width: 16px;
}
.testimonial-item .item-content p {
  color: #616161;
  font-size: 20px;
  line-height: 30px;
}
/* pagination start */
.pagination_wrapper .page-item.active .page-link {
  background-color: #db1c00;
  border-color: #db1c00;
}
.pagination_wrapper .page-link:hover {
  color: #fff;
  background-color: #db1c00;
  border-color: #db1c00;
}
.pagination_wrapper .page-link{
  color: #db1c00;
}
.pagination_wrapper .page-link:focus {
  box-shadow: 0 0 0 0.2rem #db1d0059;
}
.preloader.absolute {
  display: flex !important;
  position: absolute;
  /* left: 0; */
  height: 100%;
  width: 100%;
  z-index: 1;
}
.blog-standard-wrapper {
  position: relative;
}
/* pagination end */
/************************ custom mobile responsive start ************************/
@media only screen and (max-width: 480px) {
  /* firstcome popup start*/
  .firstcomeimg img {
    display: none;
  }
 
    /* firstcome popup  end*/
    .breadcrumbs .crumb a {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  body h2 {
    font-size: 30px;
  }
  .cursor.cursor--small {
    display: none;
  }
  body{
    cursor: context-menu;
  }
  .inner_pages_wrapper {
    padding-top: 67px;
    overflow: hidden;
  }
  /* header start */
  .nav_toggle_button {
    display: block;
  }
  .header-navigation .main-menu ul li > a {
    padding: 15px 0;
  }
  .header-navigation .main-menu ul li.has-children > a {
    display: inline-block;
  }
  .header-navigation .main-menu ul > li.has-children > a::after {
    display: none;
  }
  .header-navigation .main-menu ul li{
    display: block;
  }
  .main-menu {
    align-items: start;
    gap: 20px;
    flex-direction: column;
  }
  .nav-menu {
    position: absolute;
    top: 0;
    left: -100%;
    background: #000000d1;
    padding: 15px;
    height: 100vh;
    width: 80%;
    transition: all .3s ease;
    opacity: 0;
  }
  .nav-menu.active {
    left: 0;
    opacity: 1;
  }
  .header-navigation .primary-menu {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .header-navigation .main-menu ul li .sub-menu.sub-menu_about {
    padding: 10px;
  }
  .header-navigation .main-menu ul li .sub-menu.sub-menu_about li {
    width: 100%;
  }
  .header-navigation .main-menu ul li .sub-menu.sub-menu_about li a {
    padding: 7px 5px;
    min-height: 85px;
    justify-content: left;
    font-size: 20px;
  }
  .header-navigation .main-menu ul li:hover > .sub-menu {
    top: 50px;
    overflow: auto;
max-height: 50vh;
flex-wrap: nowrap;
  }
  .main-menu ul {
    width: 100%;
  }
  .header-navigation .main-menu ul li .sub-menu {
    position: absolute;
    width: 100%;
    flex-direction: column;
    padding: 35px 15px;
  }
  .extra_sub_menu_wrapper {
    width: 100%;
    border-right: none;
    padding: 0;
  }
  .header-navigation .main-menu ul li .sub-menu.sub-menu_about li a img {

    width: 40px;
  }
  .header-navigation .main-menu ul>li>a::before{
    display: none;
  }
  .has-children-arrow{
    display: inline-block;
    position: absolute;
right: 0;
top: 15px;
color: #fff;
padding-left: 35px;
  }


    /* header end */
    /* home page start */
    .fancy-about .text-wrapper {
      overflow: hidden;
      transform: none;
    }
    .hero-content.text-left h1 {
      font-size: 34px;
    }
    div.hero-wrapper-one .single-slider {
      padding: 130px 0 90px;
      height: unset;
    }
    .about-img-box,.text-wrapper {
      transform: none;
      opacity: 1 !important;
    }
    .section-title.case_study_title span.sub-title {
      margin-bottom: 15px;
      line-height: 25px;
    }
    .section-title h2 {
      line-height: 35px;
    }
    .blank_case_study {
      width: unset;
    }
    .owl-nav {
      display: none;
    }
    .service-area.pb-130{
      padding-bottom: 50px;
    }
.service-area.pt-130 {
  padding-top: 50px;
}
.case_study {
  padding: 50px 0px 70px 0px;
}
.service-style-one .service-wrapper {
  margin-left: 0;
}
.service-area .border-right-1 {
  border: none;
}
.service-style-one .service-wrapper::after {
  background-color: #b5b5b4;
  display: none;
}
.service-area .block-style-three {
  padding: 15px 0px 15px;
}
.portfolio {
	padding: 50px 0px 70px 0px;
}
.portfolio_title h2 {
  line-height: 35px;
}
span.sub-title {
  font-size: 18px;
}
.portfolio_title_main.side_line::before {
  height: 54px;

}
.testimonial-item {
  padding: 40px 10px 40px 10px;
}
.news_blog {
  padding: 50px 0px 70px 0px;
}
.owl-dots {
  bottom: -35px;
}
.banner_over_cnt p {
  font-size: 18px;
}
.banner_over_cnt h2 {
  font-size: 30px;
  margin-top: 5px;
}
.banner_over_cnt .brand {
  width: calc(50% - 20px);
  opacity: 1;
}
.banner_over_cnt .brands {
  gap: 40px;
}
.newsletter-style-one .newsletter-wrapper .newsletter-content-box .content h2 {
	font-size: 25px;
	line-height: 26px;
}
.newsletter-style-one .newsletter-wrapper {
	padding: 25px;
}
.newsletter-form .form_group {
  display: flex;
}
.newsletter-style-one .newsletter-wrapper .newsletter-form .main-btn {
	padding: 10px;
	font-size: 14px;
}
.newsletter-form {
  justify-content: start;
}
.newsletter-style-one .newsletter-wrapper .newsletter-content-box {
  margin-bottom: 5px;
}
.footer-default .footer-widget .footer-nav-widget {
  padding-left: 0;
  margin-top: 15px;
  transform: none;
    opacity: 1;
}
.footer-copyright ul.social-link {
  text-align: left;
}
.newsletter-style-one .newsletter-wrapper .newsletter-form .main-btn.transparent_btn {
  display: block;
}
.imp-link {
  gap: 10px;
  font-size: 12px;
  justify-content: center;
  margin-top: 10px;
}

.imp-link li::after {
	height: 11px;
width: 1px;
left: -5px;
top: 6px;
}
.footer-copyright.footer-copyright-bottom {
  font-size: 14px;
  line-height: 16px;
}

.footer-copyright .social-link {
  margin-top: 0px;
  justify-content: center;
  display: flex;
}
.newsletter-wrapper.bg_cover {
  opacity: 1;
  transform: none;
}
.newsletter-inn {
  flex-direction: column;
}
/* aboutus start */
.fancy-about.fancy-about-four.pt-60.pb-60 {
  padding-top: 30px;
}
.our_Delivery_Process.pt-60.pb-60 {
  padding-top: 30px;
  padding-bottom: 30px;
}
#masonry-portfolio.portfolio-area {
  padding-bottom: 30px;
  padding-top: 30px;
}
.case_study_page {
  padding: 30px 0;
}
.inner_pages_wrapper .features-area.features-style-four.pt-60 {
  padding-top: 30px;
}
.case-item_inner::before {
  opacity: 1;
  visibility: visible;
  background: #00000054;
}
.p-title {
  visibility: visible;
  opacity: 1;
}
.p-icon {
  opacity: 1;
  visibility: visible;
  right: 10px;
  background: #BB0B0BAB;
}
div.about_top_rgt_img {
  transform: none;
}
.faq-content-box-one {
  margin-left: 0;
}
.about_top_rgt_img {
  overflow: hidden;
}
.pt-tabs-1 .nav-tabs .nav-item {
  flex: auto;
  font-size: 16px;
  line-height: 16px;
  display: block;
  padding: 15px;
  margin-right: 0;
}
.pt-tabs-1 .nav-tabs .nav-item.active::after {
display:none;
}
.about-widge {
  opacity: 1 !important;
  transform: none;
}
.square_icon ~ .text h3 {
  width: 72%;
}
.why_choose_us_experties .block-style-twenty {
  min-height: unset;
  margin: 0 -15px;
}
.case-item_inner {
  transform: none !important;
  overflow: hidden;
}
.case_study_details_page .rerult_imgs {
  flex-direction: column;
}
/* service start */
.main-btn {
  padding: 12px 25px;
}
.fancy-about.fancy-about-five.pt-50.pb-80.my_fancy {
  padding-top: 30px;
}
.row.even_row_flow_items {
  flex-direction: column-reverse;
}
.pt-process-2 .pt-before-img {
  display: none;
}
.galllery_section .row.masonry-row {
  height: unset !important;
}
.galllery_section .col-lg-6.portfolio-column {
  position: unset !important;
}
.contact-style-three .contact-wrapper, .contact-style-one .contact-wrapper {
  background-color: #fff;
  padding: 40px 15px 30px;
}
.contact-style-three .contact-form .form_control, .contact-style-one .contact-form .form_control {
  height: 55px;
  padding: 0 15px;
  margin-bottom: 15px;
  font-weight: 600;
}
.col-md-4.col-6.counter-column {
  width: 100%;
  max-width: 100%;
  flex: unset;
}
.check-list li {
  width: 100% !important;
}
/* portfolio start */
.filter-btn {
  flex-wrap: wrap;
}
/* consultancy start */
.transparent_txt_banner_wrap h1 {
  font-size: 36px;

}
/* blog */
.blog-area.blog-standard-style.pt-60.pb-95 {
  padding-bottom: 30px;
  padding-top: 30px;
}
/* blog details */
.blog-details-wrapper .post-thumbnail img {
  width: 100%;
  height: auto;
  object-fit: unset;
}
.blog-details-style.pt-50.pb-95 {
  padding-bottom: 30px;
  padding-top: 30px;
}
/* contact */
.contact-information-style-one div.information-wrapper {
  padding: 30px 20px 10px;
}
.contact-information-style-one .information-cta {
  padding: 30px 20px 20px;
}
.contact-page-map {
  margin: 0 15px;
}
.contact-page-map .map-box iframe {
  height: 350px;
}
.contact-information-area.contact-information-style-one.pt-50.pb-80 {
  padding-bottom: 50px;
}
.contact-style-two .contact-form form .form_control {
  height: 50px;
  padding: 0 30px;
  margin-bottom: 15px;
  font-size: 18px;
}
.footer-widget .widget.about-widge {
  transform: none;
}
.footer-area .newsletter-wrapper.bg_cover {
  transform: none;
  opacity: 1;
}
.preloader .loader .pre-shadow {
  top: 25px;
  left: -25px;
}
.preloader .loader .pre-box {
  top: -35px;
  left: -25px;
}
.justify_txt{
hyphens: auto;
-moz-hyphens: auto;
-webkit-hyphens: auto;
word-break: break-word;
-ms-word-break: break-all;
word-wrap: break-word;
}
/* live chat */
.mylivechat_inline {
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
justify-content: end;
}
.mylivechat_expanded_outer {
  width: 100% !important;
  min-width: unset;
}
.mylivechat_collapsed_text {
  margin-right: 5px;
}
.mylivechat_collapsed .mylivechat_sprite svg[image="up"] {
  width: 26px;
  height: 26px;
  margin: -1px;
}
/* faq start */
.faq-accordian-two .card .card-header a {
  font-size: 16px;
  padding: 0 0 0 15px;
  line-height: 19px;
}
div.faq-accordian-two .card div.card-header a[aria-expanded]::after {
  background-color: transparent;
}

}
/************************ custom mobile responsive end ************************/

/* .contact_form11 .submitColor{
  background-color: red;
} */
.brand img {
  max-height: 45px;
}