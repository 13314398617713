.menu_list li{
    list-style-type: none;

}
.menu_list li a{
    text-decoration: none;
    font-size: 16px;
    color:#fff;

}
/* .menu_list{
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: end;
    padding: 0 20px;
    height: 100%;
} */
.menu_list ul{
    display: flex;
    align-items: center;
    gap: 30px;
}

.login_header{
    /* border:none;
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 18px; */
    cursor: pointer;
}
.login_modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000000d1;
	display: flex;
	justify-content: center;
	align-items: center;
    z-index: 999;
}
.login_modal form {
    width: 450px;
    background: #fff;

    padding: 30px;
    position: relative;
  }
  .login_modal form label{
    display: block;
    margin-bottom: 5px;
  }
  .login_modal form input{
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    padding: 0 10px;
  }
  .login_modal h4{
    font-size: 30px;
    margin-bottom: 20px;
  }
  .btn{
	background: #000;
    color:#fff;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px 30px;
    border:none;
    cursor: pointer;
  }
  .login_modal .close_btn{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    background: unset;
    border: none;
    cursor: pointer;
    width: 25px;
    height: 25px;
    line-height: 25px;
    background: #db1c00;
    color: #fff;
    border-radius: 3px;
  }
  .login_modal .close_btn span{
    position: relative;
    display: block;
  }
  .login_modal .close_btn span:after{
    content: '\f00d';
    font-family: 'Font Awesome 5 Pro';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 20px;
  }
 
  .welcome_message{
    font-size: 18px;
    color: #fff;
  }
  .firstcomeimg {
    overflow: hidden;
  }
  .firstcomeimg img {
    height: 100%;
    object-fit: cover;
  }
  .firstcome_wrapper {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
  }
  .firstcome_wrapper textarea {
    width: 100%;
    padding: 10px;
  }
  .success_message.firstcomeMessage {
    position: absolute;
    left: 0;
    top: 84px;
    z-index: 9;
    background: #37ad46ad;
    color: #fff;
    width: 100%;
    text-align: center;
  }
  .login_modal .error{
    left: 0;
    bottom:-24px
  }

  @media only screen and (max-width: 480px){
  .login_modal form {
    width: 100%;
  }
}