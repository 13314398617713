.loader_section{
    position: fixed;
    left: 0;
    top:0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,.8);
    z-index: 9999;
}