/*-----------------------------------------------------------------------------------

    Template Name: Muntech - IT Solutions & Technology HTML Template
    Description: Muntech - IT Solutions & Technology HTML Template which is perfectly suitable for the Agency, Apps, SaaS & Software, start-up, IT Solutions, and Services Company related to any business website
    Author: WebTend 
    Author URI: https://webtend.net/
    Version: 1.0

----------------------------------------------------------------------------------
    ===================
        CSS INDEX
    ===================

    
    01. Theme Common css
    02. Header css
    03. Hero css
    04. About css
    05. Service css
    06. Features css
    07. Element css
    08. Counter css
    09. Portfolio css
    10. Pricing css
    11. Testimonial css
    12. Team css
    14. Shop css
    15. Blog css
    16. Contact css
    17. Footer css

----------------------------------------------------------------------------------*/


/*---==========================
   01. Start Common  css 
=========================---*/
/* Google Font */
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&family=Roboto:wght@400;500;700&display=swap');
/* Animate css */
/* @import url(animate.css); */
/* Flaticon css */
/* @import url(flaticon.css); */
/* Fontawesome css */
/* @import url(all.min.css); */
/* Bootstrap css */
/* @import url(bootstrap.min.css); */
/* Slick css */
/* @import url(slick.css); */
/* Magnific css */
/* @import url(magnific-popup.css); */
/* Nice-select css */
/* @import url(nice-select.css); */
/* Jquery css */
/* @import url(jquery-ui.min.css); */

/* Root css */
:root {
	--text-color: #616161;
	--heading: #151515;
	--red-dark: #db1c00;
    --blue-light: #db1c00;
	/* --blue-light: #43baff; */
	--blue-dark: #0066ff;
	/* --purple-blue: #7141b1; */
    --purple-blue: #00c6db;
	--yellow-dark: #faa200;
    --orange-dark: #fc7561;
	--black-dark: #191919;
	--light-gray: #f7f8f9;
    --roboto: 'Jost', sans-serif;
	--rajdhani: 'Jost', sans-serif;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
    font-size: 100%;
}
a{
    color: inherit;
    text-decoration: none;
    transition: all .3s;
}
a:hover,a:focus{
    color: inherit;
    text-decoration: none;
}
i,
span,
a{
    display: inline-block;
}
h1,
h2,
h3,
h4,
h5{
    font-weight: 700;
	letter-spacing: -0.03em;
}
h1,
h2,
h3,
h4,
h5,
h6{
    color: #000000;
    margin: 0px;
    font-family: var(--rajdhani);
    font-weight: 700;
}
h1{
    font-size: 75px;
    line-height: 85px;
}
h2{
    font-size: 42px;
    line-height: 50px;
}
h3{
    font-size: 24px;
    line-height: 32px;
}
h4{
    font-size: 22px;

}
h5{
    font-size: 20px;
    line-height: 30px;
}
h6{
    font-size: 16px;
    line-height: 26px;
}
ul,
ol {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}
p {
	margin: 0px;
}
input,
textarea {
	display: inherit;
}
input:focus,
textarea:focus{
	outline: none;
}
img {
	max-width: 100%;
}
blockquote{
	margin: 0;
}
body {
	font-family: var(--roboto);
	font-weight: normal;
	font-style: normal;
	color: var(--text-color);
	font-size: 18px;
	line-height: 30px;
	overflow-x: hidden;
}
.bg_cover {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}
.slick-slide {
	outline: 0;
}
.form_group {
	position: relative;
}
.form_control{
	width: 100%;
	border: none;
	font-size: 20px;
    font-weight: 700;
    font-family: var(--rajdhani);
}
ul.social-link li{
	display: inline-block;
}
@media only screen and (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}
span.sub-title{
	display: inline-block;
	font-size: 20px;
	font-family: var(--rajdhani);
	font-weight: 700;
	text-transform: uppercase;
}
.section-title span.sub-title{
    margin-bottom: 13px;
}
.section-title h2{
	line-height: 54px;
	text-transform: capitalize;
}
.sub-title-bg{
	padding: 5px 15px;
	border-radius: 5px;
	color: #fff;
	margin-bottom: 25px;
}
.section-title-white h2{
	color: #fff;
}
.text-underline{
	text-decoration: underline;
}
.text-underline:hover{
	text-decoration: underline;
}

/* Colors */
.red-dark{
	color: var(--red-dark);
}
.blue-light{
    color: var(--blue-light);
}
.blue-dark{
    color: var(--blue-dark);
}
.yellow-dark{
    color: var(--yellow-dark);
}
.text-white{
    color: #fff;
}

/* All Bg */
.light-gray-bg{
	background-color: #f7f8f9;
}
.dark-black-bg{
	background-color: var(--black-dark);
}
.red-dark-bg{
	background-color: var(--red-dark);
}
.blue-light-bg{
	background-color: var(--blue-light);
}
.blue-dark-bg{
	background-color: var(--blue-dark);
}
.purple-blue-bg{
	background-color: var(--purple-blue);
}
.dark-blue-bg{
	background-color: #3c53ff;
}
.dark-yellow-bg{
	background-color: #ff7e00;
}
.dark-green-bg{
	background-color: #2bc48a;
}
.black-bg{
	background-color: #191919;
}
.gradient-bg-blue{
	background: linear-gradient( -90deg, rgb(41,129,233) 0%, rgb(62,67,220) 99%);
}
.block-white h3,
.block-white h4,
.block-white h5,
.block-white p{
	color: #fff;
}

/* Dark E-waller Home CSS */
.dark-e-wallet{
	background-color: #16171b;
	color: #abb0bf;
}


/* All Button */
button {
	border: none;
}
.main-btn{
	display: inline-flex;
    align-items: center;
	padding: 12px 35px;
	background-color: var(--red-dark);
	color: #fff;
	font-size: 16px;
	font-family: var(--rajdhani);
	text-transform: uppercase;
	/* text-decoration: underline; */
	font-weight: 700;
	border-radius: 5px;
    transition: all .3s;
}
.main-btn:hover{
    background-color: var(--heading);
    color: #fff;
}
.btn-red-dark{
    background-color: var(--red-dark)
}
.btn-purple{
	background-color: var(--purple-blue);
}
.btn-blue-light{
	background-color: var(--blue-light);
}
.btn-blue-dark{
	background-color: var(--blue-dark);
}
.btn-black{
	background-color: var(--black-dark);
}
.btn-orange-dark{
    background-color: var(--orange-dark);
}
.btn-white{
	background-color: #fff;
	color: var(--heading);
}
.btn-gray-dark{
	background-color: #32353d;
	color: #abb0bf;
}
.btn-gray-light{
    background-color: #374774;
    color: #fff;
}
.btn-gradient-blue{
    background-color: transparent;
    background: linear-gradient( to left, rgb(41,129,233) 0%, rgb(62,67,220) 99%);
}
.main-btn.filled-btn{
	color: var(--heading);
	background-color: transparent;
	border: 2px solid rgba(25, 25, 25, 0.1);
}
.main-btn.main-btn-sm{
	padding: 5px 20px;
}
.btn-link{
    font-family: var(--rajdhani);
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: 700;
    color: var(--text-color);
}
.btn-link:hover{
	color: var(--red-dark);
}

/* Extra css */
.mb-minus-130{
	margin-bottom: -130px;
}
.mb-minus-135{
	margin-bottom: -135px;
}
.mb-minus-75{
    margin-bottom: -75px;
}
.mt-minus-100{
    margin-top: -100px;
}
.mb-minus-100{
    margin-bottom: -100px;
}
.mb-90-minus{
    margin-bottom: -90px;
}
.custom-container{
	width: 1420px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.container-1600{
    max-width: 1630px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.container-1350{
    max-width: 1380px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
}
@media screen and (min-width: 1200px) and (max-width: 1400px){
	.custom-container{
		max-width: 1200px;
	}
}
@media screen and (max-width: 1199px){
	.custom-container{
		width: 100%;
	}
}
.w-100{
	width: 100%;
}

/* Preloader CSS */
.preloader {
    background-color: #fff;
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}
.preloader .loader {
    margin: 0 auto;
    position: relative;
    text-align: center;
}
.preloader .pre-box {
    width: 50px;
    height: 50px;
    background: var(--red-dark);
    animation: animate .5s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
}
.preloader .pre-shadow { 
    width: 50px;
    height: 5px;
    background: #000;
    opacity: 0.1;
    position: absolute;
    top: 59px;
    left: 0;
    border-radius: 50%;
    animation: shadow .5s linear infinite;
}
@keyframes animate {
    17% { border-bottom-right-radius: 3px; }
    25% { transform: translateY(9px) rotate(22.5deg); }
    50% {
      transform: translateY(18px) scale(1,.9) rotate(45deg) ;
      border-bottom-right-radius: 40px;
    }
    75% { transform: translateY(9px) rotate(67.5deg); }
    100% { transform: translateY(0) rotate(90deg); }
} 

@keyframes shadow {
    50% {
      transform: scale(1.2,1);
    }
}

/*
    Start scroll_up css
*/
.back-to-top {
    background: var(--red-dark);
    border-radius: 50%;
    bottom: 30px;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 20px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: fixed;
    right: 30px;
    text-align: center;
    text-decoration: none;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 337;
}
.back-to-top:hover, .back-to-top:focus {
    background: var(--heading);
    color: #fff;
}
/*
    Search CSS
*/
#search-modal{
    background: rgba(23, 26, 33, 0.85);
}
#search-modal .modal-content{
    background: 0 0;
    box-shadow: none;
    border: none; 
}
#search-modal .modal-content:focus{
    box-shadow: none; 
}
#search-modal .modal-content .form_control{
    padding-left: 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    color: #fff;
    margin-bottom: 0; 
    height: 50px;
    font-weight: 500;
}
#search-modal .modal-content .form_control:focus{
    border-color: #fff;
}
#search-modal .modal-content .form_control::placeholder{
    color: #fff; 
}
#search-modal .modal-content .search_btn{
    position: absolute;
    background: transparent;
    top: 10px;
    right: 10px;
    color: #fff;
}

/* Nice select */
.nice-select{
    padding: 0;
    padding-right: 10px;
}
.nice-select span.current:before{
    content: '\f0ac';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    margin-right: 7px;
}
.nice-select:after{
    content: '\f107';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 700;
    margin-left: 7px;
}

/*
    Nice Number CSS
*/
.nice-number {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: stretch;
	-ms-flex-pack: stretch;
	justify-content: stretch;
}
.nice-number input {
	vertical-align: middle;
	-moz-appearance: textfield;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	margin: 0;
	text-align: center;
}
.nice-number input::-webkit-inner-spin-button,
.nice-number input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Animation */
@-webkit-keyframes shakeX {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translate3d(-5px, 0, 0);
      transform: translate3d(-5px, 0, 0);
    }
  
    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translate3d(5px, 0, 0);
      transform: translate3d(5px, 0, 0);
    }
}
@keyframes shakeX {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translate3d(-5px, 0, 0);
      transform: translate3d(-5px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translate3d(5px, 0, 0);
      transform: translate3d(5px, 0, 0);
    }
}
/*---==========================
   End Common css 
=========================---*/


/*---==========================
   02. Start Header css 
=========================---*/
.transparent-header {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}
/* Header Top Bar */
.header-top-bar .top-left ul li{
    display: inline-block;
}
.header-top-bar .top-left ul li span i{
    margin-right: 10px;
}
.header-top-bar .top-right ul.social-link li{
    margin-left: 15px;
}
.header-top-bar .lang-dropdown .nice-select{
    background-color: transparent;
    border-radius: 0;
    border: none;
}
/* Header Top Bar One */
.header-top-bar-one{
    padding: 7px 0;
    background-color: #f7f8f9;
}
.header-top-bar-one .top-left ul li:after{
    display: inline-block;
    content: '|';
    margin-left: 10px;
    margin-right: 15px;
}
.header-top-bar-one .top-left ul li:last-child::after{
    display: none;
}
.theader-top-bar-one .top-left ul li span i{
    color: var(--red-dark);
}
.header-top-bar-one a:hover{
    color: var(--red-dark);
}
.header-top-bar-one .top-right{
    justify-content: end;
}
.header-top-bar-one .top-right span.phone i{
    color: var(--red-dark);
    margin-right: 10px;
}
.header-top-bar-one .top-right span.phone:after{
    display: inline-block;
    content: '|';
    margin-left: 10px;
    margin-right: 15px;
}
.header-top-bar-one .top-right ul.social-link li span.title{
    font-weight: 700;
    color: #151515;
    font-weight: 700;
}

/* Header Top Bar Two */
.header-top-bar-two{
    padding: 7px 0;
    background-color: #141d38;
}
.header-top-bar-two .top-left ul li:after{
    display: inline-block;
    content: '|';
    margin-left: 40px;
    margin-right: 35px;
}
.header-top-bar-two .top-left ul li:last-child:after{
    display: none;
}
.header-top-bar-two .top-left ul li span{
    color: #fff;
}
.header-top-bar-two .top-left ul li span i{
    color: var(--blue-light);
}
.header-top-bar-two .top-right{
    justify-content: end;
}
.header-top-bar-two .top-right ul.social-link li a{
    color: #fff;
}
.header-top-bar-two .top-right ul.social-link li a:hover{
    color: var(--blue-light);
}
.header-top-bar-two .top-right .lang-dropdown{
    display: inline-flex;
    align-items: center;
}
.header-top-bar-two .top-right .lang-dropdown:after{
    display: inline-flex;
    content: '|';
    margin-left: 15px;
    margin-right: 20px;
}
.header-top-bar-two .top-right .lang-dropdown .nice-select{
    color: #fff;
    font-size: 18px;
    font-family: var(--rajdhani);
    font-weight: 700;
} 
.header-top-bar-two .top-right .lang-dropdown .nice-select span.current::before{
    color: var(--blue-light);
}
.header-top-bar-two .top-right .lang-dropdown .nice-select ul.list .option{
    color: var(--heading);
}

/* Header Top Bar Three */
.header-top-bar-three{
    background-color: #141d38;
    padding: 7px 0;
}
.header-top-bar-three .top-left ul li:after{
    display: inline-block;
    content: '|';
    color: rgba(255, 255, 255, 0.15);
    margin-right: 35px;
    margin-left: 40px;
}
.header-top-bar-three .top-left ul li:last-child:after{
    display: none;
}
.header-top-bar-three .top-right ul.social-link li{
    margin-left: 15px;
}
.header-top-bar-three .top-right ul.social-link li a{
    color: #fff;
}
.header-top-bar-three .top-right ul.social-link li a:hover{
    color: var(--blue-light);
}
.header-top-bar-three .top-left ul li span{
    color: #fff;
}
.header-top-bar-three .top-left ul li span i{
    color: var(--blue-light);
}

/* Header Navigation Default */
.nav-ml-auto{
    margin-left: auto;
}
.nav-ml-mr-auto{
    margin-right: auto;
    margin-left: 120px;
}
.breakpoint-on .nav-ml-mr-auto{
    margin-left: 0px;
}
.header-top-bar-three .container-fluid,
.header-navigation .container-fluid{
    padding-left: 61px;
    padding-right: 61px;
}
.header-navigation .primary-menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-navigation .main-menu ul > li.has-children > a:after {
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    display: inline-block;
    color: #162542;
    font-size: 14px;
    margin-left: 5px;
    float: right;
}
.header-navigation .main-menu ul li {
    display: inline-block;
    position: relative;
}
.header-navigation .main-menu ul li:first-child > a {
    padding-left: 0px;
}
.header-navigation .main-menu ul li > a {
    display: block;
    font-size: 18px;
    color: #162542;
    font-family: var(--rajdhani);
    text-transform: uppercase;
    font-weight: 700;
    padding: 35px 18px;
    line-height: 1;
}
.header-navigation .main-menu ul li .sub-menu {
    position: absolute;
    left: 0;
    top: 120%;
    width: 200px;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 99;
    height: auto;
}
.header-navigation .main-menu ul li .sub-menu li {
    display: block;
    margin: 0;
}
.header-navigation .main-menu ul li .sub-menu li:last-child > a {
    border-bottom: none;
}
.header-navigation .main-menu ul li .sub-menu li a {
    display: block;
    padding: 7px 15px;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 0;
    line-height: 2;
    margin: 0;
    border-bottom: 1px solid #ececec;
    color: #1b3255;
}
.header-navigation .main-menu ul li .sub-menu li a:hover {
    color: #fff;
    border-color: transparent;
}
.header-navigation .main-menu ul li .sub-menu li .sub-menu {
    left: 100%;
    top: 50%;
}
.header-navigation .main-menu ul li .sub-menu li:hover .sub-menu {
    top: 0%;
}
.header-navigation .main-menu ul li .sub-menu li:hover > a {
    background-color: var(--red-dark);
    color: #fff;
}
.header-navigation .main-menu ul li:hover.has-children > a:after {
    color: var(--red-dark);
}
.header-navigation .main-menu ul li:hover > a {
    color: var(--red-dark);
}
.header-navigation .main-menu ul li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}
.header-navigation .main-menu ul li .dd-trigger {
    display: none;
}
.header-navigation.breakpoint-on .nav-menu {
    text-align: left;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 9999;
    width: 300px;
    height: 100%;
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    padding: 0;
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 70px;
}
.header-navigation.breakpoint-on .nav-menu.menu-on {
    left: 0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li {
    display: block;
    margin: 0;
    border-bottom: 1px solid #ececec;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li:last-child {
    border-bottom: 0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li.active .sub-menu {
    border-top: 1px solid #ececec;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li a {
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: var(--heading);
    padding: 13px 20px;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    background-color: transparent;
    visibility: visible;
    opacity: 1;
    display: none;
    -webkit-transition: none;
    transition: none;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a {
    color: #162542;
    padding: 0px 20px 0 40px;
    line-height: 45px;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover {
    border-color: rgba(255, 255, 255, 0.5);
}
.header-navigation .main-menu ul > li.has-children .sub-menu li.has-children:hover > a:after {
    color: #fff;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .dd-trigger {
    display: block;
    position: absolute;
    right: 0;
    height: 42px;
    width: 45px;
    top: 0;
    border-left: 1px solid #eaeaea;
    z-index: 2;
    background: transparent;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
    color: #162542;
    font-size: 20px;
}
.header-navigation.breakpoint-on .nav-menu .main-menu.menu-on {
    left: 0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li.search-item{
    display: none;
}
.header-navigation.breakpoint-on .navbar-close,
.header-navigation.breakpoint-on .navbar-toggler {
    display: block;
}
.header-navigation .header-right-nav {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.header-navigation .navbar-toggler {
    display: none;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.header-navigation .navbar-toggler span {
    position: relative;
    background-color: #162542;
    border-radius: 3px;
    display: block;
    height: 3px;
    margin-top: 5px;
    padding: 0;
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms;
    width: 30px;
    cursor: pointer;
    display: block;
}
.header-navigation .navbar-toggler.active span:nth-of-type(1) {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
    top: 8px;
}
.header-navigation .navbar-toggler.active span:nth-of-type(2) {
    opacity: 0;
}
.header-navigation .navbar-toggler.active span:nth-of-type(3) {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
    top: -8px;
}
.header-navigation .navbar-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 12;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    display: none;
    cursor: pointer;
    background: var(--red-dark);
}
.header-navigation .navbar-close i {
    color: #fff;
    font-size: 20px;
}
.navigation-white .navbar-toggler span{
    background-color: #fff;
}
.header-navigation.breakpoint-on .nav-menu .nav-search{
    display: block;
}
.nav-menu .nav-search{
    display: none;
    margin: 0 20px 20px;
}
.nav-menu .nav-search .form_group .form_control{
    border: 1px solid #e1e1e1;
    height: 60px;
    padding: 0 20px;
}
.nav-menu .nav-search .form_group .search-btn{
    position: absolute;
    top: 17px;
    right: 20px;
    background-color: transparent;
}

/* Header Right Nav */
.header-right-nav ul{
    display: inline-flex;
    align-items: center;
}
.header-right-nav>ul>li{
    margin-left: 25px;
}
.header-right-nav ul li.search-item a,
.header-right-nav ul li.login a,
.header-right-nav ul li.cart-item a{
    color: var(--heading);
}
.header-right-nav ul li.navbar-toggle-btn{
    display: none;
}
.header-right-nav ul li.nav-button,
.header-right-nav ul li.lang-dropdown{
    display: inline-flex;
    align-items: center;
}
.header-right-nav ul li.lang-dropdown .nice-select{
    padding: 0;
    border: none;
    font-size: 18px;
    font-weight: 700;
    background-color: transparent;
    border-radius: 0;
    color: var(--heading);
}
.header-right-nav ul li.lang-dropdown .nice-select .option{
    display: flex;
}
.header-right-nav ul li.lang-dropdown .nice-select ul.list{
    display: block;
}
.header-right-nav ul li.lang-dropdown:before{
    display: inline-flex;
    content: '|';
    color: rgba(25, 25, 25, 0.4);
    font-size: 20px;
    margin-right: 25px;
}
.header-right-nav ul li.navbar-toggle-btn{
    display: none;
}
.header-right-nav ul li.login a{
    color: var(--heading);
    font-family: var(--rajdhani);
    font-weight: 700;
    text-transform: uppercase;
}
/* Header Right Nav One */
.header-right-nav.header-right-nav-one ul li.lang-dropdown:before{
    display: none;
}
.header-right-nav.header-right-nav-one ul li.lang-dropdown:after {
    display: inline-flex;
    content: '|';
    color: var(--heading);
    font-size: 20px;
    margin-left: 25px;
}
/* Header Right Nav Two */
.header-right-nav.header-right-nav-two ul li .main-btn{
    border: 2px solid #e7e7e7;
    background: #fff;
    border-radius: 0;
    justify-content: space-between;
    color: var(--heading);
    align-items: center;
    text-decoration: none;
    padding: 6px 10px 6px 30px;
}
.header-right-nav.header-right-nav-two ul li .main-btn i{
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: linear-gradient( 90deg, rgb(0,41,157) 0%, rgb(0,120,238) 100%);
    margin-left: 10px;
}

/* Site Branding */
.site-branding{
    max-width: 200px;
}
/* Navigation Color Varient */
.navigation-blue-light .main-menu ul li .sub-menu li:hover >a{
    background-color: var(--blue-light);
}
.navigation-blue-light .main-menu ul li:hover.has-children > a:after,
.navigation-blue-light .main-menu ul li:hover > a{
    color: var(--blue-light);
}
.navigation-blue-dark .main-menu ul li .sub-menu li:hover >a{
    background-color: var(--blue-dark);
}
.navigation-blue-dark .main-menu ul li:hover.has-children > a:after,
.navigation-blue-dark .main-menu ul li:hover > a{
    color: var(--blue-dark);;
}
.navigation-white .main-menu ul li .sub-menu li:hover > a {
    background-color: var(--blue-light);
}
.navigation-white .main-menu ul li > a,
.navigation-white .main-menu ul > li.has-children > a:after{
    color: #fff;
}
.navigation-white .main-menu ul li:hover.has-children > a:after,
.navigation-white .main-menu ul li:hover > a {
    color: var(--blue-light);
}
.navigation-white .header-right-nav ul li.cart-item a{
    color: #fff;
}
.navigation-white .header-right-nav ul li.lang-dropdown .nice-select{
    color: #fff;
}
.navigation-white .header-right-nav ul li.lang-dropdown .nice-select .option{
    color: var(--heading);
}
/* Header Sticky */
.header-navigation.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: #fff;
    -webkit-animation: sticky 1.2s;
    animation: sticky 1.2s;
    box-shadow: 0px 10px 30px 0px rgb(110 104 162 / 10%);
}
.header-navigation.navigation-white.sticky{
    background-color: var(--heading);
}
@-webkit-keyframes sticky {
    0% {
      top: -200px;
    }
    100% {
      top: 0;
    }
}
@keyframes sticky {
    0% {
      top: -200px;
    }
    100% {
      top: 0;
    }
}
/*---==========================
   End Header css 
=========================---*/


/*---==========================
   03. Start Hero css 
=========================---*/

.hero-wrapper-one .single-slider{
    position: relative;
    padding: 162px 0 268px;
    z-index: 1;
}
.hero-wrapper-one .single-slider:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(25, 25, 25, 0.75);
    z-index: -1;
    mix-blend-mode: multiply;
}
.hero-slider-one .slick-dots {
    position: absolute;
    right: 100px;
    top: 45%;
    text-align: right;
    transform: translateY(-45%) rotate(90deg);
}
.hero-slider-one ul.slick-dots li{
    position: relative;
    width: 5px;
    height: 5px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
}
.hero-slider-one ul.slick-dots li.slick-active:after{
    border-color: #fff;
}
.hero-slider-one ul.slick-dots li:after{
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid transparent;
    margin-top: -10px;
    margin-left: -10px;
    transition: all .3s;
}
.hero-wrapper-one .hero-content span.sub-title{
    color: #fff;
    margin-bottom: 20px;
}
.hero-wrapper-one .hero-content h1{
    color: #fff;
    margin-bottom: 30px;
}
.hero-wrapper-one .hero-content ul.button li{
    display: inline-block;
    margin-right: 5px;
}

/* Hero Area Two */
.hero-wrapper-two{
    position: relative;
}
.hero-wrapper-two .hero-arrows{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
.hero-wrapper-two .hero-arrows .slick-arrow{
    position: relative;
    cursor: pointer;
    z-index: 1;
    width: 60px;
    height: 60px;
    background: #fff;
    color: var(--blue-dark);
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero-wrapper-two .hero-arrows .slick-arrow:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient( -90deg, rgb(41,129,233) 0%, rgb(62,67,220) 99%);
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
}
.hero-wrapper-two .hero-arrows .slick-arrow:hover{
    color: #fff;
}
.hero-wrapper-two .hero-arrows .slick-arrow:hover:after{
    visibility: visible;
    opacity: 1;
}
.hero-wrapper-two .single-slider{
    padding: 192px 0 200px;
    position: relative;
    z-index: 1;
}
.hero-wrapper-two .single-slider:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(25, 25, 25, 0.65);
    mix-blend-mode: multiply;
    z-index: -1;
}
.hero-wrapper-two .hero-content span.sub-title{
    color: #fff;
    margin-bottom: 18px;
}
.hero-wrapper-two .hero-content h1{
    font-size: 75px;
    line-height: 85px;
    color: #fff;
    margin-bottom: 15px;
}
.hero-wrapper-two .hero-content p{
    color: #fff;
    margin-bottom: 33px;
}
.hero-wrapper-two .hero-content ul.button li{
    display: inline-block;
}
.hero-wrapper-two .hero-content ul.button li:last-child{
    margin-left: 5px;
}

/* Hero Area Three */
.hero-wrapper-three .single-slider{
    position: relative;
    z-index: 1;
    padding: 310px 0 180px;
}
.hero-wrapper-three .single-slider:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(20, 29, 56, 0.8);
    mix-blend-mode: multiply;
    z-index: -1;
}
.hero-wrapper-three .hero-content .sub-title{
    color: #fff;
    margin-bottom: 25px;
    background-color: #7d76eb;
    padding: 5px 20px;
    border-radius: 5px;
}
.hero-wrapper-three .hero-content h1{
    font-size: 130px;
    line-height: 120px;
    color: #fff;
    margin-bottom: 50px;
}
.hero-wrapper-three .hero-content ul.button li{
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}

/* Hero Area Four */
.hero-wrapper-four{
    position: relative;
    padding: 130px 0 165px;
    z-index: 1;
}
.hero-wrapper-four .shape-icon{
    position: absolute;
}
.hero-wrapper-four .shape-icon-one{
    left: 7%;
    top: 10%;
}
.hero-wrapper-four .shape-icon-two{
    left: 7%;
    bottom: 10%;
}
.hero-wrapper-four .hero-content span.sub-title{
    display: block;
	font-size: 20px;
	font-family: var(--rajdhani);
	font-weight: 700;
	text-transform: uppercase;
    color: var(--blue-dark);
    text-decoration: underline;
    margin-bottom: 20px;
}
.hero-wrapper-four .hero-content h1{
    margin-bottom: 10px;
}
.hero-wrapper-four .hero-content p{
    margin-bottom: 30px;
}
.hero-wrapper-four .hero-content ul.button li{
    display: inline-block;
}
.hero-wrapper-four .hero-content ul.button li .video-popup{
    display: flex;
    align-items: center;
    margin-left: 5px;
}
.hero-wrapper-four .hero-content ul.button li .video-popup .icon{
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 13px;
    margin-right: 10px;
    background: linear-gradient( 90deg, rgb(255,58,52) 0%, rgb(255,111,51) 99%);
}
.hero-wrapper-four .hero-content ul.button li .video-popup span{
    font-family: var(--rajdhani);
    text-decoration: underline;
    color: var(--heading);
    font-weight: 700;
}
.hero-wrapper-four .hero-content ul.button li .video-popup:hover span{
    color: var(--blue-dark);
}
.hero-wrapper-four .hero-img img{
    max-width: 775px;
}

/* Hero Area Five */
.hero-wrapper-five{
    background-color: #f2f5fa;
    padding: 110px 0;
}
.hero-wrapper-five .hero-content h1{
    line-height: 75px;
    margin-bottom: 35px;
}
.hero-wrapper-five .hero-content .check-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.hero-wrapper-five .hero-content .check-list li{
    width: 50%;
    margin-bottom: 20px;
}
.hero-wrapper-five .hero-content  ul.check-list.list-circle-bg li:before{
    background-color: var(--blue-light);
}
.hero-wrapper-five .hero-content ul.button li{
    display: inline-block;
}
.hero-wrapper-five .hero-content ul.button li:last-child{
    margin-left: 10px;
}
.hero-wrapper-five .hero-img img{
    max-width: 890px;
}

/* Hero Area Six */
.hero-wrapper-six{
    position: relative;
    z-index: 1;
    padding: 230px 0 160px;
}
.hero-wrapper-six .hero-bg{
    position: absolute;
    top: 0;
    right: 0;
}
.hero-wrapper-six .hero-content span.sub-title{
    margin-bottom: 15px;
}
.hero-wrapper-six .hero-content h1{
    margin-bottom: 15px;
}
.hero-wrapper-six .hero-content p{
    font-size: 18px;
    line-height: 35px;
    margin-bottom: 30px;
}
.hero-wrapper-six .hero-content ul.button li{
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}
.hero-wrapper-six .hero-content ul.button li:last-child{
    margin-right: 0;
}
.hero-wrapper-six .hero-content ul.button li .main-btn{
    position: relative;
    background-color: transparent;
    align-items: center;
    background: linear-gradient( 90deg, rgb(0,41,157) 0%, rgb(0,120,238) 100%);
    color: #fff;
    border-radius: 0;
    padding: 12px 10px 12px 30px;
    text-decoration: none;
}
.hero-wrapper-six .hero-content ul.button li .main-btn i{
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: var(--heading);
    margin-left: 35px;
}
.hero-wrapper-six .hero-content ul.button li .main-btn.filled-btn{
    background: #fff;
    color: var(--heading);
}
.hero-wrapper-six .hero-content ul.button li .main-btn.filled-btn i{
    background: linear-gradient( 90deg, rgb(0,41,157) 0%, rgb(0,120,238) 100%);
    color: #fff;
}

/* Hero Seven css */
.hero-wrapper-seven{
    position: relative;
    padding: 207px 0 135px;
    z-index: 1;
}
.hero-wrapper-seven .shape-icon{
    position: absolute;
    z-index: -1;
}
.hero-wrapper-seven .shape-icon.shape-icon-one{
    top: 30%;
    left: 5%;
}
.hero-wrapper-seven:after{
    position: absolute;
    top: 0;
    right: 27%;
    transform: translateX(27%);
    content: '';
    width: 450px;
    height: 100%;
    /* background: url(../image/hero-bg-1.png) no-repeat center; */
    background-size: cover;
    z-index: -1;
}
.hero-wrapper-seven .hero-content h1{
    color: #fff;
    margin-bottom: 15px;
}
.hero-wrapper-seven .hero-content p{
    margin-bottom: 35px;
}
.hero-wrapper-seven .hero-content .main-btn{
    text-decoration: none;
}
.hero-wrapper-seven .hero-content ul.button li{
    display: inline-block;
    margin-bottom: 10px;
}
.hero-wrapper-seven .hero-content ul.button li:last-child{
    margin-left: 10px;
}
.hero-wrapper-seven .hero-content .main-btn i{
    margin-left: 10px;
}
.hero-wrapper-seven .hero-content .icon-box .text p,
.hero-wrapper-seven .hero-content .icon-box .text h5{
    color: #fff;
}
.hero-wrapper-seven .hero-content .icon-box .icon{
    margin-right: 18px;
}
.hero-wrapper-seven .hero-content .icon-box i{
    color: #fff;
    font-size: 45px;
}
.hero-wrapper-seven .hero-content .icon-box .text p{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}
.hero-wrapper-seven .hero-img{
    position: relative;
    margin-right: -50px;
}
.hero-wrapper-seven .hero-img .sm-img{
    position: absolute;
}
.hero-wrapper-seven .hero-img .sm-img-one{
    top: 60px;
    left: 35px;
}
.hero-wrapper-seven .hero-img .sm-img-two{
    top: 80px;
    right: 50px;
}
.hero-wrapper-seven .hero-img .sm-img-three{
    top: 30%;
    left: 0;
}

/* Breadcrumbs CSS */
.breadcrumbs-wrapper{
    position: relative;
    z-index: 1;
    padding: 165px 0 170px;
}
.breadcrumbs-wrapper:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: -1;
}
.breadcrumbs-wrapper .page-title-text h1{
    color: #fff;
    text-decoration: underline;
    margin-bottom: 20px;
}
.breadcrumbs-wrapper .page-title-text ul.breadcrumbs-link li{
    display: inline-block;
    font-size: 24px;
}
.breadcrumbs-wrapper .page-title-text ul.breadcrumbs-link li:after{
    display: inline-block;
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    margin-left: 20px;
    margin-right: 15px;
    color: #fff;
}
.breadcrumbs-wrapper .page-title-text ul.breadcrumbs-link li:last-child:after{
    display: none;
}
.breadcrumbs-wrapper .page-title-text ul.breadcrumbs-link li.active{
    color: var(--blue-light);
}
.breadcrumbs-wrapper .page-title-text ul.breadcrumbs-link li a{
    color: #fff;
}

.list-style-one{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.list-style-one li{
    width: 50%;
    margin-bottom: 20px;
    font-weight: 600;
    color: var(--heading);
}
.list-style-one li:before{
    display: inline-flex;
    margin-right: 13px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    content: '\f00c';
    font-weight: 600;
    font-family: 'Font Awesome 5 Pro';
    color: #fff;
}
.list-gradient-blue li:before{
    background: linear-gradient( to left, rgb(41,129,233) 0%, rgb(62,67,220) 99%);
}
.list-circle-blue-light li:before{
    background-color: var(--blue-light);
}

/*---==========================
   End Hero css 
=========================---*/

/*---==========================
   04. Start About css 
=========================---*/
.quote-content{
    font-weight: 500;
    font-style: italic;
    line-height: 32px;
    color: var(--heading);
    padding-left: 20px;
    border-left: 3px solid var(--purple-blue);
}
.quote-blue-light{
    border-left-color: var(--blue-light);
}
/* About Style One */
.fancy-about-one:after{
    position: absolute;
    top: -110px;
    left: 120px;
    width: 390px;
    height: 390px;
    border-radius: 50%;
    content: '';
    background-color: var(--red-dark);
    z-index: -1;
}
.fancy-about-one .text-wrapper p{
    margin-bottom: 33px;
}

/* About Style One */
.fancy-about-two {
    position: relative;
    overflow: hidden;
}
.fancy-about-two .about-circle-img{
    position: absolute;
    top: -50px;
    left: 150px;
    z-index: 1;
}
.fancy-about-two .about-circle-img img{
    width: 390px;
    height: 390px;
    border-radius: 50%;
}
.fancy-about-two .text-wrapper{
    margin-left: 70px;
}
.fancy-about-two .text-wrapper .text-box {
    display: flex;
    align-items: flex-start;
}
.fancy-about-two .text-wrapper .text-box .big-text{
    font-size: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    background-color: #fff;
    box-shadow: 0px 0px 30px 0px rgba(153, 153, 153, 0.19);
    border-radius: 7px;
    color: var(--primary);
    font-weight: 700;
    margin-right: 25px;
}
.fancy-about-two .text-wrapper .text-box p{
    margin-bottom: 0;
}
.fancy-about-two .text-wrapper p{
    margin-bottom: 25px;
}
.fancy-about-two .text-wrapper ul.list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.fancy-about-two .text-wrapper ul.list li{
    position: relative;
    padding-left: 35px;
    width: 50%;
    margin-bottom: 20px;
}
.fancy-about-two .text-wrapper ul.list li:after{
    position: absolute;
    top: 2px;
    left: 0;
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient( -90deg, rgb(41,129,233) 0%, rgb(62,67,220) 99%);
    color: #fff;
    border-radius: 50%;
    font-size: 13px;
}
  
/* About Style three */
.fancy-about-three .img-holder{
    margin-right: -40px;
}
.fancy-about-three .text-wrapper .experience-box {
    display: flex;
    align-items: center;
}
.fancy-about-three .text-wrapper .experience-box .year,
.fancy-about-three .text-wrapper .experience-box .text{
    width: 50%;
}
.fancy-about-three .text-wrapper .experience-box .text h4{
    font-size: 22px;
    line-height: 35px;
}
.experience-box .year h2{
    background: url(../image/about/text-bg.jpg) center;
    background-repeat: repeat;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    text-align: center;
    font-weight: bold;
    font-size: 220px;
    line-height: 1;
}
.fancy-about-three .text-wrapper ul.button{
    display: inline-flex;
    align-items: center;
}
.fancy-about-three .text-wrapper ul.button li a.support{
    display: flex;
    line-height: 1;
    margin-left: 20px;
}
.fancy-about-three .text-wrapper ul.button li a.support .icon{
    margin-right: 13px;
}
.fancy-about-three .text-wrapper ul.button li a.support .icon i{
    font-size: 40px;
    line-height: 1;
    color: var(--blue-light);
}
.fancy-about-three .text-wrapper ul.button li a.support .info span.span{
    text-transform: uppercase;
    font-family: var(--rajdhani);
    font-weight: 700;
}

/* About Four */
.fancy-about-four .about-content-box p{
    margin-bottom: 25px;
}
.fancy-about-four .about-content-box ul.check-list{
    display: flex;
    flex-wrap: wrap;
}
.fancy-about-four .about-content-box ul.check-list li{
    width: 50%;
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 20px;
}
.fancy-about-four .img-holder img{
    width: 100%;
}
.fancy-about-four .experience-box .year h2{
    line-height: 1;
    font-family: var(--rajdhani);
    font-weight: 700;
}

/* About Five */
.fancy-about-five .text-wrapper .experience-box{
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(20, 29, 56, 0.07);
    padding-bottom: 15px;
    margin-bottom: 50px;
}  
.fancy-about-five .text-wrapper .experience-box .big-text{
    font-size: 300px;
    font-family: var(--rajdhani);
    font-weight: 700;
    color: var(--blue-light);
    line-height: 85px;
}
.fancy-about-five .text-wrapper .experience-box .content{
    padding-left: 35px;
}
.fancy-about-five .text-wrapper .experience-box .content span.span{
    font-size: 18px;
    font-weight: 600;
    font-family: var(--rajdhani);
    text-transform: uppercase;
    margin-bottom: 5px;
}
.fancy-about-five .text-wrapper .experience-box .content h2{
    font-size: 30px;
    line-height: 32px;
    margin-bottom: 15px;
}
.fancy-about-five .text-wrapper .experience-box .content blockquote{
    padding-left: 25px;
    border-left: 4px solid #c72004;
}

/* History CSS */
.history-style-one .history-img{
    width: 100%;
    height: 100%;
}
.history-style-one .history-content-box .year-box{
    background-color: var(--blue-light);
    padding: 30px 70px;
}
.history-style-one .history-content-box .year-box h3{
    color: #fff;
    font-size: 35px;
    letter-spacing: -0.03em;
    line-height: 45px;
}
.history-style-one .history-content-box .year-box span.text{
    font-weight: 500;
    color: #fff;
}
.history-style-one .history-content-box .content-box{
    background-color: #f8f8f8;
    padding: 50px 70px;
}
.history-style-one .history-content-box .content-box .quote-content{
    font-size: 26px;
    line-height: 36px;
    font-style: normal;
    font-family: var(--rajdhani);
    font-weight: 700;
    margin-bottom: 22px;
}
.history-style-one .history-content-box .year-box span.text:before{
    display: inline-block;
    content: '';
    width: 30px;
    height: 3px;
    background-color: #fff;
    vertical-align: middle;
    margin-right: 10px;
}
.history-content-slider .slick-dots{
    text-align: left;
}
.history-content-slider .slick-dots li{
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 5px solid #fff;
    background-color: var(--blue-light);
    margin-right: 5px;
    transition: all .3s;
}
.history-content-slider .slick-dots li.slick-active{
    border-color: var(--blue-light);
    background-color: #fff;
}
/*---==========================
   End About css 
=========================---*/

/*---==========================
   05. Start Service css 
=========================---*/

/* Service Style One */
.border-right-1{
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.service-style-one .text-wrapper p{
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.6);
}
.service-style-one .service-wrapper{
    position: relative;
    margin-left: 80px;
}
.service-style-one .service-wrapper:after{
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
}

/* Service Style Two */
.service-style-two{
    
}

/* Service Style Three */
.service-style-three{
    position: relative;
    margin-top: -205px;
}
.service-style-three .service-wrapper{
    padding: 80px 60px;
    background-color: #fff;
    box-shadow: 0px 10px 30px 0px rgba(110, 104, 162, 0.1);
}
.service-style-three .service-slider-one{
    margin-left: -5px;
    margin-right: -5px;
}
.service-slider-one .block-style-nine{
    margin-left: 5px;
    margin-right: 5px;
}
.service-style-three .service-arrows{
    display: flex;
    justify-content: end;
}
.service-style-three .service-arrows .slick-arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;
    color: var(--purple-blue);
    background-color: rgba(113, 65, 177, 0.07);
    transition: all .3s;
}
.service-style-three .service-arrows .slick-arrow:hover{
    background-color: var(--purple-blue);
    color: #fff;
}
.service-style-three .service-arrows .slick-arrow.next{
    margin-left: 10px;
}
/* Service Style Four */
.service-style-four .service-item-wrapper .col-lg-3{
    flex: 0 0 20%;
    max-width: 20%;
}
/*---==========================
   End Service css 
=========================---*/

/*---==========================
   06. Start Features css 
=========================---*/
/* Features Style One */
.features-style-one{
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-top: -100px;
}
.features-style-one:after {
    position: absolute;
    top: 0;
    right: 0;
    left: calc((100% - 1330px) / 2);
    content: '';
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    z-index: -1;
}

/* Features Style Two */
.features-style-two{
    position: relative;
    z-index: 1;
}
.features-style-two:after{
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    /* background: url(../image/pattern-gray.png) no-repeat center; */
    background-size: cover;
    z-index: -1;
}
.features-style-two .text-wrapper h3{
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
}

/* Features Style Five */
.features-style-three .features-wrapper {
    position: relative;
    background-color: #0a0b11;
    z-index: 1;
}
.features-style-three .features-wrapper .shape-icon{
    position: absolute;
}
.features-style-three .features-wrapper .shape-icon.shape-icon-one{
    top: 25%;
    left: 7%;
}
.features-style-three .features-wrapper .shape-icon.shape-icon-two{
    top: 15%;
    right: 15%;
}
.features-style-three .features-wrapper .shape-icon.shape-icon-three{
    bottom: 12%;
    right: 5%;
}
/* Job Section */
.job-style-one .single-job-item{
    padding: 40px 20px;
    background-color: #fff;
}
.job-style-one .single-job-item .icon{
    margin-bottom: 22px;
}
.job-style-one .single-job-item .text .main-btn{
    padding: 10px 25px;
    line-height: 20px;
}
.job-style-one .single-job-item .text span.salary{
    color: var(--purple-blue);
    font-weight: 500;
    margin-bottom: 10px;
}
.job-style-one .single-job-item .text span.salary i{
    margin-right: 10px;
}
.job-style-one .single-job-item .text p{
    margin-bottom: 22px;
}
.job-style-one .single-job-item .text h3.title{
    margin-bottom: 5px;
}
.job-style-one .single-job-item .text h3.title:hover{
    color: var(--purple-blue);
}
.job-style-one .single-job-item .text h3.title a{
    text-decoration: none;
}
.job-style-one .single-job-item .main-btn:hover{
    background-color: var(--blue-light);
    color: #fff;
}
/*---==========================
   End Features css 
=========================---*/

/*---=========================
   07. Start Element css 
=========================---*/

/* Block  One */
.animate-icon:hover .icon{
    animation: shakeX linear 1s;
}
/* Block One css */
.block-style-one .icon{
    flex: 0 0 45px;
    max-width: 45px;
    margin-right: 20px;
}
.block-style-one .icon i{
    font-size: 45px;
    line-height: 1;
    color: var(--red-dark);
}
.block-style-one .text{
    margin-top: -7px;
}
.block-style-one .text h3.title{
    margin-bottom: 7px;
}
.block-style-two:hover  .text h3,
.block-style-one:hover .text h3.title{
    color: var(--red-dark);
}
/* Block  Two css */
.block-style-two{
    position: relative;
    background-color: #fff;
    padding: 50px 35px 40px;
    z-index: 1;
}
.block-style-two:hover:after{
    transform: rotate(-15deg);
}
.block-style-two:after{
    content: '';
    width: 60px;
    height: 60px;
    position: absolute;
    top: 40px;
    right: 40px;
    background: url(../image/dot.png) no-repeat center;
    background-size: cover;
    z-index: -1;
    transition: all .3s;
}
.block-style-two .icon{
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}
.block-style-two .text h3{
    margin-bottom: 10px;
    text-transform: uppercase;
}

/* Block  Three Css */
.block-style-three{
    display: flex;
    padding: 60px 50px 50px;
}
.block-style-three.border-right-1{
    padding-left: 0;
}
.block-style-three .icon{
    margin-right: 30px;
}
.block-style-three .icon i{
    font-size: 55px;
    line-height: 1;
    color: var(--red-dark);
}
.block-style-three .text h3.title{
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 7px;
}
.block-style-three .text h3.title:hover{
    color: var(--red-dark);
}
.block-style-three .text p{
    color: rgba(255, 255, 255, 0.6);
}

/* Block  Four css */
.block-style-four:hover .text{
    transform: translateY(-40px);
}
.block-style-four .text{
    padding: 15px 30px;
    background-color: #fff;
    width: 90%;
    margin: auto;
    border-radius: 7px;
    transform: translateY(-53px);
    position: relative;
    transition: all .3s;
}
.block-style-four .text h3.title{
    margin-bottom: 7px;
}
/* Block  Five  css*/

.block-style-five .icon{
    margin-right: 30px;
}
.block-style-five .icon i{
    line-height: 1;
    font-size: 55px;
    color: #fff;
}
.block-style-five .text{
    padding-right: 30px;
}
.block-style-five .text h3.title{
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 30px;
}
.block-style-five .text p,
.block-style-five .text h3.title{
    color: #fff;
}

/* Block  Six css */

.block-style-six{
    padding-bottom: 34px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.07);
}
.block-style-six:last-child{
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
}
.block-style-six .icon{
    position: relative;
    flex: 0 0 70px;
    max-width: 70px;
    height: 70px;
    font-size: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 20px;
}
.block-style-six .icon .step{
    position: absolute;
    top: -15px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 13px;
    font-weight: 700;
    color: var(--blue-dark);
    background-color: #fff;
    box-shadow: 0px 16px 60px 0px rgba(0, 0, 0, 0.56);
}
.block-style-six .text{
    margin-top: -7px;
}
.block-style-six .text h3{
    margin-bottom: 5px;
}
/* Block  Seven css */
.block-style-seven{
    margin-bottom: 35px;
}
.block-style-seven .icon{
    flex: 0 0 50px;
    max-width: 50px;
    margin-right: 15px;
}
.block-style-seven .text{
    margin-top: -7px;
}
.block-style-seven .icon i{
    font-size: 50px;
    color: var(--blue-dark);
}

/* Block  Eight css */

.block-style-eight{
    padding: 25px 30px 15px;
    background-color: #fff;
    border: 5px solid #fff;
    box-shadow: 0px 10px 30px 0px rgba(58, 65, 111, 0.1);
    transition: all .3s;
}
.block-style-eight .icon{
    margin-right: 30px;
    font-size: 40px;
    line-height: 1;
    color: var(--purple-blue);
}
.block-style-eight:hover{
    border-color: var(--blue-light);
}
.block-style-eight .text{
    margin-top: -7px;
}
.block-style-eight .text h3{
    margin-bottom: 10px;
}
.block-style-eight:hover .text h3{
    color: var(--purple-blue);
}
/* Block  Nine css */
.block-style-nine{
    padding: 35px 30px 30px;
    background-color: rgba(67, 186, 255, 0.07);
}
.block-style-nine .icon i{
    font-size: 60px;
    line-height: 0;
    margin-bottom: 10px;
}
.block-style-nine .text h3{
    font-size: 20px;
    margin-bottom: 5px;
}
.block-style-nine.bg-one{
    background-color: rgba(67, 186, 255, 0.07);
}
.block-style-nine.bg-two{
    background-color: rgba(96, 0, 255, 0.05);
}
.block-style-nine.bg-three{
    background-color: rgba(253, 74, 24, 0.05);
}
.block-style-nine.bg-four{
    background-color: rgba(43, 196, 138, 0.07)
}
.block-style-nine.bg-five{
    background-color: rgba(255, 90, 97, 0.06);
}
.block-style-nine.bg-one .icon i{
    color: var(--blue-light);
}
.block-style-nine.bg-two .icon i{
    color: #6000ff;
}
.block-style-nine.bg-three .icon i{
    color: var(--red-dark);
}
.block-style-nine.bg-four .icon i{
    color: #2bc48a;
}
.block-style-nine.bg-five .icon i{
    color: #ff5a61;
}
.block-style-nine .text h3.title:hover{
    color: var(--blue-light);
}
/* Block  Ten css */
.block-style-ten{
    position: relative;
    padding: 40px 35px;
    z-index: 1;
}
.block-style-ten:before{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
}
.block-style-ten:hover:after{
    transform: rotate(-5deg)
}
.block-style-ten:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--blue-dark);
    z-index: -2;
    transition: all .3s;
}
.block-style-ten .icon{
    margin-bottom: 15px;
}
.block-style-ten .icon i{
    font-size: 70px;
    line-height: 1;
    color: var(--red-dark);
}
.block-style-ten .text h4{
    margin-bottom: 10px;
}
.block-style-ten .text p{
    margin-bottom: 10px;
}
.block-style-ten .text .btn-link{
    color: var(--heading);
}
.block-style-ten .text .btn-link:hover{
    color: var(--blue-dark);
}
/* Block  Eleven css */
.block-style-eleven{
    position: relative;
    border: 1px solid rgba(25, 28, 40, 0.1);
    padding: 25px 35px 20px;
    margin-bottom: 20px;
    transition: all .3s;
}
.block-style-eleven:hover{
    border-color: transparent;
    box-shadow: 0px 10px 60px 0px rgba(87, 87, 87, 0.1);
}
.block-style-eleven:hover .text:after{
    background-color: var(--red-dark);
}
.block-style-eleven:hover:after{
    width: 100%;
}
.block-style-eleven:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 0%;
    height: 3px;
    background: linear-gradient( 90deg, rgb(255,58,52) 0%, rgb(255,111,51) 99%);
    z-index: -1;
    /* visibility: hidden;
    opacity: 0; */
    transition: all .3s;
}
.block-style-eleven .text{
    padding-left: 50px;
    position: relative;
}
.block-style-eleven .text:after{
    position: absolute;
    top: 6px;
    left: 0;
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--blue-dark);
    transition: all .3s;
}
.block-style-eleven:last-child{
    margin-bottom: 0;
}
.block-style-eleven .text h5{
    font-size: 21px;
    margin-bottom: 5px;
}
/* Block Twelve css */
.block-style-twelve .icon{
	margin-right: 20px;
	flex: 0 0 60px;
	max-width: 60px;
}
.block-style-twelve .icon i{
	font-size: 60px;
	line-height: 1;
	color: var(--red-dark);
}
.block-style-twelve .text h5{
    margin-bottom: 5px;
}
/* Block thirteen css */
.block-style-thirteen .icon{
    flex: 0 0 80px;
    max-width: 80px;
    height: 80px;
    margin-right: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    color: #fff;
    margin-bottom: 35px;
}
.block-style-thirteen .text h3.title{
    display: inline-block;
    position: relative;
    margin-bottom: 15px;
    padding-left: 35px;
}
.block-style-thirteen .text h3.title:hover{
    color: var(--blue-light);
}
.block-style-thirteen .text h3.title:before,
.block-style-thirteen .text h3.title:after{
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
}
.block-style-thirteen .text h3.title:before{
    top: 0;
    left: 0;
    background-color: var(--blue-light);
}
.block-style-thirteen .text h3.title:after{
    bottom: 5px;
    left: 10px;
    background-color: #00dcae;
}
.block-style-thirteen .text ul.list li{
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
}
.block-style-thirteen .text ul.list li:before{
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    margin-right: 7px;
}

/* Block Fourteen css */
.block-style-fourteen{
    position: relative;
    padding: 50px 60px 40px;
    border: 2px solid #ededed;
    z-index: 1;
}
.block-style-fourteen:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient( 90deg, rgb(0,41,157) 0%, rgb(0,120,238) 100%);
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
}
.block-style-fourteen:hover{
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
}
.block-style-fourteen:hover:after{
    visibility: visible;
    opacity: 1;
}
.block-style-fourteen:hover .text h3.title{
    color: #fff;
}
.block-style-fourteen:hover .text .btn-link{
    color: #7fa6df;
}
.block-style-fourteen:hover .text p{
    color: #bcd5fe;
}
.block-style-fourteen .icon{
    flex: 0 0 100px;
    max-width: 100px;
    margin-right: 30px;
}
.block-style-fourteen .text h3.title{
    margin-bottom: 10px;
}
.block-style-fourteen .text .btn-link:hover,
.block-style-fourteen .text h3.title:hover{
    color: var(--blue-dark);
}
.block-style-fourteen .text h3.title a{
    text-decoration: none;
}
.block-style-fourteen .text p{
    margin-bottom: 15px;
}

/* Block Fifteen css */
.block-style-fifteen .icon i{
    font-size: 30px;
    color: var(--yellow-dark);
}
.block-style-fifteen .text{
    margin-top: -7px;
    margin-left: 30px;
}
.block-style-fifteen .text h5{
    color: #fff;
    margin-bottom: 10px;
}
.block-style-fifteen .text p{
    margin-bottom: 0;
}

/* Block sixteen css */
.block-style-sixteen .icon{
    flex: 0 0 40px;
    max-width: 40px;
    margin-right: 25px;
}
.block-style-sixteen .text{
    margin-top: -7px;
}
.block-style-sixteen .text h3{
    color: #fff;
    margin-bottom: 8px;
}

/* Block seventeen css */
.block-style-seventeen{
    padding: 40px 25px;
}
.block-style-seventeen .icon i{
    font-size: 60px;
    line-height: 1;
    margin-bottom: 10px;
}
.block-style-seventeen .text h3.title{
    margin-bottom: 8px;
    letter-spacing: -0.03em;
}
.block-style-seventeen .text h3.title:hover{
    color: var(--heading);
}
.block-style-seventeen .icon i,
.block-style-seventeen .text h3.title,
.block-style-seventeen .text p{
    color: #fff;
}

/* Block eighteen css */
.block-style-eighteen{
    border: 1px solid rgba(31, 36, 44, 0.1);
    background-color: #fff;
    border-radius: 7px;
    padding: 30px 45px;
    transition: all .3s;
}
.block-style-eighteen:hover{
    border-color: transparent;
    box-shadow: 0px 10px 30px 0px rgba(58, 65, 111, 0.1);
}
.block-style-eighteen .icon{
    flex: 0 0 85px;
    max-width: 85px;
    height: 80px;
    margin-right: 30px;
}
.block-style-eighteen .text h3.title{
    margin-bottom: 10px;
}
.block-style-eighteen .text .btn-link:hover,
.block-style-eighteen .text h3.title:hover{
    color: var(--blue-light);
}
.block-style-eighteen .text P{
    margin-bottom: 5px;
}

/* Block nineteen css */
.block-style-nineteen{
    position: relative;
    background-color: #fff;
    padding: 65px 30px 25px;
}
.block-style-nineteen .icon{
    position: absolute;
    top: -40px;
    left: 35px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: #fff;
}
.block-style-nineteen .text h3.title{
    font-size: 22px;
    letter-spacing: -0.03em;
    margin-bottom: 12px;
}
.block-style-nineteen .text .brn-link:hover,
.block-style-nineteen .text h3.title:hover{
    color: var(--blue-light);
}

/* Block Twenty css */
.block-style-twenty{
    background-color: #fff;
    padding: 45px 40px 40px;
}
.block-style-twenty .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 85px;
    max-width: 85px;
    height: 85px;
    background-color: var(--blue-light);
    border-radius: 50%;
    margin-right: 30px;
}
.block-style-twenty .icon i{
    font-size: 45px;
    line-height: 1;
    color: #fff;
}
.block-style-twenty .text h3{
    /* text-decoration: underline; */
    margin-bottom: 13px;
}

/* Block TwentyOne css */
.block-style-twentyOne{
    position: relative;
    margin-bottom: 32px;
}
.block-style-twentyOne:after{
    position: absolute;
    bottom: -21px;
    left: 35px;
    content: '';
    width: 3px;
    height: 35px;
    background-color: var(--blue-light);
}
.block-style-twentyOne:last-child{
    margin-bottom: 0;
}
.block-style-twentyOne:last-child:after{
    display: none;
}
.block-style-twentyOne .icon{
    position: relative;
    max-width: 70px;
    flex: 0 0 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
}
.block-style-twentyOne .icon i{
    font-size: 40px;
    color: #fff;
}
.block-style-twentyOne .text h4{
    margin-bottom: 10px;
}

/* Block TwentyTwo css */
.block-style-twentyTwo{
    padding: 35px 40px 30px;
    border: 1px solid rgba(113, 65, 177, 0.1);
    transition: all .3s;
}
.block-style-twentyTwo:hover{
    background-color: #fff;
    border-color: transparent;
    box-shadow: 0px 10px 80px 0px rgba(38, 42, 55, 0.1);
}
.block-style-twentyTwo .icon{
    margin-right: 35px;
}
.block-style-twentyTwo .icon i{
    color: var(--blue-light);
    font-size: 60px;
    line-height: 1;
}
.block-style-twentyTwo .text h3{
    margin-bottom: 8px;
}
/* Block style TwentyThree */
.block-style-twentyThree .icon{
    margin-right: 25px;
}
.block-style-twentyThree .icon i{
    font-size: 35px;
    color: var(--blue-light);
}
.block-style-twentyThree .text h4{
    margin-bottom: 10px;
}



.text-wrapper-white p{
    color: #fff;
}
.border-top-left-radius img{
    border-radius: 0px 117.5px 117.5px 117.5px;
}
.border-bottom-left-radius img{
    border-radius: 117.5px 117.5px 117.5px 0px;
}
.border-bottom-right-radius img{
    border-radius: 97.5px 97.5px 0px 97.5px;
}
/* Fancy Block One */
.fancy-text-block-one{
    position: relative;
}
.fancy-text-block-one .img-holder-box{
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
}
.fancy-text-block-one .img-holder-box .img-holder-one{
    position: absolute;
    top: 0;
    right: 0;
}
.fancy-text-block-one .img-holder-box .img-holder-two{
    position: absolute;
    left: 0;
    bottom: 0;
}
.fancy-text-block-one .text-wrapper{
    padding: 120px 0 125px;
}

/* Fancy Block Two */
.fancy-text-block-two{
    position: relative;
}
.fancy-text-block-two .fancy-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
}
.fancy-text-block-two .text-wrapper{
    padding: 130px 70px 130px;
}
.fancy-text-block-two .text-wrapper .section-title h2{
    font-size: 38px;
    line-height: 47px;
}

/* Fancy Block Three */
.fancy-text-block-three .text-wrapper{
    margin-left: 70px;
}

/* Fancy Block Four */
.fancy-text-block-four .img-holder{
    margin-right: 40px;
    margin-left: -140px;
}

/* Fancy Text Block Five */
.fancy-text-block-five .img-holder{
    position: relative;
    z-index: 1;
}
.fancy-text-block-five .fancy-block-wrapper .img-holder:before{
    position: absolute;
    content: '';
    bottom: -100px;
    left: -100px;
    width: 295px;
    height: 295px;
    border-radius: 50%;
    background-color: rgba(0, 102, 255, 0.07);
    z-index: -1;
}
.fancy-text-block-five .fancy-block-wrapper:nth-child(even) .img-holder:before{
    left: auto;
    right: -100px;
}
.fancy-text-block-five .fancy-block-wrapper:nth-child(even) .img-holder{
    margin-left: 85px;
}
.fancy-text-block-five .fancy-block-wrapper:nth-child(even){
    flex-direction: row-reverse;
    margin-top: 60px;
}

/* Fancy Text Block Six */
.fancy-text-block-six .text-wrapper p{
    margin-bottom: 30px;
}
.fancy-text-block-six .fancy-box-item.bg-one .icon{
    background-color: var(--blue-light);
}
.fancy-text-block-six .fancy-box-item.bg-two .icon{
    background-color: #00dcae;
}
.fancy-text-block-six .fancy-box-item.bg-three .icon{
    background-color: var(--orange-dark);
}
.fancy-text-block-six .fancy-box-item .icon{
    width: 85px;
    height: 85px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 52px;
    color: #fff;
    margin-bottom: 10px;
}

/* Fancy Block seven */
.fancy-text-block-seven .fancy-img{
    margin-right: 30px;
    margin-left: -30px;
}
.fancy-text-block-seven .text-wrapper p{
    margin-bottom: 20px;
}
.fancy-text-block-seven .fancy-box-item{
    align-items: center;
}
.fancy-text-block-seven .fancy-box-item .icon{
    flex: 0 0 90px;
    max-width: 90px;
    margin-right: 30px;
}
.fancy-text-block-seven .fancy-box-item .text p{
    margin-bottom: 0;
}
.fancy-text-block-seven .content-tab-list ul.nav-tabs{
    border-bottom: none;
}
.fancy-text-block-seven .content-tab-list ul.nav-tabs li{
    position: relative;
    display: inline-flex;
    align-items: center;
}
.fancy-text-block-seven .content-tab-list ul.nav-tabs li:last-child:after{
    display: none;
}
.fancy-text-block-seven .content-tab-list ul.nav-tabs li:after{
    content: '|';
    margin-left: 15px;
    margin-right: 15px;
}
.fancy-text-block-seven .content-tab-list ul.nav-tabs li a{
    position: relative;
    font-size: 18px;
    color: #999999;
    font-weight: 700;
    font-family:var(--rajdhani);
    border: none;
    border-radius: 0;
}
.fancy-text-block-seven .content-tab-list ul.nav-tabs li a.active:after,
.fancy-text-block-seven .content-tab-list ul.nav-tabs li a:hover:after{
    visibility: visible;
    opacity: 1;
}
.fancy-text-block-seven .content-tab-list ul.nav-tabs li a:after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--blue-dark);
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
}
/* Fancy Block Eight */
.fancy-text-block-eight .fancy-img{
    margin-left: -120px;
    margin-right: 40px;
}
.fancy-text-block-eight .text-wrapper p{
    margin-bottom: 25px;
}
.fancy-text-block-eight .text-wrapper ul.list{
    padding-bottom: 30px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.fancy-text-block-eight .text-wrapper ul.list li{
    display: flex;
    align-items: center;
    width: 50%;
    font-weight: 700;
    font-family: var(--rajdhani);
    color: var(--heading);
    margin-bottom: 10px;
}
.fancy-text-block-eight .text-wrapper ul.list li i{
    font-size: 20px;
    background: linear-gradient( 90deg, rgb(0,41,157) 0%, rgb(0,120,238) 100%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    margin-right: 20px;
    flex: 0 0 25px;
    max-width: 25px;
}
.fancy-text-block-eight .text-wrapper .user-box .thumb{
    flex: 0 0 70px;
    max-width: 70px;
    margin-right: 20px;
}
.fancy-text-block-eight .text-wrapper .user-box .info p.position{
    margin-bottom: 0;
}

/* Fancy Block Nine */
.fancy-text-block-nine{
    position: relative;
    z-index: 1;
}
.fancy-text-block-nine .shape-icon{
    position: absolute;
}
.fancy-text-block-nine .shape-icon.shape-icon-one {
    top: 20%;
    left: 8%;
}
.fancy-text-block-nine .img-holder-box{
    margin-right: 60px;
    margin-left: -50px;
}
.fancy-text-block-nine .text-wrapper{
    padding-left: 30px;
}
.fancy-text-block-nine .text-wrapper p{
    margin-bottom: 35px;
}

/* Fancy Block Ten */
.fancy-text-block-ten .text-wrapper p{
    margin-bottom: 25px;
}
.fancy-text-block-ten .img-holder{
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin-left: 50px;
}
.fancy-text-block-ten .img-holder img{
    width: 100%;
}
.fancy-text-block-ten .play-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .50);
    display: flex;
    align-items: center;
    justify-content: center;
}
.fancy-text-block-ten .play-content .video-popup{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background-color: #fff;
    color: var(--purple-blue);
}

/* Fancy Block Eleven */
.fancy-text-block-eleven .text-wrapper{
    padding-left: 70px;
}
.fancy-text-block-eleven .text-wrapper p{
    margin-bottom: 25px;
}
.fancy-text-block-eleven .text-wrapper ul.check-list{
    display: flex;
    flex-wrap: wrap;
}
.fancy-text-block-eleven .text-wrapper ul.check-list li{
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 15px;
}
ul.check-list li:before{
    content: '\f00c';
    font-weight: 600;
    font-family: 'Font Awesome 5 Pro';
}
ul.check-list.list-circle-bg li:before{
    display: inline-flex;
    margin-right: 13px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: var(--purple-blue);
    color: #fff;
}

/* Fancy Block Twelve */
.fancy-text-block-twelve .fancy-block-wrapper{
    background-color: #fff;
}
.fancy-text-block-twelve .text-wrapper{
    padding: 45px 60px;
}
.fancy-text-block-twelve .text-wrapper p{
    margin-bottom: 30px;
}

/* Skill CSS */
.skill-style-one{
    position: relative;
}
.skill-style-one .objects .img-1{
    position: absolute;
    top: 190px;
    right: 140px;
}
.skill-style-one .objects .img-2{
    position: absolute;
    bottom: 140px;
    left: 140px;
}
.skill-style-one .skill-img-box{
    position: relative;
    margin-left: -40px;
    margin-right: 40px;
}
.skill-style-one .skill-img-box .shape .img-1 {
    position: absolute;
    bottom: 30px;
    left: 130px;
}
.skill-style-one  .skill-content-box p{
    margin-bottom: 33px;
}
.skill-style-one  .skill-content-box .skill-item .skill-bar{
    position: relative;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.skill-style-one  .skill-content-box .skill-item h2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 30px;
    line-height: 1;
}
.skill-style-one  .skill-content-box .skill-item h2 span.sign{
    font-size: 20px;
}
.skill-style-one  .skill-content-box .skill-item h3{
    text-decoration: underline;
    margin-bottom: 3px;
}
.skill-style-one  .skill-content-box .skill-item canvas{
    position: absolute;
    top: 0;
    left: 0;
}
.skill-style-one  .skill-content-box .skill-item .chart-1 h2 span.sign{
    font-size: 24px;
}
.skill-style-two .skill-item .skill-bar{
    position: relative;
    width: 100px;
    height: 100px;
}
.skill-style-two .skill-item .skill-bar h2.number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 20px;
}
.skill-style-two .skill-item .skill-bar h2.number span.count{
    font-size: 30px;
}
.skill-style-two .skill-item h3.title{
    text-decoration: underline;
    margin-bottom: 5px;
}
/* Quote CSS */
.quote-style-one .quote-content-box {
    position: relative;
}
.quote-style-one .quote-content-box .thumb{
    position: relative;
    min-width: 135px;
    max-width: 135px;
    height: 135px;
    margin-right: 30px;
}
.quote-style-one .quote-content-box .thumb img{
    width: 100%;
    border-radius: 50%;
}
.quote-style-one .quote-content-box .thumb i{
    position: absolute;
    top: -15px;
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #fff;
    color: #fff;
    background: linear-gradient( to left, rgb(41,129,233) 0%, rgb(62,67,220) 99%);
}

/* CTA CSS */
.cta-style-one{
    position: relative;
    z-index: 1;
}
.cta-style-one:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(25, 25, 25, 0.85);
    z-index: -1;
}
.cta-style-one .cta-content h3{
    font-size: 35px;
    line-height: 50px;
    color: #fff;
}
/* CTA Two CSS */
.cta-style-two .cta-wrapper{
    position: relative;
    padding: 80px 90px;
    background-color: rgba(0, 102, 255, 0.05);
}
.cta-style-two .cta-wrapper .shape-icon{
    position: absolute;
}
.cta-style-two .cta-wrapper .shape-icon.shape-icon-one{
    top: -70px;
    left: -70px;
}
.cta-style-two .cta-wrapper .shape-icon.shape-icon-two{
    bottom: 70px;
    right: -90px;
}
.cta-style-two .cta-wrapper .cta-img{
    margin-left: 70px;
    position: absolute;
    right: 0;
    bottom: -80px;
}
.cta-style-two .cta-content-box form .form_control{
    border: 1px solid rgba(27, 27, 32, 0.1);
    height: 70px;
    padding: 0 25px;
}
.cta-style-two .cta-content-box form .main-btn{
    position: absolute;
    top: 9px;
    right: 10px;
    padding: 10px 30px;
}
.cta-style-two .cta-content-box p{
    margin-bottom: 25px;
}
/* CTA Three CSS */
.cta-style-three{
    position: relative;
    z-index: 1;
}
.cta-style-three:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #484848;
    mix-blend-mode: multiply;
    z-index: -1;
}
.cta-style-three .cta-content-box h2{
    font-size: 65px;
    line-height: 75px;
    color: #fff;
    /* text-decoration: underline; */
    margin-bottom: 20px;
}
.cta-style-three .cta-content-box ul.button li{
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

/* CTA Four CSS */
.cta-style-four{
    position: relative;
    z-index: 1;
}
.cta-style-four:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(67, 186, 255, 0.85);
    mix-blend-mode: multiply;
    z-index: -1;
}
.cta-style-four .cta-content h3{
    font-size: 35px;
    line-height: 50px;
    color: #fff;
    text-decoration: underline;
}
/* CTA Five CSS */
.cta-style-five .cta-content-box ul.button li{
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 5px;
}
.cta-style-five .cta-content-box ul.button li:last-child{
    margin-right: 0;
}
.cta-style-five .cta-content-box ul.button li a i{
    padding-left: 10px;
}
.cta-style-five .cta-content-box .quote-box{
    display: flex;
}
.cta-style-five .cta-content-box .quote-box .thumb{
    flex: 0 0 80px;
    max-width: 80px;
    margin-right: 20px;
}
.cta-style-five .cta-content-box .quote-box .thumb img{
    width: 100%;
    border-radius: 50%;
}
/* CTA Six CSS */
.cta-style-six .cta-wrapper{
    position: relative;
    background-color: #2f5aa8;
    padding: 35px 70px;
}
.cta-style-six .cta-wrapper img.shape-icon{
    position: absolute;
}
.cta-style-six .cta-wrapper .shape-icon-one{
    top: -100px;
    left: -100px;
}
.cta-style-six .cta-wrapper .shape-icon-two{
    bottom: 50px;
    left: 40%;
}
.cta-style-six .cta-content-box p{
    color: #fff;
    margin-bottom: 40px;
}
.cta-style-six .cta-content-box .main-btn.filled-btn{
    border-color: #fff;
    color: #fff;
}
.cta-style-six .cta-content-box .main-btn.filled-btn:hover{
    background-color: var(--blue-light);
    border-color: transparent;
}
/* Clients CSS */
.clients-map {
    position: relative;
    z-index: 1;
}
.clients-map:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    /* background: url(../image/clients-map.png) no-repeat center; */
    background-size: cover;
    z-index: -1;
}
.clients-style-one .clients-wrapper{
    background-color: #fff;
    padding: 50px 20px;
}
.clients-slider-two .slick-track,
.clients-slider-one .slick-track{
    display: flex;
    align-items: center;
}
.clients-style-one .client-item .client-img img{
    margin: 0 auto;
    opacity: .35;
    transition: all .3s;
}
.clients-style-one .client-item:hover .client-img img{
    opacity: 1;
}
.clients-style-one .client-item{
    padding: 0 35px;
}

/* Clients Style Two */
.clients-style-two{
    position: relative;
    z-index: 2;
}
.clients-style-two .clients-wrapper{
    padding: 50px 20px;
}
.clients-style-two .client-item{
    padding: 0 60px;
    cursor: pointer;
}
.clients-style-two .client-item:hover .client-img img{
    opacity: 1;
}
.clients-style-two .client-item .client-img img{
    opacity: .5;
    transition: all .3s;
}

/* Clients Style Three */
.clients-style-three{
    background-color: #f2f5fa;
}
.clients-style-three .clients-slider-two.slick-list{
    display: flex;
    align-items: center;
}
.clients-style-three .clients-slider-two{
    margin-left: -50px;
    margin-right: -50px;
}
.clients-style-three .clients-slider-two .client-item{
    margin-left: 50px;
    margin-right: 50px;
    cursor: pointer;
}
.clients-style-three .client-item:hover .client-img img{
    opacity: 1;
    filter: grayscale(0);
}
.clients-style-three .client-item .client-img img{
    margin: 0 auto;
    filter: grayscale(1);
    opacity: .5;
    transition: all .3s;
}

/* Clients Style Four */
.counter-style-four .container-fluid,
.features-style-three .container-fluid,
.clients-style-four .container-fluid{
    padding-left: 150px;
    padding-right: 150px;
}
.clients-style-four .section-title h4{
    color: #fff;
}
.clients-style-four .clients-slider-two{
    margin-left: -40px;
    margin-right: -40px;
}
.clients-style-four .clients-wrapper{
    background-color: #0d0e12;
    border-radius: 12px;
}
.clients-style-four .client-item{
    cursor: pointer;
}
.clients-style-four .client-item .client-img{
    padding: 0 40px;
}
.clients-style-four .client-item .client-img img {
    filter: grayscale(100%);
    transition: all .3s;
}
.clients-style-four .client-item.slick-current .client-img img,
.clients-style-four .client-item:hover .client-img img{
    filter: grayscale(0);
}


/* Clients Style Five */

.clients-style-five .clients-wrapper{
    border: 2px solid #ededed;
    padding: 70px 30px;
}
.clients-style-four .clients-slider-two .slick-track,
.clients-style-five .clients-slider-three .slick-track{
    display: flex;
    align-items: center;
}
.clients-style-five .client-item{
    padding: 0 45px;
    cursor: pointer;
}
.clients-style-five .client-item:hover .client-img img{
    filter: grayscale(0);
    opacity: 1;
}
.clients-style-five .client-item .client-img img{
    filter: grayscale(100%);
    opacity: .5;
    transition: all .3s;
}


/* Newsletter CSS */

.newsletter-style-one .newsletter-wrapper {
    position: relative;
    padding: 40px 80px 25px;
    margin-bottom: -85px;
    z-index: 2;
}
.newsletter-style-one .newsletter-wrapper .newsletter-content-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}
.newsletter-style-one .newsletter-wrapper .newsletter-content-box .icon {
    font-size: 90px;
    line-height: 1;
    margin-right: 40px;
}
.newsletter-style-one .newsletter-wrapper .newsletter-content-box .icon i,
.newsletter-style-one .newsletter-wrapper .newsletter-content-box .content h2{
    color: #fff;
}
.newsletter-style-one .newsletter-wrapper .newsletter-form .form_control {
    padding: 0 30px 0 50px;
    max-width: 510px;
    height: 75px;
}
.newsletter-style-one .newsletter-wrapper .newsletter-form i {
    position: absolute;
    top: 30px;
    left: 20px;
    color: var(--red-dark);
}
.newsletter-style-one .newsletter-wrapper .newsletter-form .main-btn {
    position: absolute;
    top: 11px;
    right: 0;
}

/* Newsletter Two CSS */
.newsletter-style-two{
    position: relative;
}
.newsletter-style-two #curve-one{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.newsletter-style-two #curve-one path{
    fill: #f6f6f7;
}
.newsletter-style-two .newsletter-wrapper{
    position: relative;
    border: 1px solid rgba(27, 27, 32, 0.07);
    padding: 80px 100px;
}
.newsletter-style-two .newsletter-wrapper #curve-one{
    position: absolute;
    top: 0;
    left: 0;
}
.newsletter-style-two .newsletter-wrapper #curve-two{
    position: absolute;
    top: 0;
    right: 0;
}
.newsletter-style-two .newsletter-wrapper #curve-two path,
.newsletter-style-two .newsletter-wrapper #curve-one path{
    fill: rgba(0, 102, 255, .039);
}
.newsletter-style-two .newsletter-wrapper .newsletter-content-box form .form_control{
    border: 1px solid rgba(27, 27, 32, 0.1);
    height: 75px;
    border-radius: 5px;
    padding: 0 25px;
    color: var(--heading);
}
.newsletter-style-two .newsletter-wrapper .newsletter-content-box form .form_control::placeholder{
    color: var(--heading);
}
.newsletter-style-two .newsletter-wrapper .newsletter-content-box form .main-btn{
    position: absolute;
    top: 7px;
    right: 10px;
}
.newsletter-style-two .newsletter-wrapper .newsletter-content-box  p{
    margin-bottom: 25px;
}

/* Newsletter Three CSS */
.newsletter-style-three .newsletter-wrapper{
    padding: 70px 60px;
    background-color: #fff;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
}
.newsletter-style-three .newsletter-wrapper .newsletter-form .form_control{
    padding: 0 40px;
    background-color: #f8f8f8;
    height: 70px;
}
.newsletter-style-three .newsletter-wrapper .newsletter-form .main-btn{
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border-radius: 0;
    text-decoration: none;
    align-items: center;
    background: linear-gradient( 90deg, rgb(0,41,157) 0%, rgb(0,120,238) 100%);
    padding: 20px 30px;
}
.newsletter-style-three .newsletter-wrapper .newsletter-form .main-btn i{
    margin-right: 10px;
}

/* Newsletter Style Four */
.newsletter-style-four .newsletter-wrapper{
    background-color: #fff;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
    padding: 65px 60px 60px;
}
.newsletter-style-four .newsletter-form form .form_control{
    height: 70px;
    background-color: #f9f9f9;
    padding: 0 40px;
} 
.newsletter-style-four .newsletter-form form .main-btn{
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 35px;
    border-radius: 0;
    background: linear-gradient( 90deg, rgb(0,41,157) 0%, rgb(0,120,238) 100%);
}
.newsletter-style-four .newsletter-form form .main-btn i{
    margin-right: 10px;
}
  
/* 404 Page */
.error-content h2{
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 20px;
}
.error-content p{
    margin-bottom: 35px;
}
.error-content .main-btn{
    padding: 15px 50px;
}

/* Page Pagination */
.page-pagination ul li{
    display: inline-block;
    margin-right: 5px;
}
.page-pagination ul li a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
    font-family: var(--rajdhani);
    color: var(--heading);
    background-color: rgba(25, 25, 25, 0.07);
    transition: all .3s;
}
.page-pagination ul li a.active,
.page-pagination ul li a:hover{
    background-color: var(--blue-light);
    color: #fff;
}
/*---=========================
    End Element css 
=========================---*/

/*---=========================
    08. Start Counter css 
=========================---*/

/* Counter Style One */
.counter-style-one{
    position: relative;
    z-index: 1;
}
.counter-style-one:after{
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--red-dark);
    mix-blend-mode: multiply;
    z-index: -1;
}
.counter-style-one .counter-item .icon{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    margin-bottom: 21px;
}
.counter-style-one .counter-item .icon i{
    font-size: 50px;
    line-height: 1;
    color: var(--red-dark);
}
.counter-style-one .counter-item h2.number{
    color: #fff;
}
.counter-style-one .counter-item p{
    font-weight: 500;
    color: #fff;
}


/* Counter Style Two */
.counter-style-two .counter-wrapper{
    position: relative;
    border: 1px solid rgba(27, 27, 32, 0.08);
    background-color: #fff;
    padding-top: 0;
}
.counter-style-two .counter-wrapper .shape-icon{
    position: absolute;
    z-index: -1;
}
.counter-style-two .counter-wrapper .shape-icon.shape-icon-one{
    top: -70px;
    left: -70px;
}
.counter-style-two .counter-wrapper .shape-icon.shape-icon-two{
    bottom: -50px;
    right: -50px;
}
.counter-style-two .counter-item{
    padding: 60px 30px;
}
.counter-style-two .counter-item .icon{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(0, 102, 255, 0.07);
    margin-bottom: 20px;
}
.counter-style-two .counter-item .icon i{
    font-size: 50px;
    line-height: 0;
    color: var(--blue-dark);
}
.counter-style-two .counter-item p{
    font-weight: 500;
    line-height: 20px;
}
.counter-style-two .counter-column{
    position: relative;
}
.counter-style-two .counter-column:last-child:after{
    display: none;
    border-right: none;
}
.counter-style-two .counter-column:after{
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 1px;
    height: 100%;
    background-color: rgba(27, 27, 32, 0.08);
}

/* Counter Style Three */
.counter-style-three .counter-item{
    display: flex;
    margin-bottom: 50px;
}
.counter-style-three .counter-item:last-child{
    margin-bottom: 0;
}
.counter-style-three .counter-item .icon{
    flex: 0 0 70px;
    margin-right: 30px;
    display: flex;
    align-items: center;
}
.counter-style-three .counter-item .icon:after{
    margin-left: 20px;
    content: '';
    width: 3px;
    height: 65px;
    background-color: rgba(20, 29, 56, 0.1);
}
.counter-style-three .counter-item .icon i{
    font-size: 70px;
    line-height: 1;
    color: var(--blue-light);
}
.counter-style-three .counter-item .text p{
    font-weight: 500;
}
/* Counter Style Four */
.counter-style-four .counter-wrapper{
    position: relative;
    background-color: #0a0b11;
    z-index: 1;
}
.counter-style-four  .counter-wrapper .shape-icon{
    position: absolute;
    z-index: -1;
}
.counter-style-four  .counter-wrapper .shape-icon.shape-icon-one{
    top: 15%;
    left: 5%;
}
.counter-style-four  .counter-wrapper .shape-icon.shape-icon-two{
    top: 10%;
    right: 7%;
}
.counter-style-four  .counter-wrapper .shape-icon.shape-icon-three{
    bottom: 10%;
    right: 5%;
}
.counter-style-four .counter-content-box p{
    margin-bottom: 35px;
}
.counter-style-four .counter-item-wrap{
    padding-left: 100px;
}
.counter-style-four .counter-item-wrap .counter-item{
    padding: 40px 40px 30px;
    background-color: #16171b;
}
.counter-style-four .counter-item-wrap .counter-item .icon{
    margin-bottom: 25px;
}
.counter-style-four .counter-item-wrap .counter-item .text h2{
    color: #fff;
    margin-bottom: 5px;
}
.counter-style-four .counter-item-wrap .counter-item .text p{
    color: #fff;
}
.counter-style-four .counter-item-wrap .counter-thumb img{
    border-radius: 131.25px 0px 131.25px 131.25px;
}
/* Counter Style Six */
.counter-style-five .counter-item .icon{
    margin-right: 20px;
}
.counter-style-five .counter-item .icon i{
    font-size: 60px;
    background: linear-gradient( 90deg, rgb(0,41,157) 0%, rgb(0,120,238) 100%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}
.counter-style-five .counter-item .text{
    margin-top: -6px;
}
.counter-style-five .counter-item .text h2{
    font-size: 48px;
    line-height: 48px;
}
.counter-style-five .counter-item .text p{
    font-size: 18px;
    line-height: 27px;
}

/* Counter Style Six */
.counter-style-six .counter-wrapper{
    position: relative;
    padding: 80px 90px 40px;
    background-color: #2f5aa8;
}
.counter-style-six .counter-item .icon i{
    font-size: 35px;
    color: #fff;
}
.counter-style-six .counter-item .text{
    margin-top: -10px;
}
.counter-style-six .counter-item .text h2{
    display: flex;
    align-items: center;
    color: #fff;
}
.counter-style-six .counter-item .text h2 span.title{
    font-size: 16px;
    margin-left: 20px;
    font-family: var(--roboto);
    letter-spacing: 0;
    font-weight: 400;
}
.counter-style-six .counter-item .text p{
    color: #fff;
}
.counter-style-six .img-holder{
    position: relative;
}
.counter-style-six .img-holder .play-content{
    position: absolute;
    top: 50%;
    left: -45px;
    transform: translateY(-50%);
}
.counter-style-six .img-holder .play-content .video-popup{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    color: #00dcae;
    background-color: #fff;
}
.counter-style-six .text-wrapper{
    padding-right: 95px;
}

/*---=========================
    End Counter css 
=========================---*/

/*---=========================
    09. Strat Portfolio css 
=========================---*/

/* Portfolio Filter Button */
.portfolio-filter-button .filter-btn li{
    position: relative;
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    color: #6d6d6d;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    transition: all .3s;
}
.portfolio-filter-button .filter-btn li:after{
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 0;
    height: 1px;
    background-color: #1b1d21;
    transition: all .3s;
}
.portfolio-filter-button .filter-btn li:hover:after,
.portfolio-filter-button .filter-btn li.active:after{
    width: 100%;
}
.portfolio-filter-button .filter-btn li:hover,
.portfolio-filter-button .filter-btn li.active{
    color: #1b1d21;
}
/* Projects Style One */
.portfolio-slider-two,
.portfolio-slider-one{
    margin-left: -15px;
    margin-right: -15px;
}
.portfolio-slider-two .portfolio-block-two,
.portfolio-slider-one .portfolio-block-one{
    margin-left: 15px;
    margin-right: 15px;
}
.portfolio-slider-one ul.slick-dots{
    text-align: center;
    margin-top: 60px;
}
.portfolio-slider-one ul.slick-dots li{
    position: relative;
    width: 5px;
    height: 5px;
    border: 1px solid var(--red-dark);
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
}
.portfolio-slider-one ul.slick-dots li.slick-active:after{
    border-color: var(--red-dark);
}
.portfolio-slider-one ul.slick-dots li:after{
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid transparent;
    margin-top: -10px;
    margin-left: -10px;
    transition: all .3s;
}
.portfolio-block-one:hover .portfolio-img img{
    transform: scale(1.1);
}
.portfolio-block-one .portfolio-img{
    overflow: hidden;
}
.portfolio-block-one .portfolio-img img{
    transform: scale(1);
    transition: all .3s;
}
.portfolio-block-one .portfolio-content{
    position: relative;
    padding: 0 30px;
    margin-top: -20px;
}
.portfolio-block-one .portfolio-content .cat-btn{
    padding: 5px 15px;
    background-color: var(--red-dark);
    color: #fff;
    text-decoration: underline;
    border-radius: 5px;
    font-family: var(--rajdhani);
    text-transform: uppercase;
    font-weight: 700;
}
.portfolio-block-one .portfolio-content h3.title{
    font-size: 22px;
    line-height: 32px;
    margin: 17px 0 10px;
}
.portfolio-block-one:hover .portfolio-content h3.title{
    color: var(--red-dark);
}
.portfolio-block-one .portfolio-content p{
    margin-bottom: 7px;
}

/* Projects Style two */
.portfolio-block-two:hover .portfolio-overlay .hover-content{
    background: linear-gradient( -90deg, rgb(41,129,233) 0%, rgb(62,67,220) 99%);
}
.portfolio-block-two:hover .portfolio-overlay .hover-content .main-btn.main-btn-sm {
    background-color: #fff;
    color: var(--heading);
}
.portfolio-block-two:hover .portfolio-overlay .hover-content h3.title{
    color: #fff;
}
.portfolio-block-two:hover .portfolio-overlay .hover-content p.category{
    color: #fff;
}
.portfolio-block-two .portfolio-img{
    position: relative;
    overflow: hidden;
}
.portfolio-block-two .portfolio-img img{
    width: 100%;
}
.portfolio-block-two .portfolio-overlay{
    position: absolute; 
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    width: 80%;
}
.portfolio-block-two .portfolio-overlay .hover-content{
    position: relative;
    background: #fff;
    padding: 20px 30px;
    width: 100%;
    transition: all .3s;
}
.portfolio-block-two .portfolio-overlay .hover-content .main-btn.main-btn-sm {
    padding: 5px 15px;
    line-height: 20px;
    position: absolute;
    top: -15px;
    right: 10px;
    background-color: var(--blue-dark);
}
.portfolio-block-two .portfolio-overlay .hover-content h3.title{
    font-size: 22px;
}
.portfolio-block-two .portfolio-overlay .hover-content p.category{
    font-weight: 700;
    color: var(--blue-dark);
    line-height: 24px;
}

/* Projects Style three */
.portfolio-style-one .portfolio-slider-three .slick-dots {
    margin-top: 60px;
}
.portfolio-style-one .portfolio-slider-three .slick-dots li{
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: var(--blue-light);
    border: 5px  solid #fff;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
    transition: all .3s;
}
.portfolio-style-one .portfolio-slider-three .slick-dots li.slick-active{
    border-color: var(--blue-light);
    background-color:#fff;
}
.portfolio-style-one .container-fluid{
    padding-left: 170px;
    padding-right: 170px;
}
.portfolio-style-one .portfolio-slider-three{
    margin-left: -15px;
    margin-right: -15px;
}
.portfolio-slider-three .portfolio-block-three{
    margin-left: 15px;
    margin-right: 15px;
}
.portfolio-block-three{
    transform: translateY(0px);
    transition: all .3s;
}
.portfolio-block-three:hover{
    transform: translateY(-10px);
}
.portfolio-block-three .portfolio-content{
    background-color: #f8f8f8;
    padding: 0 35px 35px;
    margin-top: -15px;
}
.portfolio-block-three .portfolio-content h3.title{
    font-size: 20px;
    line-height: 30px;
}
.portfolio-block-three .portfolio-content .cat-btn{
    font-family: var(--rajdhani);
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px 15px;
    border-radius: 5px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 17px;
}
.portfolio-block-three .portfolio-content h3.title:hover{
    color: var(--blue-light);
}

/* Portfolo Block Four */
.portfolio-block-four{
    padding: 40px 50px 35px;
    margin-bottom: 30px;
}
.portfolio-block-four.bg-one{
    background-color: rgba(254, 74, 25, 0.1);
}
.portfolio-block-four.bg-two{
    background-color: rgba(1, 102, 255, 0.1);
}
.portfolio-block-four.bg-three{
    background-color: rgba(255, 148, 51, 0.1);
}
.portfolio-block-four.bg-four{
    background-color: rgba(0, 220, 174, 0.1);
}
.portfolio-block-four .portfolio-img{
    position: relative;
    overflow: hidden;
}
.portfolio-block-four .portfolio-img img{
    width: 100%;
}
.portfolio-block-four .portfolio-img .portfolio-hover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 187, 238, .74);
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
}
.portfolio-block-four:hover .portfolio-img .portfolio-hover{
    visibility: visible;
    opacity: 1;
}
.portfolio-block-four:hover .portfolio-img .portfolio-hover .hover-content{
    transform: translateY(0px);
}
.portfolio-block-four .portfolio-img .portfolio-hover .hover-content{
    transform: translateY(50px);
    transition: all .45s;
}
.portfolio-block-four .portfolio-img .portfolio-hover .hover-content .img-popup{
    font-size: 30px;
    color: #fff;
}
.portfolio-block-four .portfolio-content{
    padding-top: 30px;
}
.portfolio-block-four .portfolio-content h3.title{
    margin-bottom: 5px;
}

/* Projects Block Five */

.portfolio-block-seven:hover .portfolio-img img,
.portfolio-block-five:hover .portfolio-img img{
    transform: scale(1.1);
}
.portfolio-block-seven .portfolio-img,
.portfolio-block-five .portfolio-img{
    position: relative;
    overflow: hidden;
}
.portfolio-block-seven .portfolio-img img,
.portfolio-block-five .portfolio-img img{
    width: 100%;
    transform: scale(1);
    transition: all .3s;
}
.portfolio-block-five .portfolio-content{
    padding-top: 25px;
}
.portfolio-block-five .portfolio-content h3.title{
    font-size: 24px;
    line-height: 35px;
}
.portfolio-block-five .portfolio-content .cat-btn:hover,
.portfolio-block-five .portfolio-content h3.title:hover{
    color: var(--blue-light);
}
.portfolio-block-five .portfolio-content .cat-btn{
    font-family: var(--rajdhani);
    font-weight: 600;
    font-size: 18px;
}

/* Projects Style Five */
.portfolio-block-six:hover .portfolio-img .portfolio-hover{
    transform: perspective(700px) rotateX(0deg);
    visibility: visible;
    opacity: 1;
}
.portfolio-block-six .portfolio-img{
    position: relative;
    overflow: hidden;
}
.portfolio-block-six .portfolio-img .portfolio-hover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(67, 186, 255, 0.93);
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: perspective(700px) rotateX(40deg);
    visibility: hidden;
    opacity: 0;
    transition: all .5s;
}
.portfolio-block-six .portfolio-img .portfolio-hover .hover-content .cat-btn,
.portfolio-block-six .portfolio-img .portfolio-hover .hover-content p,
.portfolio-block-six .portfolio-img .portfolio-hover .hover-content h3.title{
    color: #fff;
}
.portfolio-block-six .portfolio-img .portfolio-hover .hover-content .cat-btn{
    margin-bottom: 5px;
}
.portfolio-block-six .portfolio-img .portfolio-hover .hover-content .icon-btn{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: var(--heading);
}
.portfolio-block-six .portfolio-img .portfolio-hover .hover-content p{
    padding-bottom: 25px;
}
/* Portfolio Block seven */
.portfolio-block-seven .portfolio-content{
    background-color: #fff;
    padding: 20px 40px;
}
.portfolio-block-seven .portfolio-content h3.title{
    margin-bottom: 5px;
}
.portfolio-block-seven .portfolio-content h3.title:hover{
    color: var(--blue-light);
}
.portfolio-block-seven .portfolio-content .cat-btn {
    font-family: var(--rajdhani);
    font-weight: 600;
    font-size: 18px;
}
.recent-portfolio-slider{
    margin-left: -15px;
    margin-right: -15px;
}
.recent-portfolio-slider .portfolio-block-seven{
    margin-left: 15px;
    margin-right: 15px;
}

/* Project Details CSS */
.portfolio-details-wrapper .project-content .project-info{
    background-color: #f7f8f9;
    padding: 40px 40px 40px;
    border-top: 5px solid var(--blue-light);
}
.portfolio-details-wrapper .project-content .project-info h3{
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 33px;
}
.portfolio-details-wrapper .project-content .project-info h3:after{
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 35px;
    height: 3px;
    background-color: var(--purple-blue);
}
.portfolio-details-wrapper .portfolio-content .project-info .list li{
    display: flex;
    margin-bottom: 15px;
}
.portfolio-details-wrapper .portfolio-content .project-info .list li:last-child{
    margin-bottom: 0;
}
.portfolio-details-wrapper .portfolio-content .project-info .list li span{
    width: 50%;
    font-size: 18px;
    font-family: var(--rajdhani);
    color: var(--heading);
}
.portfolio-details-wrapper .portfolio-content .project-info .list li span.title{
    font-weight: 600;
}
.portfolio-details-wrapper .portfolio-content .project-info .list li span.text{
    font-weight: 500;
}
.portfolio-details-wrapper .portfolio-content .content{
    margin-bottom: 40px;
}
.portfolio-details-wrapper .portfolio-content .content h4.title,
.portfolio-details-wrapper .portfolio-content .content h3.title{
    position: relative;
    border-left: 5px solid var(--blue-light);
    padding-left: 30px;
}
.portfolio-details-wrapper .portfolio-content .content h3.title{
    margin-bottom: 30px;
}
.portfolio-details-wrapper .portfolio-content .content .text-box{
    display: flex;
}
.portfolio-details-wrapper .portfolio-content .content .text-box .big-text{
    width: 80px;
    height: 80px;
    flex: 0 0 80px;
    background-color: var(--purple-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: var(--rajdhani);
    font-size: 60px;
    margin-right: 30px;
}
.portfolio-details-wrapper .portfolio-content .content .text-box .box-text{
    margin-top: -7px;
}
.portfolio-details-wrapper .portfolio-content .content .text-box .box-text p{
    margin-bottom: 0px;
}
.portfolio-details-wrapper .portfolio-content .content h4.title{
    margin-bottom: 20px;
}
.portfolio-details-wrapper .portfolio-content .content ul.list li{
    display: inline-flex;
    margin-bottom: 5px;
}
.portfolio-details-wrapper .portfolio-content .content ul.list li:last-child{
    margin-bottom: 0px;
}
.portfolio-details-wrapper .portfolio-content .content ul.list li:before{
    display: inline-flex;
    content: '\f058';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    margin-right: 15px;
    color: var(--blue-light);
}
.portfolio-details-wrapper .portfolio-content .content p{
    margin-bottom: 15px;
}
.portfolio-details-wrapper .portfolio-content .project-info{
    background-color: #f7f8f9;
    border-top: 5px solid var(--blue-light);
    padding: 35px 35px;
}
.portfolio-details-wrapper .portfolio-content .project-info h3{
    position: relative;
}
.portfolio-details-wrapper .portfolio-content .project-info h3:after{
    display: block;
    content: '';
    margin: 15px 0 30px;
    width: 35px;
    height: 3px;
    background-color: var(--purple-blue);
}
.portfolio-details-wrapper .portfolio-content .project-info  ul.list li span.text{
    display: inline-flex;
}
.portfolio-details-wrapper .portfolio-content .project-info  ul.list li span.text:before{
    display: inline-flex;
    content: ":";
    margin-right: 10px;
}
.roboto-italic{
    font-family: var(--roboto);
    font-style: italic;
}
.single-step-box{
    margin-top: 30px;
    padding-right: 30px;
}
.single-step-box .icon{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: var(--blue-light);
    color: #fff;
    margin-bottom: 20px;
}
.single-step-box .icon span.step-count{
    position: absolute;
    top: -15px;
    right: 0;
    background-color: var(--purple-blue);
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 13px;
    border-radius: 50%;
}
.single-step-box .icon i{
    font-size: 40px;
    line-height: 1;
}
.single-step-box .text h3{
    text-decoration: underline;
    margin-bottom: 5px;
}
/*---=========================
    End Portfolio css 
=========================---*/

/*---=========================
    10. Start Pricing css 
=========================---*/
.pricing-nav .nav-tabs {
    border-bottom: none;
    display: inline-flex;
    background-color: #fff;
    padding: 20px 30px;
}
.pricing-nav .nav-tabs li{
    display: inline-flex;
    align-items: center;
}
.pricing-nav .nav-tabs li:after{
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 5px  solid var(--red-dark);
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
}
.pricing-nav .nav-tabs li:last-child::after{
    display: none;
}
.pricing-nav .nav-link {
    margin-bottom: 0px;
    border: none;
    border-radius: 0px;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Rajdhani';
    text-transform: uppercase;
    text-decoration: underline;
}
.pricing-style-one .pricing-nav .nav-tabs {
    background-color: #fff;
}
.pricing-style-one .pricing-nav .nav-link.active,
.pricing-style-one .pricing-nav .nav-link:hover{
    color: var(--red-dark);
}
.pricing-item .pricing-body .list li{
    margin-bottom: 10px;
}
.pricing-item .pricing-body .list li:last-child{
    margin-bottom: 0;
}
.pricing-item .pricing-body .list li:before{
    display: inline-block;
    margin-right: 10px;
    font-size: 14px;
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 660;
}
.pricing-item .pricing-body .list li.uncheck{
    color: rgba(97, 97, 97, 0.5);
}
.pricing-item{
    transition: all .3s;
}
.pricing-item:hover{
    margin-top: -10px;
}
.pricing-style-one .pricing-item{
    position: relative;
    background-color: #ffffff;
    padding: 45px 40px 0px;
}
.pricing-style-one .pricing-item .pricing-title-price {
    position: relative;
    margin-bottom: 27px;
    display: flex;
}
.pricing-style-one .pricing-item .pricing-title-price:after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 40%;
    height: 3px;
    border-bottom: 3px  dashed var(--red-dark);
}
.pricing-style-one .pricing-item .pricing-title-price .pricing-price {
    background-color: var(--red-dark);
    display: inline-flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    right: 0;
    top: -55px;
    width: 40%;
    padding: 22px 10px;
}
.pricing-style-one .pricing-item .pricing-title-price .pricing-price:after {
    content: '';
    width: 100%;
    height: 20px;
    bottom: -20px;
    left: 0px;
    border-top: 20px solid var(--red-dark);
    border-left: 58px solid transparent;
    border-right: 58px solid transparent;
    position: absolute;
}
.pricing-style-one .pricing-item .pricing-title-price .pricing-price:before{
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 0;
    left: -10px;
    border-bottom: 10px solid var(--red-dark);
    border-left: 10px solid transparent;
}
.pricing-style-one .pricing-item .pricing-title-price .pricing-price h3.price {
    
    font-size: 37px;
    color: #fff;
    margin-top: 15px;
}
.pricing-style-one .pricing-item .pricing-title-price .pricing-price h3.price span.currency{
    font-size: 18px;
}
.pricing-style-one .pricing-item .pricing-title-price .pricing-price h3.price span.sm-text{
    display: block;
    font-size: 14px;
    line-height: 14px;
}
.pricing-style-one .pricing-item .pricing-title-price .pricing-title {
    width: 60%;
    word-break: break-word;
}
.pricing-style-one .pricing-item .pricing-title-price .pricing-title h3.title{
    font-size: 27px;
}
.pricing-style-one .pricing-item .pricing-title-price .pricing-title span.discount{
    margin-bottom: 15px;
}
.pricing-style-one .pricing-item .pricing-body p{
    margin-bottom: 20px;
}
.pricing-style-one .pricing-item .pricing-body .list{
    margin-bottom: 30px;
}
.pricing-style-one .pricing-item .pricing-body .list li.check:before{
    color: var(--red-dark);
}
.pricing-style-one .pricing-item .pricing-body .main-btn {
    margin-bottom: -20px;
    padding: 10px 30px;
    background-color: var(--black-dark);
    color: #fff;
}
.pricing-style-one .pricing-item .pricing-body .main-btn:hover{
    background-color: var(--red-dark);
}
.pricing-style-two .pricing-nav .nav-tabs{
    background-color: #f8f8f8;
}
.pricing-style-two .pricing-nav .nav-tabs li:after{
    border-color: var(--purple-blue);
}
.pricing-style-two .pricing-nav .nav-link.active,
.pricing-style-two .pricing-nav .nav-link:hover{
    color: var(--purple-blue);
}
/* Pricing Two css */
.pricing-style-two .pricing-item{
    padding: 45px 35px;
    border: 1px solid rgba(27, 29, 33, .102);
}
.pricing-style-two .pricing-item .pricing-body .main-btn{
    padding: 10px 20px;
    margin-bottom: 20px;
}
.pricing-style-two .pricing-title-price .pricing-price h3.price{
    font-size: 45px;
    margin-bottom: 10px;
}
.pricing-style-two .pricing-title-price .pricing-price h3.price span.currency{
    font-size: 18px;
    margin-right: 5px;
}
.pricing-style-two .pricing-title-price .pricing-price h3.price span.sm-text{
    font-size: 16px;
    color: var(--text-color);
    margin-left: 5px;
}
.pricing-style-two .pricing-title-price .pricing-title h3.title{
    text-decoration: underline;
}
/* Pricing Three CSS */
.pricing-style-three .pricing-item{
    position: relative;
    background-color: #0a0b11;
    padding: 50px 70px;
}
.pricing-style-three .pricing-item .pricing-title-price .pricing-title .icon{
    margin-bottom: 40px;
}
.pricing-style-three .pricing-item .pricing-title-price h3.title{
    color: var(--blue-dark);
    margin-bottom: 30px;
}
.pricing-style-three .pricing-item .pricing-price h3.price span.currency{
    font-size: 24px;
    vertical-align: super;
}
.pricing-style-three .pricing-item .pricing-price h3.price{
    font-size: 65px;
    line-height: 1;
    color: #fff;
    font-weight: 600;
    margin-bottom: 30px;
}
.pricing-style-three .pricing-item .pricing-price h3.price span.sm-text{
    font-size: 20px;
}
.pricing-style-three .pricing-item .pricing-body ul.list{
    margin-bottom: 35px;
}
.pricing-style-three .pricing-item .pricing-body ul.list li{
    padding-bottom: 5px;
    font-size: 17px;
}
.pricing-style-three .pricing-item .pricing-body ul.list li.check{
    color: #fff;
}
.pricing-style-three .pricing-item .pricing-body ul.list li.uncheck{
    color: #656871;
}
.pricing-style-three .pricing-item .pricing-body ul.list li.check:before{
    color: var(--yellow-dark);
}
.pricing-style-three .pricing-item:hover .pricing-body .btn-gray-dark{
    background-color: var(--blue-dark);
}
/*---=========================
    End Pricing css 
=========================---*/

/*---=========================
    11.Start Testimonial css 
=========================---*/
/* Testimonial One css */
.testimonial-style-one{
    position: relative;
    z-index: 1;
}
.testimonial-style-one:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient( -90deg, rgba(41,129,233,0.85098) 0%, rgba(62,67,220,0.9) 99%, rgb(62,67,220) 100%);
    z-index: -1;
}
.testimonial-style-one .testimonial-content-box .user-images{
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.testimonial-style-one .testimonial-content-box .user-images li {
    width: 50px;
    flex: 0 0 50px;
}
.testimonial-style-one .testimonial-content-box .user-images li img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.testimonial-style-one .testimonial-content-box .user-images li .add-icon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient( -180deg, rgb(41,129,233) 0%, rgb(62,67,220) 99%);
    color: #fff;
}
.testimonial-style-one .testimonial-content-box .user-images li:not(:first-child) {
    margin-left: -25px;
}
.testimonial-style-one .testimonial-content-box .section-title span.sub-title{
    color: #fff;
}
.testimonial-style-one .testimonial-content-box p{
    color: #fff;
    margin-bottom: 25px;
}
.testimonial-slider-one{
    margin-left: -15px;
    margin-right: -15px;
}
.testimonial-slider-one .testimonial-item{
    margin-left: 15px;
    margin-right: 15px;
}
.testimonial-style-one .testimonial-item{
    padding: 45px 40px;
    border-radius: 7px;
    background-color: #fff;
}
.testimonial-style-one .testimonial-item .wt-content .author-title-thumb{
    display: flex;
}
.testimonial-style-one .testimonial-item .wt-content .author-title-thumb .author-thumb{
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    margin-right: 20px;
}
.testimonial-style-one .testimonial-item .wt-content p{
    margin-bottom: 20px;
}
.testimonial-style-one .testimonial-item .wt-content .author-title-thumb .author-title h3{
    font-size: 22px;
    line-height: 30px;
}
.testimonial-style-one .testimonial-item .wt-content .author-title-thumb .author-title p.position{
    font-weight: 500;
    margin-bottom: 0px;
}

/* Testimonial Style two */
.testimonial-slider-two{
    margin-left: -15px;
    margin-right: -15px;
}
.testimonial-slider-two .slick-track{
    padding: 25px 0 10px;
}
.testimonial-slider-two .testimonial-item{
    margin-left: 15px;
    margin-right: 15px;
}
.testimonial-slider-two .slick-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background-color: #eeebf3;
    color: var(--purple-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    transition: all .3s;
}
.testimonial-slider-two .slick-arrow:hover{
    background-color: var(--purple-blue);
    color: #fff;
}
.testimonial-slider-two .slick-arrow.prev{
    left: -110px;
}
.testimonial-slider-two .slick-arrow.next{
    right: -110px;
}
.testimonial-style-two .testimonial-item{
    position: relative;
    padding: 0 40px 40px;
    background-color: #fff;
}
.testimonial-style-two .testimonial-item:after{
    position: absolute;
    bottom: -10px;
    right: -10px;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #e8e9ea;
    z-index: -1;
}
.testimonial-style-two .testimonial-item .wt-thumb-box{
    display: flex;
    align-items: flex-end;
    margin-top: -25px;
    margin-bottom: 30px;
}
.testimonial-style-two .testimonial-item .wt-thumb-box .thumb {
    position: relative;
    z-index: 1;
}
.testimonial-style-two .testimonial-item .wt-thumb-box .thumb:after{
    position: absolute;
    bottom: -10px;
    right: -10px;
    content: '';
    width: 80px;
    height: 80px;
    border-radius: 5px;
    background-color: #e8e9ea;
    z-index: -1;
}
.testimonial-style-two .testimonial-item .wt-thumb-box .thumb img{
    border-radius: 5px;
}
.testimonial-style-two .testimonial-item .wt-thumb-box .comment{
    flex-grow: 1;
    text-align: right;
}
.testimonial-style-two .testimonial-item .wt-content p{
    margin-bottom: 15px;
}
.testimonial-style-two .testimonial-item .wt-content .quote-title-box{
    display: flex;
}
.testimonial-style-two .testimonial-item .wt-content .quote-title-box .comment{
    flex-grow: 1;
}
.testimonial-style-two .testimonial-item .wt-content .quote-title-box .quote{
    margin-right: 20px;
}
.testimonial-style-two .testimonial-item .wt-content .quote-title-box .quote i{
    font-size: 45px;
    color: var(--blue-light);
}
.testimonial-style-two .testimonial-item .wt-content .quote-title-box .author-title h5{
    line-height: 20px;
}
.testimonial-style-two .testimonial-item .wt-content .quote-title-box .author-title span.position{
    font-size: 15px;
    line-height: 15px;
}

/* Testimonial Three css */
.testimonial-style-three .testimonial-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 46%;
    height: 100%;
}
.testimonial-style-three .testimonial-item{
    background-color: #fff;
    padding: 50px;
}
.testimonial-style-three .testimonial-item .wt-content {
    border-bottom: 1px solid rgba(27, 27, 32, 0.07);
}
.testimonial-style-three .testimonial-item .wt-content p{
    font-size: 22px;
    line-height: 38px;
    margin-bottom: 30px;
    color: var(--heading);
}
.testimonial-style-three .testimonial-item .author-title-thumb .author-thumb{
    flex: 0 0 70px;
    max-width: 70px;
    margin-right: 20px;
}
.testimonial-style-three .testimonial-item .author-title-thumb .author-thumb img{
    width: 100%;
    border-radius: 50%;
}
.testimonial-style-three .testimonial-arrows{
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
    position: relative;
}
.testimonial-style-three .testimonial-arrows .slick-arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 1px solid rgba(27, 27, 32, 0.07);
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;
    transition: all .3s;
}
.testimonial-style-three .testimonial-arrows .slick-arrow:hover{
    background-color: var(--blue-dark);
    color: #fff;
}
.testimonial-style-three .testimonial-arrows .slick-arrow.next{
    margin-left: 10px;
}

/* Testimonial Four css */
.testimonial-style-four .section-title{
    position: relative;
    display: inline-flex;
    flex-direction: column;
    background-color: var(--blue-light);
    padding: 90px 135px 85px;
    margin-left: -22px;
    position: absolute;
    top: 80px;
    z-index: 1;
}
.testimonial-style-four .section-title:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    /* background: url(../image/pattern-title.png) no-repeat center; */
    background-size: cover;
    z-index: -1;
}
.testimonial-style-four .title-img-box{
    position: relative;
}
.testimonial-style-four .title-img-box .section-title{
    position: absolute;
    top: 0;
}
.testimonial-style-four .img-holder,
.testimonial-style-four .text-wrapper{
    width: 50%;
}
.testimonial-style-four .img-holder img{
    width: 100%;
}
.testimonial-style-four .text-wrapper{
    background-color: #2f5aa8;
    padding: 100px 185px 50px;
}
.testimonial-style-four .testimonial-item .wt-content{
    position: relative;
    padding: 40px 45px;
    background-color: #fff;
    border-radius: 7px;
}
.testimonial-style-four .testimonial-item .wt-content:after{
    position: absolute;
    bottom: -10px;
    left: 65px;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
}
.testimonial-style-four .testimonial-item .wt-content p{
    font-size: 18px;
    line-height: 35px;
}
.testimonial-style-four .testimonial-item .author-title-thumb{
    padding-left: 45px;
}
.testimonial-style-four .testimonial-item .author-title-thumb .author-thumb{
    flex: 0 0 60px;
    max-width: 60px;
}
.testimonial-style-four .testimonial-item .author-title-thumb .author-thumb img{
    width: 100%;
    border-radius: 50%;
}
.testimonial-style-four .testimonial-item .author-title-thumb .author-title h3,
.testimonial-style-four .testimonial-item .author-title-thumb .author-title p.position{
    color: #fff;
}

/* Screenshot section */
.screenshot-slider{
    margin-left: -15px;
    margin-right: -15px;
}
.screenshot-slider .slick-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 55px;
    height: 55px;
    background-color: #0d0e12;
    color: #fff;
    font-size: 18px;
    transition: all .3s;
}
.screenshot-slider .slick-arrow:hover{
    background-color: var(--blue-dark);
}
.screenshot-slider .slick-arrow.prev{
    left: -85px;
}
.screenshot-slider .slick-arrow.next{
    right: -85px;
}
.screenshot-slider .screenshot-item{
    margin-left: 15px;
    margin-right: 15px;
}
/*---=========================
    End Testimonial css 
=========================---*/

/*---=========================
    12. Start Team css 
=========================---*/

/* Notification css */
.notification-box{
    background-color: #f7f8f9;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.notification-box h5 span{
    margin-right: 15px;
}
.notification-box .main-btn{
    margin-left: 20px;
    color: #fff;
}

/* Team One css */
.team-style-one .team-item{
    padding: 0 5px;
}
.team-style-one .team-item:hover .team-img .team-overlay{
    bottom: 0;
}
.team-style-one .team-item:hover .team-img .team-overlay .team-content ul.social-link{
    visibility: visible;
    opacity: 1;
}
.team-style-one .team-item .team-img img{
    width: 100%;
}
.team-style-one .team-item .team-img{
    position: relative;
    overflow: hidden;
}
.team-style-one .team-item .team-img .team-overlay{
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    transition: all .3s;
}
.team-style-one .team-item .team-img .team-overlay .team-content{
    background-color: #fff;
    width: 90%;
    padding: 8px 20px 6px;
}
.team-style-one .team-item .team-img .team-overlay .team-content h6.title{
    text-transform: uppercase;
    font-size: 15px;
}
.team-style-one .team-item .team-img .team-overlay .team-content h6.title:hover{
    color: var(--red-dark);
}
.team-style-one .team-item .team-img .team-overlay .team-content p.position{
    font-size: 14px;
    line-height: 24px;
}
.team-style-one .team-item .team-img .team-overlay .team-content ul.social-link{
    visibility: hidden;
    opacity: 0;
    transition: all .4s;
}
.team-style-one .team-item .team-img .team-overlay .team-content ul.social-link li{
    margin-right: 15px;
}
.team-style-one .team-item .team-img .team-overlay .team-content ul.social-link li:last-child{
    margin-right: 0px;
}
.team-style-one .team-item .team-img .team-overlay .team-content ul.social-link li a:hover{
    color: var(--red-dark);
}

/* Team Two css */
.team-style-two-bg{
    position: relative;
    z-index: 1;
}
.team-style-two-bg:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #141d38;
    mix-blend-mode: multiply;
    z-index: -1;
}
.team-style-two .team-item:hover .team-img .team-overlay{
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
}
.team-style-two .team-item .team-img{
    position: relative;
    overflow: hidden;
}
.team-style-two .team-item .team-img img{
    width: 100%;
}
.team-style-two .team-item .team-img .team-overlay{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 85%;
    transform: translateY(50%);
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
}
.team-style-two .team-item .team-img .team-overlay .team-content{
    background-color: #fff;
    
    padding: 30px;
}
.team-style-two .team-item .team-img .team-overlay .team-content h3.title{
    font-size: 22px;
}
.team-style-two .team-item .team-img .team-overlay .team-content h3.title a{
    text-decoration: none;
}
.team-style-two .team-item .team-img .team-overlay .team-content p.position{
    font-weight: 700;
    margin-bottom: 4px;
}
.team-style-two .team-item .team-img .team-overlay .team-content ul.social-link li{
    margin-right: 15px;
}
.team-style-two .team-item .team-img .team-overlay .team-content h3.title:hover,
.team-style-two .team-item .team-img .team-overlay .team-content ul.social-link li a:hover{
    color: var(--blue-light);
}

/* Team Three css */
.team-style-three {
    margin-top: -280px;
    padding-top: 400px;
    background-color: #f2f5fa;
}
.team-style-three .team-item{
    padding: 0 30px;
}
.team-style-three .team-item .team-img {
    position: relative;
    z-index: 1;
}
.team-style-three .team-item .team-img img{
    border-radius: 65% 35% 60% 40% / 65% 40% 60% 35%;
}
.team-style-three .team-item .team-img:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid rgba(171, 176, 191, 0.432);
    border-radius: 35% 65% 40% 60% / 40% 65% 35% 60%;
    z-index: -1;
}
.team-style-three .team-item .team-img .team-hover .team-social ul.social-link li{
    margin: 0 2px;
}
.team-style-three .team-item .team-img .team-hover .team-social ul.social-link li a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #32353d;
    color: #fff;
    border-radius: 5px;
    transition: all .3s;
}
.team-style-three .team-item .team-img .team-hover .team-social ul.social-link li a:hover{
    background-color: var(--blue-light);
    color: #fff;
}
.team-style-three .team-item .team-content{
    padding-top: 30px;
}
.team-style-three .team-item .team-content h3.title:hover{
    color: var(--blue-light);
}
.team-style-three .team-item .team-content  p.position{
    font-weight: 500;
}

/* Team Four css */
.team-style-four .team-item:hover .team-img .team-overlay{
    transform: translateY(0px);
    visibility: visible;
    opacity: 1;
}
.team-style-four .team-item .team-img{
    position: relative;
    overflow: hidden;
}
.team-style-four .team-item .team-img .team-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px 55px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30px);
    transition: all .3s;
}
.team-style-four .team-item .team-img .team-overlay .team-social{
    background-color: #fff;
    padding: 15px 20px;
    text-align: center;
}
.team-style-four .team-item .team-img .team-overlay .team-social ul.social-link li{
    margin-left: 3px;
    margin-right: 3px;
}
.team-style-four .team-item .team-img .team-overlay .team-social ul.social-link li a{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--purple-blue);
    font-size: 18px;
    color: #fff;
    transition: all .3s;
}
.team-style-four .team-item .team-img .team-overlay .team-social ul.social-link li a:hover{
    background-color: var(--blue-light);
}
.team-style-four .team-item .team-content{
    padding-top: 25px;
}
.team-style-four .team-item .team-content h3.title{
    margin-bottom: 3px;
}
.team-style-four .team-item .team-content h3.title:hover{
    color: var(--blue-light);
}
.team-style-four .team-item .team-content p.position{
    font-weight: 500;
}

/* Team Details Section */
.team-details-section .team-content{
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 10px 60px 0px rgba(58, 65, 111, 0.1)
}
.team-details-section .team-content .member-info{
    padding: 50px 50px;
}
.team-details-section .team-content .member-info,
.team-details-section .team-content .member-img{
    width: 50%;
    flex: 0 0 50%;
}
.team-details-section .team-content .member-info h3{
    font-size: 42px;
    line-height: 52px;
}
.team-details-section .team-content .member-info p.position{
    font-weight: 500;
    margin-bottom:  15px;
}
.team-details-section .team-content .member-info p{
    margin-bottom: 20px;
}
.team-details-section .team-content .member-info ul.social-link {
    padding-top: 30px;
    border-top: 1px solid rgba(20, 29, 56, 0.1);
}
.team-details-section .team-content .member-info ul.social-link li{
    margin-right: 5px;
}
.team-details-section .team-content .member-info ul.social-link li a{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--purple-blue);
    color: #fff;
}
.team-details-section .team-content .member-info ul.social-link li a:hover{
    background-color: var(--blue-light);
}
.team-details-section .team-content .member-img img{
    width: 100%;
}
/*---=========================
    End Team css 
=========================---*/

/*---========================
    13. Start Team css 
========================---*/
.faq-accordian{
    overflow-anchor: none;
}
.faq-accordian .card{
    border-radius: 0;
    border: none;
}
.faq-accordian .card .card-header{
    padding: 0;
    border: none;
}
.faq-accordian .card .card-header a{
    position: relative;
    font-weight: 700;
    font-family: var(--rajdhani);
    color: var(--heading);
    display: flex;
    align-items: center;
}
.faq-accordian .card .card-header a[aria-expanded]:after{
    margin-left: auto;
    font-weight: 600;
    font-family: 'Font Awesome 5 Pro';
}
.accordian-default .card{
    padding-bottom: 18px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px;
}
.accordian-default .card:last-child{
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}
.accordian-default .card .card-header{
    background-color: transparent;
}
.accordian-default .card .card-header a{
    align-items: center;
    color: var(--heading);
    padding: 0;
    text-decoration: none;
}
.accordian-default .card .card-header a[aria-expanded]:after{
    content: '\f107';
}
.accordian-default .card .card-header a[aria-expanded="true"]:after{
    content: '\f106';
}
.accordian-default .card .card-body{
    background-color: transparent;
    padding: 0;
}
/* Faq One css */
.faq-accordian-one .card {
    margin-bottom: 10px;
}
.faq-accordian-two .card .card-header a,
.faq-accordian-one .card .card-header a{
    padding: 0 0 0 30px;
    font-size: 20px;
}
.faq-accordian-one .card .card-header a{
    background-color: #f2f5fa;
}
.faq-accordian-one .card .card-header a[aria-expanded="true"]{
    background-color: #00bbee;
    color: #fff;
}
.faq-accordian-two .card .card-header a[aria-expanded]:after,
.faq-accordian-one .card .card-header a[aria-expanded]:after{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    content: '\f107';
    color: #fff;
}
.faq-accordian-one .card .card-header a[aria-expanded]:after{
    background-color: var(--heading);
}
.faq-accordian-one .card .card-header a[aria-expanded="true"]:after{
    content: '\f106';
}
.faq-accordian-one .card .card-body{
    padding: 23px 30px 13px;
}
/* faq two css */
.faq-content-box-one{
    margin-left: 45px;
}
.faq-accordian-two .card{
    margin-bottom: 15px;
    background-color: #f8f8f8;
}
.faq-accordian-two .card:last-child{
    margin-bottom: 0;
}
.faq-accordian-two .card .card-header a[aria-expanded="true"]{
    background-color: var(--blue-light);
    color: #fff;
}
.faq-accordian-two .card .card-header a[aria-expanded="true"]:after{
    content: '\f106';
}
.faq-accordian-two .card .card-header a[aria-expanded]:after{
    background-color: var(--purple-blue);
    text-decoration: none;
}
.faq-accordian-two .card .card-body{
    padding: 23px 30px;
}

.faq-content-box-two{
    margin-right: 30px;
}
.faq-content-box-two .faq-accordian{
    padding: 20px 40px 25px;
    border: 2px solid #ededed;
}
.faq-img-one img{
    max-width: 730px;
}

/* App FAQ css */
.app-accordian .card{
    border-radius: 0;
    border: none;
    background-color: transparent;
    margin-bottom: 20px;
}
.app-accordian .card .card-header{
    padding: 0;
    background-color: transparent;
    border: none;
}
.app-accordian .card .card-header a{
    position: relative;
    display: flex;
    align-items: center;
    font-family: var(--rajdhani);
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    padding: 4px 0;
    padding-left: 75px;
}
.app-accordian .card .card-header a[aria-expanded]:after{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    content: '\f178';
    font-family: 'Font Awesome 5 Pro';
    width: 55px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue-dark);
    border-radius: 17.5px 17.5px 17.5px 17.5px;
    margin-left: 0;
}
.app-accordian .card .card-header a[aria-expanded="true"]:after{
    background-color: var(--yellow-dark);
}
.app-accordian .card .card-body{
    padding: 0;
    padding-top: 5px;
    padding-left: 75px;
}
/*---==================
    End Team css 
=====================---*/

/*---==================
    14. Start Shop css 
=====================---*/
ul.ratings{
    display: inline-flex;
}
.ratings.ratings-1 li.star:nth-child(1) i, 
.ratings.ratings-2 li.star:nth-child(1) i,
.ratings.ratings-2 li.star:nth-child(2) i, 
.ratings.ratings-3 li.star:nth-child(1) i,
.ratings.ratings-3 li.star:nth-child(2) i, 
.ratings.ratings-3 li.star:nth-child(3) i,
.ratings.ratings-4 li.star:nth-child(1) i, 
.ratings.ratings-4 li.star:nth-child(2) i,
.ratings.ratings-4 li.star:nth-child(3) i,
.ratings.ratings-4 li.star:nth-child(4) i, 
.ratings.ratings-5 li.star:nth-child(1) i,
.ratings.ratings-5 li.star:nth-child(2) i,
.ratings.ratings-5 li.star:nth-child(3) i,
.ratings.ratings-5 li.star:nth-child(4) i,
.ratings.ratings-5 li.star:nth-child(5) i {
    color: #fd6350;
}
.product-sidebar-widget .widget{
    padding: 40px 35px;
    border: 2px solid rgba(20, 29, 56, .102);
}
.product-sidebar-widget .widget h4.widget-title{
    margin-bottom: 20px;
}
.product-sidebar-widget .widget.search-widget{
    padding: 0;
    border: none;
}
.product-sidebar-widget .widget.search-widget form .form_control{
    background-color: #141d38;
    height: 60px;
    border-radius: 5px;
    color: #fff;
    padding: 0 25px;
}
.product-sidebar-widget .widget.search-widget form .form_control::placeholder{
    color: #fff;
}
.product-sidebar-widget .widget.search-widget form .search-btn{
    position: absolute;
    top: 17px;
    right: 25px;
    border: none;
    background-color: transparent;
    color: #fff;
}
.product-sidebar-widget .widget.category-widget ul.category-nav li{
    margin-bottom: 10px;
}
.product-sidebar-widget .widget.category-widget ul.category-nav li:last-child{
    margin-bottom: 0px;
}
.product-sidebar-widget .widget.category-widget ul.category-nav li a{
    font-weight: 500;
}
.product-sidebar-widget .widget.category-widget ul.category-nav li a:hover{
    color: var(--blue-light);
}
.product-sidebar-widget .widget.category-widget ul.category-nav li a i{
    margin-right: 10px;
}
.product-sidebar-widget .widget.price-range-widget .price-number span.amount input{
    border: none;
    font-weight: 18px;
    font-weight: 700;
}
.product-sidebar-widget .widget.price-range-widget .ui-widget.ui-widget-content{
    border-radius: 0;
    height: 5px;
    background-color: rgba(20, 29, 56, 0.1);
    border: none;
}
.product-sidebar-widget .widget.price-range-widget .ui-slider-horizontal .ui-slider-range{
    background-color: var(--blue-light);
}
.product-sidebar-widget .widget.price-range-widget .ui-slider .ui-slider-handle{
    height: 15px;
    width: 5px;
    border: none;
    background: #43baff;
    outline: none;
    margin-left: 0;
}
.product-sidebar-widget .widget.recent-product-widget .product-item{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.product-sidebar-widget .widget.recent-product-widget .product-item:last-child{
    margin-bottom: 0;
}
.product-sidebar-widget .widget.recent-product-widget .product-item .product-thumb{
    flex: 0 0 70px;
    max-width: 70px;
    height: 70px;
    margin-right: 20px;
}
.product-sidebar-widget .widget.recent-product-widget .product-item .product-info h3.title{
    font-size: 18px;
    font-family: var(--rajdhani);
    line-height: 1;
    margin-bottom: 5px;
}
.product-sidebar-widget .widget.recent-product-widget .product-item .product-info h3.title:hover{
    color: var(--blue-light);
}
.product-sidebar-widget .widget.recent-product-widget .product-item .product-info h3.title a{
    text-decoration: none;
}
.product-sidebar-widget .widget.recent-product-widget .product-item .product-info a.category{
    font-size: 16px;
    line-height: 15px;
}
.product-sidebar-widget .widget.tag-cloud-widget a{
    padding: 5px 15px;
    border-radius: 3px;
    background-color: rgba(67, 186, 255, 0.1);
    font-size: 15px;
    font-weight: 500;
    margin-right: 4px;
    margin-bottom: 10px;
}
.product-sidebar-widget .widget.tag-cloud-widget a:hover{
    background-color: var(--blue-light);
    color: #fff;
}
.products-style-one .product-item .product-info{
    padding: 20px 15px;
    border: 1px solid rgba(11, 20, 96, 0.102);
    border-top: none;
}
.products-style-one .product-item .product-info h3.title{
    font-size: 18px;
}
.products-style-one .product-item .product-info h3.title:hover{
    color: var(--blue-light);
}
.products-style-one .product-item .product-info  span.price{
    font-weight: 500;
}
.products-style-one .product-item .product-info  span.price span.pre-price{
    text-decoration: line-through;
    margin-left: 5px;
}
.products-style-one .product-item:hover .product-thumb .product-overlay .product-meta .icon{
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
}
.products-style-one .product-item .product-thumb{
    position: relative;
    overflow: hidden;
}
.products-style-one .product-item .product-thumb img{
    width: 100%;
}
.products-style-one .product-item .product-thumb .product-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.products-style-one .product-item .product-thumb .product-overlay .product-meta{
    display: inline-flex;
}
.products-style-one .product-item .product-thumb .product-overlay .product-meta .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #fff;
    color: var(--heading);
    font-size: 16px;
    transform: translateY(100%);
    margin-left: 5px;
    margin-right: 5px;
    visibility: hidden;
    opacity: 0;
}
.products-style-one .product-item .product-thumb .product-overlay .product-meta .icon:hover{
    background-color: var(--blue-light);
    color: #fff;
}
.products-style-one .product-item .product-thumb .product-overlay .product-meta .icon.img-popup{
    transition: all .5s
}
.products-style-one .product-item .product-thumb .product-overlay .product-meta .icon.cart-btn{
    transition: all .3s
}
.products-style-one .product-item .product-thumb .product-overlay .product-meta .icon.wishlist-btn{
    transition: all .4s
}


/* Product Details CSS */
.product-details-wrapper .product-info .product-holder {
    padding-bottom: 20px;
}
.product-details-wrapper .product-info .product-holder span.price {
    display: block;
    color: #fd6350;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}
.product-details-wrapper .product-info .color-filter-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding: 15px 0 20px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    margin-bottom: 15px;
}
.product-details-wrapper .product-info .color-filter-wrap  h5{
    margin-right: 10px;
    line-height: 1;
}
.product-details-wrapper .product-info .color-filter-wrap .single-color {
    margin-right: 15px;
    width: 15px;
    height: 15px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.product-details-wrapper .product-info .color-filter-wrap .single-color label {
    position: relative;
    cursor: pointer;
    margin-bottom: 0px;
}
.product-details-wrapper .product-info .color-filter-wrap .single-color label:before {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    background-color: #f5deb3;
}
.product-details-wrapper .product-info .color-filter-wrap .single-color label:after {
    width: 20px;
    height: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 50%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}
.product-details-wrapper .product-info .color-filter-wrap .single-color input {
    display: none;
}
.product-details-wrapper .product-info .color-filter-wrap .single-color input:checked + label:after{
    border-color: var(--blue-light);
}
.product-details-wrapper .product-info .color-filter-wrap .single-color.black label:before {
    background-color: #000000;
}
.product-details-wrapper .product-info .color-filter-wrap .single-color.orange label:before {
    background-color: #ff9054;
}
.product-details-wrapper .product-info .color-filter-wrap .single-color.pink label:before {
    background-color: #ff31fd;
}
.product-details-wrapper .product-info .color-filter-wrap .single-color.green label:before {
    background-color: #2bd175;
}
.product-details-wrapper .product-info .color-filter-wrap .single-color.white label:before {
    background-color: #fff;
}
.product-details-wrapper .product-info p {
    margin-bottom: 20px;
}
.product-details-wrapper .product-info .product-meta {
    display: inline-flex;
    margin-bottom: 7px;
}
.product-details-wrapper .product-info .product-meta li {
    font-size: 15px;
    margin-right: 25px;
    margin-bottom: 10px;
}
.product-details-wrapper .product-info .product-meta li span {
    font-weight: 700;
    color: #070346;
    margin-right: 5px;
}
.product-details-wrapper .product-info .product-cart{
    padding-top: 20px;
}
.product-details-wrapper .product-info .product-cart ul li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}
.product-details-wrapper .product-info .product-cart ul li .wishlist-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border: 1px solid #e5e5e5;
    color: #fd6350;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.product-details-wrapper .product-info .product-cart ul li .wishlist-btn:hover{
    background-color: var(--red-dark);
    color: #fff;
    border-color: transparent;
}
.product-details-wrapper .product-info .product-cart ul li .main-btn{
    padding: 10px 35px;
}
.product-details-wrapper .product-info .product-cart ul li .nice-number input[type=number]{
    width: 50px !important;
    height: 48px;
    border: none;
    background-color: #f8f8f8;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
}
.product-details-wrapper .product-info .product-cart ul li .nice-number button{
    width: 50px;
    height: 50px;
    background-color: transparent;
    font-size: 18px;
    border: 1px solid #e1e1e1;
}
.product-gallery-area .product-img{
    cursor: pointer;
}
.product-gallery-area .product-img a{
    display: block;
}
.product-gallery-area .product-img img{
    width: 100%;
}
.product-gallery-area .product-thumb-slider{
    margin-left: -15px;
    margin-right: -15px;
}
.product-gallery-area .product-thumb-slider .product-img{
    margin-left: 15px;
    margin-right: 15px;
}
.product-details-section .discription-area .discription-tabs .nav-tabs{
    border-bottom: none;
    justify-content: center;
}
.product-details-section .discription-area .discription-tabs .nav-tabs li{
    margin-bottom: 10px;
}
.product-details-section .discription-area .discription-tabs .nav-link{
    padding: 13px 65px;
    border: none;
    border-radius: 0;
    background-color: #f8f8f8;
    font-size: 20px;
    text-decoration: underline;
    font-weight: 700;
    color: var(--heading);
    font-family: var(--rajdhani);
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
}
.product-details-section .discription-area .discription-tabs .nav-link.active{
    background-color: var(--blue-light);
    color: #fff;
}
.product-details-section .discription-area .content-box h3{
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
}
.products-review-area .review-area .review-list .review{
    display: flex;
    margin-bottom: 30px;
}
.products-review-area .review-area .review-list .review img{
    max-width: 80px;
    flex: 0 0 80px;
    height: 80px;
    margin-right: 30px;
}
.products-review-area .review-area .review-list .review .review-info{
    margin-top: -7px;
}
.products-review-area .review-area .review-list .review .review-info .author-title-review .title h5 span.date{
    font-size: 14px;
    margin-left: 10px;
    font-weight: 600;
}
.products-review-area .review-form{
    padding-top: 40px;
    border-top: 2px solid #e1e1e1;
}
.products-review-area .review-form form .form_control{
    height: 60px;
    background-color: #f8f8f8;
    padding: 0 30px;
    margin-bottom: 20px;
}
.products-review-area .review-form form .form_control::placeholder{
    color: var(--heading);
}
.products-review-area .review-form form textarea.form_control{
    height: 150px;
}
.releted-products-slider{
    margin-left: -15px;
    margin-right: -15px;
}
.releted-products-slider .product-item{
    margin-left: 15px;
    margin-right: 15px;
}
/*---=========================
    End Shop css 
=========================---*/

/*---=========================
    15. Start Blog css 
=========================---*/

.post-thumbnail{
    position: relative;
}
.post-meta ul li{
    display: inline-block;
    margin-bottom: 5px;
}
.post-meta ul li i{
    margin-right: 10px;
}
.post-meta ul li span a:hover{
    color: var(--blue-light);
}
.b-user-admin{
    margin-top: -35px;
    position: relative;
    z-index: 1;
    margin-right: 20px;
}
.b-user-admin a{
    border-radius: 35px;
    padding: 10px 30px 10px 10px;
    background-color: #eff3f6;
}
.b-user-admin .thumb{
    flex: 0 0 50px;
    max-width: 50px;
    height: 50px;
}
.b-user-admin .thumb img{
    width: 100%;
    border-radius: 50%;
}
.b-user-admin span{
    margin-left: 20px;
    font-weight: 700;
    font-family: var(--rajdhani);
    color: #151515;
    text-transform: capitalize;
}

/* Blog Style One CSS */
.blog-style-one .blog-post-item .entry-content .b-user-admin{
    margin-bottom: 15px;
}
.blog-style-one .blog-post-item .entry-content .post-meta ul li{
    margin-right: 23px;
    margin-bottom: 8px;
}
.blog-style-one .blog-post-item .entry-content h3.title{
    margin-bottom: 18px;
}
.blog-style-one .blog-post-item .entry-content .post-meta ul li span a:hover,
.blog-style-one .blog-post-item .entry-content h3.title:hover{
    color: var(--red-dark);
}
.blog-style-one .blog-post-item .entry-content  p{
    margin-bottom: 27px;
}
.blog-style-one .blog-post-item .entry-content .main-btn.filled-btn{
    padding: 8px 28px;
}
.blog-style-one .blog-post-item .entry-content .main-btn.filled-btn:hover{
    background-color: var(--red-dark);
    color: #fff;
}

/* Blog Style Two CSS */
.blog-style-two .blog-post-item{
    padding: 40px 40px 30px;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    overflow: hidden;
}
.blog-style-two .blog-post-item:hover:after{
    background: linear-gradient( -180deg, rgba(41,129,233,0.94902) 0%, rgba(62,67,220,0.94) 99%, rgb(62,67,220) 100%);
}
.blog-style-two .blog-post-item:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--light-gray);
    z-index: -1;
    transition: all .3s;
}
.blog-style-two .blog-post-item:before{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    /* background: url(../image/post-bg-1.jpg) no-repeat center; */
    background-size: cover;
    z-index: -1;
}
.blog-style-two .blog-post-item:hover .entry-content .post-meta ul li span{
    color: #fff;
}
.blog-style-two .blog-post-item:hover .entry-content h3.title{
    color: #fff;
}
.blog-style-two .blog-post-item:hover .entry-content p,
.blog-style-two .blog-post-item:hover .entry-content .btn-link{
    color: #fff;
}
.blog-style-two .blog-post-item .entry-content{ 
    position: relative;
    padding-left: 85px;
}
.blog-style-two .blog-post-item .entry-content .user-thumb{
    position: absolute;
    top: 0;
    left: 0;
    border: 7px solid #eff3f6;
    width: 65px;
    height: 65px;
    border-radius: 50%;
}
.blog-style-two .blog-post-item .entry-content .user-thumb img{
    width: 100%;
    border-radius: 50%;
}
.blog-style-two .blog-post-item .entry-content .post-meta ul li{
    margin-right: 25px;
}
.blog-style-two .blog-post-item .entry-content h3.title{
    font-size: 27px;
    line-height: 32px;
    margin-bottom: 15px;
}
.blog-style-two .blog-post-item .entry-content p{
    margin-bottom: 6px;
}

/* Blog Style Three */
.blog-style-three .blog-post-item{
    padding: 40px 30px;
    border: 1px solid rgba(31, 37, 49, .10);
    transition: all .3s;
}
.blog-style-three .blog-post-item:hover{
    border-color: transparent;
    box-shadow: 0px 10px 30px 0px rgba(58, 65, 111, 0.1);
}
.blog-style-three .blog-post-item .entry-content h3.title{
    margin-bottom: 17px;
    font-size: 22px;
}
.blog-style-three .blog-post-item .entry-content h3.title:hover{
    color: var(--blue-light);
}
.blog-style-three .blog-post-item .entry-content p{
    margin-bottom: 20px;
}

/* Blog Style Four */
.blog-style-four .blog-post-item .entry-content{
    padding: 33px 40px 30px;
    border: 2px solid #f2f2f2;
    border-top: none;
}
.blog-style-four .blog-post-item .entry-content .post-meta ul li{
    margin-right: 20px;
}
.blog-style-four .blog-post-item .entry-content .post-meta ul li span i{
    background: linear-gradient( 90deg, rgb(0,41,157) 0%, rgb(0,120,238) 100%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}
.blog-style-four .blog-post-item .entry-content h3.title{
    margin-bottom: 10px;
}
.blog-style-four .blog-post-item .entry-content h3.title:hover{
    color: var(--blue-dark);
}
.blog-style-four .blog-post-item .entry-content h3.title a{
    text-decoration: none;
}
/* Blog Standard CSS */
.blog-standard-style .blog-post-item .entry-content .b-user-admin{
    margin-right: 50px;
}
.blog-standard-style .blog-post-item .entry-content  .post-meta ul li{
    margin-right: 20px;
}
.blog-style-four .blog-post-item .entry-content .post-meta ul li:last-child,
.blog-standard-style .blog-post-item .entry-content  .post-meta ul li:last-child{
    margin-right: 0;
}
.blog-standard-style .blog-post-item .entry-content h3.title{
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 20px;
}
.blog-standard-style .blog-post-item .entry-content h3.title:hover{
    color: var(--blue-light);
}
.blog-standard-style .blog-post-item .entry-content p{
    margin-bottom: 20px;
}
.blog-standard-style .blog-post-item .entry-content .main-btn.filled-btn:hover{
    background-color: var(--blue-light);
    color: #fff;
    border-color: transparent;
}
.blog-standard-style .blog-post-item.blog-post-with-video .post-thumbnail{
    position: relative;
    z-index: 1;
}
.blog-standard-style .blog-post-item.blog-post-with-video .post-thumbnail:after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(25, 25, 25, 0.65);
    z-index: 1;
}
.blog-standard-style .blog-post-item.blog-post-with-video .post-thumbnail .play-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
}
.blog-standard-style .blog-post-item.blog-post-with-video .post-thumbnail .play-content .video-popup{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 50%;
    color: var(--blue-light);
}
.blog-standard-style .blog-post-item.blog-post-with-content{
    background-color: #f7f8f9;
    padding: 40px 50px 50px;
}
.blog-standard-style .blog-post-item.blog-post-with-bg{
    position: relative;
    z-index: 1;
    padding: 40px 50px 50px;
}
.blog-standard-style .blog-post-item.blog-post-with-bg:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(25, 25, 25, 0.65);
    z-index: -1;
}
.blog-standard-style .blog-post-item.blog-post-with-bg .entry-content p,
.blog-standard-style .blog-post-item.blog-post-with-bg .entry-content h3.title,
.blog-standard-style .blog-post-item.blog-post-with-bg .entry-content .post-meta ul li span{
    color: #fff;
}
.blog-standard-style .blog-post-item.blog-post-with-bg .entry-content h3.title:hover{
    color: var(--blue-light);
}
.blog-standard-style .blog-post-item.blog-post-with-bg .entry-content .main-btn.filled-btn{
    border-color: #fff;
    color: #fff;
}
.blog-standard-style .blog-post-item.blog-post-with-bg .entry-content .main-btn.filled-btn:hover{
    background-color: var(--blue-light);
    border-color: transparent;
}
.blog-standard-style .blog-post-item.blog-post-with-content .user-admin .thumb{
    margin-right: 15px;
}
.blog-standard-style .blog-post-item.blog-post-with-content .user-admin a:hover{
    color: var(--blue-light);
}


/* Blog Details CSS */
.blog-details-wrapper .blog-post-item p{
    margin-bottom: 38px;
}
.blog-details-wrapper .blog-post-item .entry-content .b-user-admin{
    margin-right: 50px;
}
.blog-details-wrapper .blog-post-item .entry-content .post-meta ul li{
    margin-right: 23px;
    margin-bottom: 10px;
}
.blog-details-wrapper .blog-post-item .entry-content .post-meta ul li:last-child{
    margin-right: 0;
}
.blog-details-wrapper .blog-post-item .entry-content h3.title{
    font-size: 35px;
    line-height: 45px;
    text-decoration: underline;
    margin-bottom: 25px;
}
.blog-details-wrapper .blog-post-item .entry-content blockquote{
    display: flex;
    padding: 0 60px;
    margin-bottom: 28px;
}
.blog-details-wrapper .blog-post-item .entry-content blockquote .thumb{
    max-width: 60px;
    height: 60px;
    flex: 0 0 60px;
    margin-right: 20px;
}
.blog-details-wrapper .blog-post-item .entry-content blockquote .content h4{
    text-decoration: underline;
    margin-bottom: 10px;
}
.blog-details-wrapper .blog-post-item .entry-content blockquote .content h5{
    font-size: 18px;
    font-weight: 500;
    color: #616161;
    font-family: var(--roboto);
}
.blog-details-wrapper .blog-post-item .entry-content blockquote .content h5:before{
    display: inline-block;
    content: '';
    width: 25px;
    height: 3px;
    background-color: var(--blue-light);
    margin-right: 10px;
    vertical-align: middle;
}
.blog-details-wrapper .blog-post-item .entry-content .post-share-tag .item-heading{
    color: var(--heading);
    font-weight: 500;
    margin-right: 10px;
}
.blog-details-wrapper .blog-post-item .entry-content .post-share-tag .post-tag-cloud{
    margin-bottom: 10px;
}
.blog-details-wrapper .blog-post-item .entry-content .post-share-tag .post-tag-cloud ul li{
    display: inline-block;
}
.blog-details-wrapper .post-navigation .post-nav-item .content h6:hover,
.blog-details-wrapper .post-author-box .author-content ul.social-link li a:hover,
.blog-details-wrapper .blog-post-item .entry-content .post-share-tag ul.social-link li a:hover,
.blog-details-wrapper .blog-post-item .entry-content .post-share-tag .post-tag-cloud ul li a:hover{
    color: var(--blue-light);
}
.blog-details-wrapper .blog-post-item .entry-content .post-share-tag ul.social-link li{
    margin-right: 15px;
}
.blog-details-wrapper .post-author-box{
    padding: 40px 50px;
    display: flex;
    border: 2px solid rgba(255, 107, 107, 0.07);
    background-color: #f7f8f9;
}
.blog-details-wrapper .post-author-box .author-thumb{
    flex: 0 0 170px;
    max-width: 170px;
    height: 170px;
    margin-right: 30px;
}
.blog-details-wrapper .post-author-box .author-content h4{
    text-decoration: underline;
    margin-bottom: 10px;
}
.blog-details-wrapper .post-author-box .author-content p{
    margin-bottom: 10px;
}
.blog-details-wrapper .post-author-box .author-content ul.social-link li{
    margin-right: 10px;
}
.blog-details-wrapper .post-author-box .author-thumb img{
    border-radius: 50%;
    width: 100%;
}
.blog-details-wrapper .post-navigation{
    padding-bottom: 30px;
    border-bottom: 1px solid #dde0e2;
    margin-bottom: 30px;
}
.blog-details-wrapper .post-navigation .post-nav-item {
    display: flex;
    max-width: 280px;
}
.blog-details-wrapper .post-navigation .post-nav-item .thumb{
    max-width: 80px;
    flex: 0 0 80px;
    height: 80px;
    margin-right: 25px;
}
.blog-details-wrapper .post-navigation .post-nav-item .content{
    margin-top: -5px;
}
.blog-details-wrapper .post-navigation .post-nav-item .content h6{
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 5px;
}
.blog-details-wrapper .post-navigation .post-nav-item .content h6 a{
    text-decoration: underline;
}
.blog-details-wrapper .post-navigation .post-nav-item .content span.post-date a i{
    margin-right: 10px;
}
.blog-details-wrapper .comments-area{
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
}
.blog-details-wrapper .comments-area  .comments-title{
    margin-bottom: 5px;
}
.blog-details-wrapper .comments-area p{
    margin-bottom: 45px;
}
.blog-details-wrapper .comments-area .comments-list .comment{
    display: flex;
    margin-bottom: 45px;
}
.blog-details-wrapper .comments-area .comments-list .comment:nth-child(even){
    margin-left: 40px;
}
.blog-details-wrapper .comments-area .comments-list .comment .comment-avatar{
    max-width: 85px;
    flex: 0 0 85px;
    height: 85px;
    margin-right: 25px;
}
.blog-details-wrapper .comment-wrap .comment-author-content span.author-name{
    font-size: 22px;
    font-family: var(--rajdhani);
    font-weight: 700;
    color: var(--heading);
    text-decoration: underline;
}
.blog-details-wrapper .comment-wrap .comment-author-content span.author-name span.date{
    font-family: var(--roboto);
    font-size: 15px;
    font-weight: 400;
    color: var(--text-color);
    margin-left: 20px;
}
.blog-details-wrapper .comment-wrap .comment-author-content p{
    margin-bottom: 5px;
}
.blog-details-wrapper .comment-wrap .comment-author-content .reply{
    font-weight: 700;
    color: var(--heading);
    text-decoration: underline;
}
.blog-details-wrapper .comments-respond h4.comments-heading{
    margin-bottom: 10px;
}
.blog-details-wrapper .comments-respond p{
    margin-bottom: 45px;
}
.blog-details-wrapper .comments-respond form.comment-form .form_control{
    padding: 0 30px;
    height: 60px;
    background-color: #f7f8f9;
    border-radius: 5px;
    margin-bottom: 25px;
    color: #444444;
}
.blog-details-wrapper .comments-respond form.comment-form textarea.form_control{
    height: 175px;
}
.blog-details-wrapper .comments-respond form.comment-form .form_control::placeholder{
    color: #444444;
}

/* Sidebar Area */
.sidebar-widget-area .widget{
    padding: 35px 40px 40px;
    border: 1px solid rgba(25, 25, 25, 0.1);
}
.sidebar-widget-area .widget h4.widget-title{
    position: relative;
    margin-bottom: 25px;
}
.sidebar-widget-area .widget h4.widget-title span.line{
    position: relative;
    margin-left: 20px;
}
.sidebar-widget-area .widget h4.widget-title span.line:after,
.sidebar-widget-area .widget h4.widget-title span.line:before{
    display: inline-block;
    content: '';
    background-color: var(--blue-light);
    height: 4px;
}
.sidebar-widget-area .widget h4.widget-title span.line:after{
    width: 20px;
    margin-left: 5px;
}
.sidebar-widget-area .widget h4.widget-title span.line:before{
    width: 40px;
}
.sidebar-widget-area .widget.search-widget form .form_group .form_control{
    background-color: #f7f8f9;
    padding: 0 30px;
    height: 60px;
}
.sidebar-widget-area .widget.search-widget form .form_group i{
    position: absolute;
    top: 20px;
    right: 30px;
}
.sidebar-widget-area .widget.recent-post-widget .post-thumbnail-content{
    display: flex;
    margin-bottom: 20px;
}
.sidebar-widget-area .widget.recent-post-widget .post-thumbnail-content:last-child{
    margin-bottom: 0px;
}
.sidebar-widget-area .widget.recent-post-widget .post-thumbnail-content img{
    flex: 0 0 80px;
    max-width: 80px;
    height: 80px;
    margin-right: 20px;
}
.sidebar-widget-area .widget.recent-post-widget .post-thumbnail-content .post-title-date h6{
    font-size: 18px;
    line-height: 25px;
}
/* .sidebar-widget-area .widget.recent-post-widget .post-thumbnail-content .post-title-date h6 a{
    text-decoration: underline;
} */
.sidebar-widget-area .widget.recent-post-widget .post-thumbnail-content .post-title-date  span.posted-on{
    font-size: 15px;
}
.sidebar-widget-area .widget.recent-post-widget .post-thumbnail-content .post-title-date  span.posted-on i{
    margin-right: 10px;
}
.sidebar-widget-area .widget.widget-banner{
    padding: 0;
}
.sidebar-widget-area .widget.widget-banner .banner-content{
    padding: 30px 40px;
    height: 515px;
}
.sidebar-widget-area .widget.widget-banner .banner-content h3{
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 42px;
    /* text-decoration: underline; */
}
.sidebar-widget-area .widget.widget-tag-cloud{
    padding: 35px 40px 33px;
}
.sidebar-widget-area .widget.widget-tag-cloud a{
    padding: 5px 15px;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 7px;
    margin-right: 2px;
    border-radius: 5px;
    font-weight: 500;
    background-color: #f7f8f9;
    transition: all .3s;
}
.sidebar-widget-area .widget.widget-tag-cloud a:hover{
    background-color: var(--blue-light);
    color: #fff;
}
/*---=========================
    End Blog css 
=========================---*/

/*---=========================
    16. Start Contact css 
=========================---*/

/* Contact one css */
.contact-style-three .contact-wrapper,
.contact-style-one .contact-wrapper{
    background-color: #fff;
    padding: 60px 50px 30px;
}
.contact-style-three .contact-form .form_control,
.contact-style-one .contact-form .form_control{
    background-color: var(--light-gray);
    height: 65px;
    border-radius: 5px;
    padding: 0 30px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 700;
    font-family: var(--rajdhani);
}
.contact-style-three .contact-form .form_control::placeholder,
.contact-style-one .contact-form .form_control::placeholder{
    color: #1b1d21;
}
.contact-style-three  .contact-form .nice-select,
.contact-style-one .contact-form .nice-select{
    background-color: var(--light-gray);
    padding: 0 30px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 700;
    font-family: var(--rajdhani);
    color: #1b1d21;
    height: 65px;
    line-height: 65px;
    border: none;
}
.contact-style-one .contact-form .main-btn {
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 18px 35px;
    line-height: 29px;
    background: linear-gradient( -90deg, rgb(41,129,233) 0%, rgb(62,67,220) 99%);
}
textarea.form_control{
    padding-top: 15px;
}
/* Contact Two css */
.contact-style-two .contact-wrapper{
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: -80px;
    background-color: rgb(255, 255, 255);
    padding: 70px 0 80px;
}
.contact-style-two .section-title h2{
    margin-bottom: 5px;
}
.contact-style-two .section-title h5{
    color: #6d6d6d;
    font-weight: 500;
}
.contact-style-two .contact-form form .form_control{
    height: 60px;
    background-color: rgba(20, 29, 56, 0.05);
    padding: 0 30px;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 700;
    font-family: var(--rajdhani);
    transition: all .3s;
}
.contact-style-two .contact-form form textarea.form_control{
    padding-top: 15px;
    height: 150px;
}
.contact-style-two .contact-form form .form_control:focus{
    background-color: var(--blue-light);
}
.contact-style-two .contact-form form .form_control::placeholder{
    color: #1b1d21;
}
.contact-style-two .contact-form form .form_control:focus::placeholder{
    color: #fff;
}
.contact-style-two .contact-form form .main-btn{
    justify-content: center;
}

/* Contact Three css */
.contact-style-three .contact-section-box p{
    margin-bottom: 20px;
}
.contact-style-three .contact-section-box ul.social-link li{
    margin-right: 5px;
}
.contact-style-three .contact-section-box ul.social-link li a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 18px;
    color: #fff;
    background-color: var(--purple-blue);
    transition: all .3s;
}
.contact-style-three .contact-section-box ul.social-link li a:hover{
    background-color: var(--blue-light);
}
.contact-style-three .contact-form textarea.form_control{
    height: 200px;
}
.contact-style-three .contact-form .main-btn{
    width: 100%;
    text-align: center;
    justify-content: center;
}

/* Contact Information css */

.contact-information-style-one .information-wrapper{
    background-color: #f8f8f8;
    padding: 80px 90px 10px;
}
.contact-information-style-one .information-wrapper .information-item{
    display: flex;
}
.contact-information-style-one .information-wrapper .information-item .icon{
    margin-right: 25px;
}
.contact-information-style-one .information-wrapper .information-item .icon i{
    font-size: 60px;
    line-height: 1;
    color: var(--blue-light);
}
.contact-information-style-one .information-wrapper .information-item .text h5{
    margin-bottom: 3px;
}
.contact-information-style-one .information-wrapper .information-item .text p span{
    font-weight: 700;
    color: var(--black-dark);
    margin-right: 10px;
}

.contact-information-style-one .information-cta{
    background-color: #141d38;
    padding: 40px 50px 20px;
    border-left: 7px solid var(--blue-light);
}
.contact-information-style-one .information-cta .information-box h3{
    font-size: 30px;
    color: #fff;
    margin-bottom: 15px;
}
.contact-information-style-one .information-cta .information-box h5{
    font-family: var(--roboto);
    font-weight: 400;
    color: #fff;
}
.contact-information-style-one .information-cta .information-box h5.st-close{
    color: #ffb400;
    margin-top: 5px;
}
.contact-information-style-one .information-cta .information-box p{
    color: #fff;
    margin-bottom: 20px;
}
.contact-information-style-one .information-cta .information-box .main-btn{
    padding: 5px 20px;
}
.contact-page-map .map-box iframe{
    height: 710px;
}
.map-box iframe{
    display: inherit;
    border: none;
    width: 100%;
}
/*---=========================
    End Contact css 
=========================---*/


/*---=========================
    17. Start Footer css 
=========================---*/
.footer-map{
    position: relative;
    z-index: 1;
}
.footer-map:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    /* background: url(../image/footer-map.png) no-repeat center; */
    background-size: cover;
    z-index: -1;
}
.list-style-dot li a{
    margin-left: 20px;
    position: relative;
    display: inline-flex;
    align-items: center;
}
.list-style-dot li a:before{
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    margin-right: 15px;
}
.list-style-arrow li a{
    display: inline-flex;
    align-items: center;
}
.list-style-arrow li a:before{
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    margin-right: 8px;
}
/* Footer Widget */
.footer-widget .widget h4.widget-title{
    position: relative;
    font-size: 25px;
    margin-bottom: 30px;
}
.footer-widget .widget.about-widget .about-content h4{
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 26px;
    line-height: 37px;
}
.footer-widget .widget.about-widget .about-content ul li{
    margin-bottom: 13px;
}
.footer-widget .widget.about-widget .about-content ul li span a:hover{
    text-decoration: underline;
}
.footer-widget .widget.footer-nav-widget ul li a{
    margin-bottom: 10px;
}
.footer-widget .widget.about-widget .about-content ul li i{
    font-size: 18px;
    margin-right: 10px;
}
.footer-widget .widget.about-widget .about-content ul li span{
    font-size: 17px;
}
.footer-widget .widget.recent-post-widget ul.post-widget-list li.post-item {
    display: flex;
    align-items: flex-start;
}
.footer-widget .widget.recent-post-widget ul.post-widget-list li.post-item img{
    flex: 0 0 70px;
    max-width: 70px;
    margin-right: 20px;
}
.footer-widget .widget.recent-post-widget ul.post-widget-list li.post-item{
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 25px;
}
.footer-widget .widget.recent-post-widget ul.post-widget-list li.post-item:last-child{
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}
.footer-widget .widget.recent-post-widget ul.post-widget-list li.post-item .post-title-date{
    margin-top: -3px;
}
.footer-widget .widget.recent-post-widget ul.post-widget-list li.post-item .post-title-date h3.title{
    font-size: 18px;
    line-height: 22px;
}
.footer-widget .widget.recent-post-widget ul.post-widget-list li.post-item .post-title-date span.posted-on{
    font-size: 14px;
    line-height: 15px;
}
.footer-copyright{
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 30px 0 34px;
}
.footer-copyright .social-link li{
    margin-left: 30px;
}


/* Footer Default */
.footer-default{
    color: rgba(255, 255, 255, .60);
}
.footer-default .footer-widget .widget.about-widget .about-content ul li i{
    color: var(--red-dark);
}
.footer-default .footer-widget .widget.footer-nav-widget ul li a:hover{
    color: var(--red-dark);
}
.footer-default .footer-widget .widget.about-widget .about-content ul li span a:hover{
    color: var(--red-dark);
}
.footer-default .footer-widget .widget.footer-nav-widget ul li a:after{
    background-color: var(--red-dark);
}
.footer-default .footer-widget .widget.about-widget .about-content h4,
.footer-default .footer-widget .widget h4.widget-title{
    color: #fff;
}
.footer-default .footer-copyright ul.social-link li a:hover{
    color: var(--red-dark);
}
.footer-default .footer-widget .footer-nav-widget{
    padding-left: 70px;
}
.footer-default .footer-widget .widget.footer-nav-widget ul li a:after{
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 0;
    height: 1px;
    transition: all .3s;
}
.footer-default .footer-widget .widget.footer-nav-widget ul li a:hover:after{
    width: 100%;
}


/* Footer Style One */
.footer-style-two .footer-widget .widget.about-widget .about-content h4,
.footer-style-two .footer-widget .widget h4.widget-title,
.footer-style-one .footer-widget .widget.about-widget .about-content h4,
.footer-style-one .footer-widget .widget h4.widget-title{
    color: #fff;
}
.footer-style-one .footer-widget .footer-nav-widget{
    padding-left: 70px;
}
.footer-style-two .footer-widget .widget.footer-nav-widget ul li a:after,
.footer-style-one .footer-widget .widget.footer-nav-widget ul li a:after{
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 0;
    height: 1px;
    transition: all .3s;
}
.footer-style-two .footer-widget .widget.footer-nav-widget ul li a:hover:after,
.footer-style-one .footer-widget .widget.footer-nav-widget ul li a:hover:after{
    width: 100%;
}
.footer-style-one .footer-widget .widget.footer-nav-widget ul li a:after{
    background-color: var(--blue-dark);
}
.footer-style-one .footer-widget .widget.about-widget .about-content ul li span a:hover{
    color: var(--blue-dark);
}
.footer-style-one .footer-widget{
    color: rgba(255, 255, 255, .60);
}
.footer-style-one .footer-widget .widget.footer-nav-widget ul li a:hover{
    color: var(--blue-dark);
}

/* Footer Style Two */
.footer-style-two{
    position: relative;
    color: rgba(255, 255, 255, .60);
    z-index: 1;
}
.footer-style-two.bg_cover:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #141d38;
    mix-blend-mode: multiply;
    z-index: -1;
}
.footer-style-two .footer-widget .widget.about-widget .about-content ul li i{
    color: var(--blue-light);
}
.footer-style-two .footer-widget .widget.footer-nav-widget ul li a:after{
    background-color: var(--blue-light);
}
.footer-style-two .footer-widget .widget.footer-nav-widget ul li a:hover{
    color:  var(--blue-light);
}
.footer-style-two .footer-copyright ul.social-link li a:hover{
    color: var(--blue-light);
}
.footer-style-two .footer-widget .footer-nav-widget{
    padding-left: 40px;
}
.footer-style-two .footer-widget .recent-post-widget{
    padding-left: 0px;
}
.footer-style-two .footer-widget .widget.recent-post-widget ul.post-widget-list li.post-item .post-title-date h3.title{
    color: #fff;
    font-weight: 600;
}
.footer-style-two .footer-widget .widget.recent-post-widget ul.post-widget-list li.post-item .post-title-date h3.title:hover{
    color: var(--blue-light);
}


/* Footer Style Three */
.footer-style-three{
    padding-top: 210px;
    position: relative;
    z-index: 1;
    background-color: #f6f6f7;
}
.footer-style-three:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 220px;
    /* background: url(../image/cloudy-sky.png) no-repeat center; */
    background-size: cover;
    z-index: -1;
}
.footer-style-three #footer-curve{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.footer-style-three #footer-curve path{
    fill: #f6f6f7;
}
.footer-style-three .footer-widget .widget h4.widget-title{
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 20px;
}
.footer-style-three .footer-widget .widget.footer-nav-widget ul.footer-nav li a:hover{
    color: var(--blue-dark);
}
.footer-style-three .footer-widget .widget.contact-info-widget .contact-info h3{
    margin-bottom: 23px;
}
.footer-style-three .footer-widget .widget.contact-info-widget .contact-info p{
    margin-bottom: 10px;
    font-size: 18px;
}
.footer-style-three .footer-widget .widget.contact-info-widget .contact-info p i{
    color: var(--blue-dark);
    font-size: 20px;
    margin-right: 10px;
}
.footer-style-three .footer-copyright {
    padding: 22px 0;
    border-top: 1px solid rgba(27, 27, 32, 0.1);
}

.footer-style-three .footer-copyright .text p span,
.footer-style-three .footer-copyright .social-link li a:hover{
    color: var(--blue-dark);
}
.footer-style-three .footer-copyright .text p {
    color: #1b1b20;
}
/* Footer Style Four */
.footer-style-four{
    background-color: #1f2531;
}
.footer-style-four .footer-widget .widget h4.widget-title{
    color: #fff;
    margin-bottom: 25px;
}
.footer-style-four .footer-widget .newsletter-widget .newsletter-content h3{
    color: #fff;
    padding-right: 10%;
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 12px;
}
.footer-style-four .footer-widget .newsletter-widget .newsletter-content p{
    color: #fff;
    margin-bottom: 20px;
}
.footer-style-four .footer-widget .newsletter-widget .newsletter-content form .form_control{
    background-color: transparent;
    height: 50px;
    color: #fff;
    font-weight: 400;
    font-family: var(--roboto);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.footer-style-four .footer-widget .newsletter-widget .newsletter-content form .form_control::placeholder{
    color: #fff;
}
.footer-style-four .footer-widget .newsletter-widget .newsletter-content form .main-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fc7561;
    padding: 10px 35px;
}
.footer-style-four .footer-widget .newsletter-widget .newsletter-content .social-link li{
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}
.footer-style-four .footer-widget .newsletter-widget .newsletter-content .social-link li span.social-title{
    color: #fff;
    font-size: 23px;
    font-weight: 700;
    font-family: var(--rajdhani);
    margin-right: 10px;
}
.footer-style-four .footer-widget .newsletter-widget .newsletter-content .social-link li a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    color: #fff;
    background-color: #32353d;
}
.footer-style-four .footer-widget .newsletter-widget .newsletter-content .social-link li a:hover{
    background-color: #ff9703;
    color: #fff;
}
.footer-style-four .footer-widget .widget.footer-nav-widget{
    padding-left: 40px;
}
.footer-style-four .footer-widget .widget.footer-nav-widget ul.footer-nav li a{
    color: #fff;
}
.footer-style-four .footer-widget .widget.footer-nav-widget ul.footer-nav li a:hover{
    color: var(--blue-light);
}
.footer-style-four .contact-wrapper{
    background-color: #191e29;
    padding: 50px 50px 20px;
}
.footer-style-four .contact-wrapper .information-item{
    display: flex;
}
.footer-style-four .contact-wrapper .information-item .icon{
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 40px;
    color: #fff;
    margin-right: 30px;
}
.footer-style-four .contact-wrapper .information-item .icon.icon-bg-one{
    background-color: #00bbee;
}
.footer-style-four .contact-wrapper .information-item .icon.icon-bg-two{
    background-color: #00dcae;
}
.footer-style-four .contact-wrapper .information-item .icon.icon-bg-three{
    background-color: #ff9703;
}
.footer-style-four .contact-wrapper .information-item .text span.title{
    font-size: 20px;
    font-weight: 700;
    font-family: var(--rajdhani);
    color: #fff;
}
.footer-style-four .contact-wrapper .information-item .text h3{
    color: #fff;
    font-weight: 400;
    text-decoration: underline;
    font-size: 22px;
}
.footer-style-four .contact-wrapper .information-item .text h3 a{
    text-decoration: underline;
}
.footer-style-four .contact-wrapper .information-item .text h3:hover{
    color: #ff9703;
}
.footer-style-four .footer-copyright{
    border-top: none;
}
.footer-style-four .footer-copyright .text p{
    color: #fff;
}

/* Footer Style Five */
.footer-style-five{
    position: relative;
    z-index: 1;
    background-color: #0a0b11;
}
.footer-style-five .shape-icon{
    position: absolute;
    z-index: -1;
}
.footer-style-five .shape-icon.shape-icon-one{
    top: 45%;
    left: 10%;
}
.footer-style-five .shape-icon.shape-icon-two{
    top: 15%;
    right: 15%;
}
.footer-style-five .shape-icon.shape-icon-three{
    bottom: 20%;
    right: 12%;
}
.footer-style-five .footer-widget .widget h4.widget-title{
    color: #fff;
    font-size: 24px;
    margin-bottom: 25px;
}
.footer-style-five .footer-widget .widget.about-widget h2{
    color: #fff;
    margin-bottom: 20px;
}
.footer-style-five .footer-widget .widget.about-widget p{
    color: #abb0bf;
    margin-bottom: 20px;
}
.footer-style-five .footer-widget .widget.about-widget h5{
    color: #fff;
    font-family: var(--roboto);
    font-weight: 400;
}
.footer-style-five .footer-widget .widget.about-widget h5:hover a{
    color: var(--yellow-dark);
}
.footer-style-five .footer-widget .widget.about-widget h5 i{
    color: var(--yellow-dark);
    margin-right: 10px;
}
.footer-style-five .footer-widget .widget.footer-nav-widget .footer-nav li a{
    color: #ffffff;
}
.footer-style-five .footer-widget .widget.contact-info-widget p{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #fff;
}
.footer-style-five .footer-widget .widget.contact-info-widget p:hover a{
    color: var(--yellow-dark);
}
.footer-style-five .footer-widget .widget.contact-info-widget p i{
    color: var(--yellow-dark);
    margin-right: 10px;
}
.footer-style-five .footer-widget .widget.contact-info-widget .social-box h3{
    color: #fff;
    margin-bottom: 15px;
}
.footer-style-five .footer-widget .widget.contact-info-widget .social-box ul.social-link li{
    margin-right: 5px;
}
.footer-style-five .footer-widget .widget.contact-info-widget .social-box ul.social-link li a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: #32353d;
    color: #fff;
}
.footer-style-five .footer-widget .widget.contact-info-widget .social-box ul.social-link li a:hover{
    background-color: var(--blue-dark);
}
.footer-style-five .footer-copyright{
    padding: 30px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.footer-style-five .footer-copyright .text p{
    color: #abb0bf;
}
.footer-style-five .footer-copyright .text p span{
    color: var(--yellow-dark);
}
.footer-style-five .footer-copyright .copyright-nav ul li{
    display: inline-block;
    margin-left: 25px;
}
.footer-style-five .footer-copyright .copyright-nav ul li a{
    color: #abb0bf;
}
.footer-style-five .footer-widget .widget.footer-nav-widget .footer-nav li a:hover,
.footer-style-five .footer-copyright .copyright-nav ul li a:hover{
    color: var(--blue-dark);
}

/* Footer Style Six */
.footer-style-six .footer-widget .widget h4.widget-title{
    margin-bottom: 30px;
}
.footer-style-six .footer-widget .widget.about-widget .about-content p{
    margin-bottom: 30px;
}
.footer-style-six .footer-widget .widget.about-widget .about-content ul.social-link li{
    margin-right: 5px;
}
.footer-style-six .footer-widget .widget.about-widget .about-content ul.social-link li a{
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f6f6;
    color: var(--heading);
    z-index: 1;
    transition: all .3s;
}
.footer-style-six .footer-widget .widget.about-widget .about-content ul.social-link li a i{
    margin-right: 0;
}
.footer-style-six .footer-widget .widget.about-widget .about-content ul.social-link li a:hover{
    color: #fff;
}
.footer-style-six .footer-widget .widget.about-widget .about-content ul.social-link li a:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient( 90deg, rgb(0,41,157) 0%, rgb(0,120,238) 100%);
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all .3s;
}
.footer-style-six .footer-widget .widget.about-widget .about-content ul.social-link li a:hover:after{
    visibility: visible;
    opacity: 1;
}
.footer-style-six .footer-widget .widget.footer-nav-widget ul li a:hover{
    color: var(--blue-dark);
}
.footer-style-six .footer-copyright{
    padding: 25px 0;
    border-top: 1px solid #f6f6f6;
}
.footer-style-six .footer-copyright .text p{
    color: #788eb3;
}
.footer-style-six .footer-copyright .text p span{
    font-weight: 700;
    color: var(--blue-dark);
}
/*---=========================
    End Footer css 
=========================---*/

