.menu_list li{
    list-style-type: none;

}
.menu_list li a{
    text-decoration: none;
    font-size: 16px;
    color:#fff;

}
/* .menu_list{
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: end;
    padding: 0 20px;
    height: 100%;
} */
.menu_list ul{
    display: flex;
    align-items: center;
    gap: 30px;
}

.login_header{
    /* border:none;
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 18px; */
    cursor: pointer;
}
.login_modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000000d1;
	display: flex;
	justify-content: center;
	align-items: center;
    z-index: 999;
}
.login_modal form.uploadForm {
  width: 90%;
  background: #fff;
  border-radius: 0;
  padding: 30px;
  position: relative;
  height: 90vh;
  border-radius: 10px;
  

}
.uploadInput{
  border: 1px solid #dedede;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background: transparent;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    height: 200px;
    overflow-y: auto;
  }
  .ck.ck-editor__main {
    height: 200px;
    overflow-y: auto;
  }
  .login_modal form label{
    display: block;
    margin-bottom: 5px;
  }
  .login_modal form input{
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    padding: 0 10px;
    line-height: 40px;
  }
  .login_modal h4{
    font-size: 30px;
    margin-bottom: 20px;
  }
  .btn{
	background: #000;
    color:#fff;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px 30px;
    border:none;
    cursor: pointer;
  }
  .close_btn{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    background: unset;
    border: none;
    cursor: pointer;
  }
  .welcome_message{
    font-size: 18px;
    color: #fff;
  }